import { Form, Select } from 'antd';
import { getSettings } from '@helpers/global-helpers';
import { GlobalSettings } from '@models/settings-models';
import { CurrencyOptionsProps } from '@models/global-models';

const CurrencyOptions = ({
  name = 'currency',
  label = '',
  initialValue = 'EUR',
  required = false,
  disabled = false,
  placeholder = 'Select Currency',
  className = '',
}: CurrencyOptionsProps) => {
  const { Option } = Select;
  const globalSettings: GlobalSettings = getSettings();

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required }]}
      initialValue={initialValue}
    >
      <Select allowClear disabled={disabled} placeholder={placeholder} className={className}>
        {globalSettings.currency.map(currency => {
          return (
            <Option key={currency.symbol} value={currency.symbol}>
              {currency.symbol}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default CurrencyOptions;