import { POST_MESSAGE_IFRAME } from '@constants/global-constants';

export const actionListIframePostmessage = (message: string) => {
  window.parent.postMessage(
    {
      type: POST_MESSAGE_IFRAME.TYPE,
      message: message,
    },
    POST_MESSAGE_IFRAME.TARGET_ORIGIN || ''
  );
};

export const recievedMsgFromIframePostmessage = () => {
  window.addEventListener('message', (event) => {
    if (event?.data?.message) {
      return event?.data?.message;
    }

    return false;

  });

  return false;
};


export const removeLastCharacter = (value: string) => {
  return value.substring(0, value.length - 1);
};
