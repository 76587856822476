import { Row, Col } from 'antd';
import { Fragment } from 'react';

import { CreationTabProps, CreationTabsProps } from '@models/global-models';
import { prefillBreadcrumbClickedPage } from '@helpers/campaign-helpers';
import { CAMPAIGN_TYPE, CURRENT_STEP_NAME } from '@constants/global-constants';
import './creation-tabs.css';

const CreationTabs = (props: CreationTabsProps) => {
  const { currentCampaignType, currentStep, setCurrentStep } = props;
  const { DSP_SELECTION, DATE_AND_BUDGET, CREATIVES, TARGETING } = CURRENT_STEP_NAME;

  if (currentStep < DSP_SELECTION || currentStep > TARGETING) {
    return (<Fragment></Fragment>);
  }

  const CreationTab = ({ line, text, tabStep }: CreationTabProps) => {
    return (
      <Col
        span={8}
        onClick={() => prefillBreadcrumbClickedPage(tabStep, currentStep, setCurrentStep)}
        className={currentStep >= tabStep ? 'cursor-pointer' : 'cursor-not-allowed disable-tab'}
      >
        <span className={`${(currentStep === tabStep) && 'text-bold'}`}> {text} </span>
        <div className={currentStep >= tabStep ? 'line-' + line : 'line-0'} />
      </Col>
    );
  };

  return (
    <Row gutter={16} style={{ marginBottom: '50px' }} className='creation-tab'>
      {currentCampaignType === CAMPAIGN_TYPE.DEAL_ID ? (
        <Fragment>
          <CreationTab line={1} tabStep={DSP_SELECTION} text='1. DSP Selection' />
          <CreationTab line={2} tabStep={DATE_AND_BUDGET} text='2. Dates and Budget' />
          <CreationTab line={3} tabStep={TARGETING} text='3. Targeting' />
        </Fragment>
      ) : (
        <Fragment>
          <CreationTab line={1} tabStep={DATE_AND_BUDGET} text='1. Dates and Budget' />
          <CreationTab line={2} tabStep={CREATIVES} text='2. Creatives' />
          <CreationTab line={3} tabStep={TARGETING} text='3. Targeting' />
        </Fragment>
      )}
    </Row >
  );
};

export default CreationTabs;
