import HttpClientInstance from './base-services';
import { API_END_POINTS } from '@constants/global-constants';
import { LOCAL_STORAGE_KEYS } from '@constants/storage-constants';

const httpClient = new HttpClientInstance();

/** API: getGlobalSettings() */
export async function getGlobalSettings(): Promise<any> {
  const url = API_END_POINTS.SETTINGS;

  return await httpClient
    .get(url)
    .then((resp) => {
      //? Storing global settings into Local Storage
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.SETTINGS,
        JSON.stringify({ ...resp })
      );

      return resp;
    })
    .catch((err) => err);
}
