import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Layout, Row, Col, Dropdown, Avatar } from 'antd';
import { UserOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons';

import { breadcrumbState } from '@atoms/global-atoms';
import { BaseHeaderProps } from '@models/layout-models';
import { routes } from '@constants/route-constants';
import BaseBreadcrumb from '@components/common/base-breadcrumb';

import './base-header.css';
import { BellIcon, QuestionIcon, UserIcon } from '@icons';

const { Header } = Layout;

const BaseHeader = (props: BaseHeaderProps) => {
  const { collapsed, onCollapseClick, onLogout } = props;
  const breadcrumbItems = useRecoilValue(breadcrumbState);

  const items = [
    {
      label: (
        <Link to={routes.userDetails.path}>{routes.userDetails.title}</Link>
      ),
      key: routes.userDetails.key,
      icon: <UserOutlined />,
    },
    {
      label: <div onClick={onLogout}>{routes.logout.title}</div>,
      key: routes.logout.key,
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Fragment>
      <Header className="base-header" style={{ padding: 0 }}>
        <Row className="nav-container">
          <Col span={18} className="toggle-bar-container">
            <MenuOutlined
              onClick={onCollapseClick}
              className={`trigger ${collapsed ? 'rotate-90' : ''}`}
            />
          </Col>
          <Col span={6} className="avatar-expand">
            <BellIcon />
            <QuestionIcon className="info-icon" />
            <Dropdown
              menu={{ items }}
              trigger={['click']}
              placement="bottomLeft"
            >
              <div onClick={(e) => e.preventDefault()}>
                <Avatar icon={<UserIcon />} />
              </div>
            </Dropdown>
          </Col>
        </Row>

        <BaseBreadcrumb breadcrumbItems={breadcrumbItems} />
      </Header>
    </Fragment>
  );
};

export default BaseHeader;
