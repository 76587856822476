import { useEffect } from 'react';
import { Row, Col, Form, Input, Select, Radio, Space } from 'antd';

import { getDraftId } from '@helpers/campaign-helpers';
import { GlobalSettings } from '@models/settings-models';
import { CAMPAIGN_TYPE } from '@constants/global-constants';
import { LOCAL_STORAGE_KEYS } from '@constants/storage-constants';
import { CreateCampaignProps } from '@models/create-campaign-models';

import CreateCampaignsBottomNavigation from '../create-campaigns-bottom-navigation';
import './campaign-types.css';
import CurrencyOptions from '@components/common/currency-options';

const CampaignTypes = (props: CreateCampaignProps) => {
  const { onManageCampaign, draftCampaign } = props;
  const [form] = Form.useForm();
  const { Option } = Select;

  const global_settings: GlobalSettings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS) || 'null');

  //? Prefill data if already have a draft campaign id
  useEffect(() => {
    if (getDraftId()) {
      form.setFieldValue('campaign_name', draftCampaign.data.campaignName);
      form.setFieldValue('reference_number', draftCampaign.data.reference);
      form.setFieldValue('currency', draftCampaign.data.currencyCode || 'EUR');
      form.setFieldValue('campaign_type', draftCampaign.data.campaignType);
    }
  }, [draftCampaign]);

  return (
    <div className='container right-to-left-fade-in'>
      <Form
        form={form}
        name='create_campaign'
        className='campaign-form'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onManageCampaign}
      >
        <Row>
          <Col className='gutter-row' span={12}>
            <div className='campaign_title'>
              <p>Add New Campaign</p>
            </div>
            <Row gutter={16}>
              <Col span={24} className='gutter-row'>
                <p style={{ marginBottom: '20px' }}>Campaign Management</p>
                <Form.Item
                  name='campaign_type'
                  className='mb-4'
                  initialValue={CAMPAIGN_TYPE.DEAL_ID}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value={CAMPAIGN_TYPE.DEAL_ID}>Deal ID</Radio>
                      <Radio value={CAMPAIGN_TYPE.MANAGED_CAMPAIGN}>Managed Campaign</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className='gutter-row' span={12}>
            <Row gutter={16}>
              <Col span={24} className='gutter-row'>
                <Form.Item
                  label='Campaign Name'
                  name='campaign_name'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Campaign Name',
                      type: 'string',
                    },
                  ]}
                  className='mb-4'
                >
                  <Input placeholder='Enter Campaign Name' className='' />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={18} className='gutter-row'>
                <Form.Item
                  label='Reference Number'
                  name='reference_number'
                  className='mb-4'
                >
                  <Input placeholder='Enter Reference Number' className='' />
                </Form.Item>
              </Col>
              <Col span={6} className='gutter-row'>
                <CurrencyOptions required label='Currency' />
              </Col>
            </Row>
          </Col>
        </Row>

        <CreateCampaignsBottomNavigation />
      </Form>
    </div>
  );
};

export default CampaignTypes;
