import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';

import { AppErrorType } from '@models/global-models';
import { getGlobalSettings } from '@services/global-services';
import { getAccessTokenFromMarketPlace } from '@services/marketplace-action-list-services';

import { showErrorModal } from '@helpers/global-helpers';
import { LOCAL_STORAGE_KEYS } from '@constants/storage-constants';
import FullPageLoading from '../../components/full-page-loading/full-page-loading';

const MarketplaceValidationLayout = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasValidToken, setHasValidToken] = useState<boolean>(false);
  const [isError, setIsError] = useState<AppErrorType>();

  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('at');
  const refreshToken = searchParams.get('rt');

  useEffect(() => {
    getAccessTokenFromMarketPlace({ accessToken, refreshToken })
      .then((response) => {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.AUTH_TOKEN,
          JSON.stringify({ access_token: response.data.token })
        );
        getGlobalSettings().catch((err: AppErrorType) =>
          showErrorModal(err?.message, err.error.message)
        );
        setHasValidToken(true);
      })
      .catch((err: AppErrorType) => setIsError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const render = () => {
    if (isLoading) {
      return (<FullPageLoading />);
    } else if (!accessToken || !refreshToken) {
      return (<h1 className='centerItem'>Please Provide MarketPlace Token</h1>);
    } else if (hasValidToken && !isError) {
      return (<Outlet />);
    } else {
      return (<h1 className='centerItem'>Invalid token or session expired</h1>);
    }
  };

  return (
    <Layout>
      {render()}
    </Layout>
  );
};

export default MarketplaceValidationLayout;
