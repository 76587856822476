import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectPage = ({ route = '' }: { route: string }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(route);
  }, []);

  return (<Fragment></Fragment>);
};

export default RedirectPage;