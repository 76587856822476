import { useState } from 'react';
import { Layout, message } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';

import { useSession } from '@hooks/session-hooks';
import { routes } from '@constants/route-constants';
import { userLogout } from '@services/auth-services';
import { AppErrorType } from '@models/global-models';

import {
  FullPageBlurLoading,
  FullPageLoading,
} from '@components/full-page-loading';
import BaseSidebar from './base-sidebar';
import BaseHeader from './base-header';
import IframeBlockerLayout from '../iframe-blocker-layout';
import GlobalErrorHandler from '../../common/global-error-handler/global-error-handler';
import './base-layout.css';

const { Content } = Layout;

interface BaseLayoutProps {
  errorBoundary?: boolean;
}

const BaseLayout = (props: BaseLayoutProps) => {
  const { session, status } = useSession();
  const { errorBoundary } = props;
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!session && !(status === 'loading')) {
    if (!location.pathname.includes('/auth')) {
      navigate(routes.login.path);
    }
  }

  if (!session || status === 'loading') {
    return <FullPageLoading />;
  }

  const handleCollapseClick = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    setIsLoading(true);
    userLogout()
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
        message.success('Successfully logged out');
        navigate(routes.login.path);
      })
      .catch((err: AppErrorType) => message.error(err.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <IframeBlockerLayout>
      <Layout>
        {isLoading && <FullPageBlurLoading />}
        <BaseSidebar collapsed={collapsed} />
        <Layout className="site-layout">
          <BaseHeader
            collapsed={collapsed}
            onCollapseClick={handleCollapseClick}
            onLogout={handleLogout}
          />
          <Content>{errorBoundary ? <GlobalErrorHandler /> : <Outlet />}</Content>
        </Layout>
      </Layout>
    </IframeBlockerLayout>
  );
};

export default BaseLayout;
