import { FC, Fragment } from 'react';

interface OsiBarProps {
  value: number;
}

const OsiBar: FC<OsiBarProps> = ({ value }: OsiBarProps) => {
  let bgColor: string = '#FFFFFF';
  const boxArr: number[] = [1, 2, 3, 4, 5];
  const colorCount = Math.min(Math.ceil(value / 20), 5);

  if (value === 0) bgColor = '#FFFFFF';
  else if (value <= 20) bgColor = '#FA541C';
  else if (value <= 60) bgColor = '#FEC400';
  else if (value > 60) bgColor = '#03F0A9';

  return (
    <div className="osi-box-container">
      {boxArr.map((num) => (
        <Fragment key={num}>
          {num <= colorCount ? (
            <div className="osi-box" style={{ backgroundColor: bgColor }} />
          ) : (
            <div className="osi-box" style={{ backgroundColor: '#FFFFFF' }} />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default OsiBar;
