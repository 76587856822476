import { ReactNode } from 'react';
import { Button, Modal } from 'antd';

interface ConfirmationModalProps {
  btnName?: string;
  title?: ReactNode;
  content?: ReactNode;
  className?: string;
  handleOk?: () => any;
  handleCancel?: () => any;
}

const ConfirmationModal = ({
  btnName = 'Cancel',
  title = 'Are you sure you want to cancel?',
  content = '',
  className = '',
  handleOk = () => null,
  handleCancel = () => null,
}: ConfirmationModalProps) => {

  const showConfirmation = () => {
    Modal.confirm({
      title,
      content,
      icon: null,
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        handleOk();
        return null;
      },
      onCancel() {
        handleCancel();
        return null;
      },
    });
  };

  return (
    <Button className={`ant-btn-secondary ${className}`} onClick={showConfirmation}>
      {btnName}
    </Button>
  );
};

export default ConfirmationModal;