export const DEALS_BREADCRUMB_ITEMS = [
  [
    { label: 'New Campaign', value: 0 }
  ],
  [
    { label: 'New Campaign', value: 0 },
    { label: 'DSP Selection', value: 1 }
  ],
  [],
  [
    { label: 'New Campaign', value: 0 },
    { label: 'DSP Selection', value: 1 },
    { label: 'Dates and Budget', value: 3 }
  ],
  [],
  [
    { label: 'New Campaign', value: 0 },
    { label: 'DSP Selection', value: 1 },
    { label: 'Dates and Budget', value: 3 },
    { label: 'Targeting', value: 5 },
  ],
  [
    { label: 'New Campaign', value: 0 },
    { label: 'DSP Selection', value: 1 },
    { label: 'Dates and Budget', value: 3 },
    { label: 'Targeting', value: 5 },
    { label: 'Add Line Item', value: 6 },
  ],
];

export const CAMPAIGN_BREADCRUMB_ITEMS = [
  [
    { label: 'New Campaign', value: 0 }
  ],
  [],
  [],
  [
    { label: 'New Campaign', value: 0 },
    { label: 'Dates and Budget', value: 3 }
  ],
  [
    { label: 'New Campaign', value: 0 },
    { label: 'Dates and Budget', value: 3 },
    { label: 'Creative', value: 4 },
  ],
  [
    { label: 'New Campaign', value: 0 },
    { label: 'Dates and Budget', value: 3 },
    { label: 'Creative', value: 4 },
    { label: 'Targeting', value: 5 }
  ],
  [
    { label: 'New Campaign', value: 0 },
    { label: 'Dates and Budget', value: 3 },
    { label: 'Creative', value: 4 },
    { label: 'Targeting', value: 5 },
    { label: 'Add Line Item', value: 6 },
  ]
];