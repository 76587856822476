import { Link, useNavigate } from 'react-router-dom';
import { Menu, Image, Layout, MenuProps } from 'antd';
import { BookIcon, FormOutlineIcon, PlusIcon, StarIcon } from '@icons';
import { routes } from '@constants/route-constants';
import { BaseSidebarProps } from '@models/layout-models';
import companyImage from '@images/azerion-logo-full-white.svg';
import compnayTinyLogo from '@images/azerion-tiny-logo-white.png';
import './base-sidebar.css';

type MenuItem = Required<MenuProps>['items'][number];

const { Sider } = Layout;
const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  disabled?: boolean,
  children?: MenuItem[],
  type?: 'group'
): MenuItem => {
  return {
    label,
    key,
    icon,
    disabled,
    children,
    type,
  } as MenuItem;
};

const items: MenuProps['items'] = [
  getItem(routes.favourites.title, routes.favourites.key, <StarIcon />, true),
  getItem(
    <Link to={routes.actionList.path}>{routes.actionList.title}</Link>,
    routes.actionList.key,
    <FormOutlineIcon />
  ),
  getItem(routes.campaigns.title, routes.campaigns.key, <BookIcon />, false, [
    getItem(
      <Link to={routes.myCampaigns.path}>{routes.myCampaigns.title}</Link>,
      routes.myCampaigns.key,
      <BookIcon />
    ),
    getItem(
      <Link to={routes.watchedCampaigns.path}>
        {routes.watchedCampaigns.title}
      </Link>,
      routes.watchedCampaigns.key,
      <StarIcon />
    ),
  ]),
  getItem(
    <Link to={routes.createCampaign.path}>{routes.createCampaign.title}</Link>,
    routes.createCampaign.key,
    <PlusIcon />,
    false
  ),
];

const BaseSidebar = (props: BaseSidebarProps) => {
  const { collapsed } = props;
  const navigate = useNavigate();

  return (
    <Sider
      collapsible
      trigger={null}
      collapsed={collapsed}
      className={`${collapsed ? 'collapsed-sidebar' : ''} sidebar`}
    >
      <Image
        rootClassName="logo"
        src={collapsed ? compnayTinyLogo : companyImage}
        preview={false}
        alt="Azerion"
        className={collapsed ? 'tiny-logo' : 'full-logo'}
        onClick={() => navigate(routes.myCampaigns.key)}
      />
      <Menu
        items={items}
        defaultSelectedKeys={[routes.myCampaigns.key]}
        selectedKeys={[location.pathname.substring(1)]}
        defaultOpenKeys={[routes.campaigns.key]}
        mode='inline'
      />
    </Sider>
  );
};

export default BaseSidebar;
