import HttpClientInstance from './base-services';
import { API_END_POINTS } from '@constants/global-constants';
import {
  CreateMetaAdvertiserPayload,
  CreateMetaAdvertiserResponse,
  CreateMetaDataSalesPersonPayload,
  GetMetaCampaignParams,
  GetMetaCampaignsResponse,
  MetaDataAdOpsPersons,
  MetaDataAdvertisers,
  MetaDataAgencies,
  MetaDataPlatform,
  MetaDataSalesPersons,
  UpdateMetaDataPayload,
} from '@models/meta-campaign-models';
import {
  IntegrationPlatformEnum,
  MetaCampaignStatusEnum,
} from '@models/campaign-models';

const httpClient = new HttpClientInstance();

export async function getMetaCampaigns(
  params: GetMetaCampaignParams
): Promise<GetMetaCampaignsResponse> {
  return await httpClient.get(
    `${API_END_POINTS.METADATA_CAMPAIGNS}?${getMetaCampaignsRequestParams(
      params
    )}`
  );
}

export function getMetaCampaignsRequestParams(
  params: GetMetaCampaignParams
): string {
  const {
    page,
    limit,
    search,
    metaCampaignStatus,
    businessUnitId,
    integrationPlatform,
    sort,
  } = params;
  let url = '';

  if (metaCampaignStatus?.length && metaCampaignStatus !== MetaCampaignStatusEnum.ALL) {
    url = url.concat(
      `&isCompleted=${getMetaCampaignStatusParam(metaCampaignStatus)}`
    );
  }

  if (businessUnitId.length) {
    url = url.concat(`&businessUnitId=${businessUnitId.join(',')}`);
  }

  if (search && search.length > 2) {
    url = url.concat(`&search=${search}`);
  }

  if (limit) {
    url = url.concat(`&limit=${limit}`);
  }

  if (page) {
    url = url.concat(`&page=${page}`);
  }

  if (sort) {
    url = url.concat(`&sort=${sort}`);
  }

  if (integrationPlatform.length) {
    url = url.concat(
      `&integrationPlatformId=${getIntegrationPlatformParam(
        integrationPlatform
      )}`
    );
  }

  return url.substring(1);
}

const getMetaCampaignStatusParam = (metaCampaignStatus: string): boolean => {
  if (metaCampaignStatus === MetaCampaignStatusEnum.COMPLETED) {
    return true;
  }

  if (metaCampaignStatus === MetaCampaignStatusEnum.MISSING) {
    return false;
  }

  throw new Error(
    `Unexpecged metaCampaignStatus value, expected one of [${MetaCampaignStatusEnum.COMPLETED}, ${MetaCampaignStatusEnum.MISSING}] `
  );
};

const getIntegrationPlatformParam = (
  integrationPlatform: IntegrationPlatformEnum[]
): string => {
  return integrationPlatform.join(',');
};

export async function metaDataIntregationPlatforms(): Promise<
  MetaDataPlatform[]
> {
  return await httpClient.get(API_END_POINTS.METADATA_INTREGRATION_PLATFORMS);
}

export async function metaDataSalesPersons(): Promise<MetaDataSalesPersons[]> {
  return await httpClient.get(API_END_POINTS.METADATA_SALES_PERSONS);
}

export async function metaDataAdOpsPersons(): Promise<MetaDataAdOpsPersons[]> {
  return await httpClient.get(API_END_POINTS.METADATA_ADOPS_PERSONS);
}

export async function metaDataAdvertisers(): Promise<MetaDataAdvertisers[]> {
  return await httpClient.get(API_END_POINTS.METADATA_ADVERTISERS);
}

export async function metaDataAgencies(): Promise<MetaDataAgencies[]> {
  return await httpClient.get(API_END_POINTS.META_DATA_AGENCIES);
}

export async function updateMetaData(
  data: UpdateMetaDataPayload,
  compositeId: string
): Promise<any> {
  return await httpClient.patch(
    `${API_END_POINTS.METADATA_CAMPAIGNS}/${compositeId}`,
    { data }
  );
}

export async function createMetaDataAdvertiser(
  data: CreateMetaAdvertiserPayload
): Promise<CreateMetaAdvertiserResponse> {
  return await httpClient.post(API_END_POINTS.METADATA_ADVERTISERS, { data });
}

export async function createMetaDataSalesPerson(
  data: CreateMetaDataSalesPersonPayload
): Promise<MetaDataSalesPersons> {
  return await httpClient.post(API_END_POINTS.METADATA_SALES_PERSONS, { data });
}
