import dayjs from 'dayjs';
import { toPlainObject } from 'lodash';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Pagination, Table } from 'antd';
import { FC, Fragment, useContext, useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';

import {
  CheckCircleIcon,
  ActionListEditIcon,
  ExclamationCircleIcon,
} from '@icons';
import {
  MetaCampaignData,
  MetaCampaignTableProps,
} from '@models/meta-campaign-models';

import {
  ACTION_ITEMS,
  THEME_COLORS,
  INIT_PAGE_ITEMS_LIMIT,
} from '@constants/global-constants';
import { sortDirection } from '@helpers/global-helpers';
import MetaCampaignModal from './meta-campaign-modal';
import { ActionListContext } from '@contexts/action-list-context';

const MetaCampaignTable: FC<MetaCampaignTableProps> = ({
  totalMetaCampaignData,
  metaCampaignData,
  getMetaCampaignsApiParams,
  scroll
}: MetaCampaignTableProps) => {
  const { loadingStates, setApiParams } = useContext(ActionListContext);

  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [modalData, setModalData] = useState<MetaCampaignData | null>(null);

  const showModal = (record: MetaCampaignData) => {
    setModalData(record);
    setOpenModal(true);
  };

  const closeModal = () => {
    setModalData(null);
    setOpenModal(false);
  };

  //? For sync the pagination with params pagination
  useEffect(() => {
    setCurrentPage(getMetaCampaignsApiParams.page ?? 1);
  }, [getMetaCampaignsApiParams]);




  const pageChangeHandler = (page: number, limit: number) => {
    setApiParams((prev) => ({ ...prev, page, limit }));
  };

  const onTableChange = (_: any, __: any, sort: any) => {
    const column = toPlainObject(sort)?.column;
    const sortParam = column?.sorter
      ? `${sortDirection(sort)}${column?.dataIndex}`
      : '';

    setApiParams((state) => ({ ...state, sort: sortParam }));
  };

  const MetaCampaignColumns: ColumnsType<MetaCampaignData> = [
    {
      width: 0,
      fixed: 'left',
    },
    {
      width: 40,
      fixed: 'left',
      dataIndex: 'none',
      render: (_, record: MetaCampaignData) => (
        <ActionListEditIcon
          style={{ cursor: 'pointer', marginLeft: '5px' }}
          onClick={() => showModal(record)}
        />
      ),
    },
    {
      width: 40,
      fixed: 'left',
      render: (_, record) => {
        if (record?.isCompleted) {
          return <CheckCircleIcon color={THEME_COLORS.COMPLETED} />;
        } else return <ExclamationCircleIcon color={THEME_COLORS.MISSING} />;
      },
    },
    {
      width: 400,
      key: 'campaignName',
      title: 'Name',
      dataIndex: 'campaignName',
      fixed: 'left',
      render: (value, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: 500 }}>{value}</span>
            <span style={{ color: THEME_COLORS.DISABLED }}>
              {record.campaignId}
            </span>
          </div>
        );
      },
    },
    {
      width: 105,
      key: 'campaignStartDate',
      title: 'Start Date',
      dataIndex: 'campaignStartDate',
      showSorterTooltip: false,
      render: (value) => (value ? dayjs(value).format('DD/MM/YYYY') : ''),
      sorter: true,
    },
    {
      width: 105,
      key: 'campaignEndDate',
      title: 'End Date',
      dataIndex: 'campaignEndDate',
      showSorterTooltip: false,
      render: (value) => (value ? dayjs(value).format('DD/MM/YYYY') : ''),
      sorter: true,
    },
    {
      width: 105,
      key: 'integrationPlatformAlias',
      title: 'DSP',
      dataIndex: 'integrationPlatformAlias',
      render: (value: string) => <span>{value}</span>,
    },
    {
      width: 130,
      key: 'seatName',
      title: 'Seat',
      dataIndex: 'seatName',
      showSorterTooltip: false,
      render: (value: string, record) => (
        <span>
          {value ? value.split(':')[1] ?? '' : ''}{' '}
          {value ? `(${record?.seatId})` ?? '' : record?.seatId ?? ''}
        </span>
      ),
    },
    {
      width: 135,
      key: 'campaignType',
      title: 'Object Type',
      dataIndex: 'campaignType',
      showSorterTooltip: false,
      render: (value: string) => <span>{value}</span>,
    },
    {
      width: 85,
      key: 'agencyName',
      title: 'Agency',
      dataIndex: 'agencyName',
      showSorterTooltip: false,
      render: (value: string) => <span>{value}</span>,
    },
    {
      width: 135,
      key: 'advertiserName',
      title: 'Advertiser',
      dataIndex: 'advertiserName',
      showSorterTooltip: false,
      render: (value: string) => <span>{value}</span>,
    },
    // {
    //   width: 105,
    //   align: 'right',
    //   key: 'netRevenue',
    //   title: 'Net Revenue',
    //   dataIndex: 'netRevenue',
    //   showSorterTooltip: false,
    //   render: (value: number, record: MetaCampaignData) => (
    //     <span>{value ? `${value} ${record?.currencyCode}` : ''}</span>
    //   ),
    // },
    {
      width: 135,
      key: 'businessUnitName',
      title: 'BU',
      dataIndex: 'businessUnitName',
      render: (value: string) => <span>{value}</span>,
    },
    {
      width: 120,
      key: 'salesPersonName',
      title: 'Sales Person',
      dataIndex: 'salesPersonName',
      showSorterTooltip: false,
      render: (value: string) => <span>{value}</span>,
    },
    {
      width: 120,
      key: 'adOpsPersonName',
      title: 'AdOps User',
      dataIndex: 'adOpsPersonName',
      showSorterTooltip: false,
      render: (value: string) => <span>{value}</span>,
    },
    {
      width: 105,
      key: 'updatedByUserAt',
      title: 'Data Modified',
      dataIndex: 'updatedByUserAt',
      render: (value: string) =>
        value ? dayjs(value).format('DD/MM/YYYY') : '',
    },
    {
      width: 50,
      align: 'center',
      key: 'action',
      dataIndex: 'action',
      fixed: 'right',
      render: () => (
        <Dropdown menu={{ items: ACTION_ITEMS }}>
          <MoreOutlined style={{ fontSize: '17px' }} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Fragment>
      <Table
        rowKey={(record) => record._id}
        size='middle'
        columns={MetaCampaignColumns}
        pagination={false}
        scroll={{ ...scroll }}
        dataSource={metaCampaignData}
        loading={loadingStates.isMetaDataLoading}
        className='campaign-table'
        onChange={onTableChange}
      />

      {modalData && (
        <MetaCampaignModal
          modalData={modalData}
          isOpen={openModal}
          onOk={closeModal}
          onCancel={closeModal}
        />
      )}

      <Pagination
        hideOnSinglePage
        showSizeChanger
        className='pagination'
        current={currentPage}
        total={totalMetaCampaignData}
        defaultPageSize={INIT_PAGE_ITEMS_LIMIT}
        onChange={(page, limit) => pageChangeHandler(page, limit)}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
      />
    </Fragment>
  );
};

export default MetaCampaignTable;
