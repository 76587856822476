import { Input, Row, Col, Select, Button } from 'antd';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import {
  CampaignStatusEnum,
  DropdownValuesType,
  CampaignTableFilterProps,
  IntegrationPlatformEnum,
  CampaignTypeEnum,
  GetCampaignsParams,
} from '@models/campaign-models';
import { OptionType } from '@models/global-models';

import {
  enumToOptions,
  getBusinessUnitsFromStorage,
} from '@helpers/global-helpers';
import {
  STATUS_OPTIONS,
  PLATFORM_OPTIONS,
  INIT_CAMPAIGNS_PARAMS,
  INIT_DROPDOWN_VALUES,
  PLATFORM_OPTIONS_WITHOUT_MARKETPLACE,
} from '@constants/campaign-data';
import { SearchIcon } from '@icons';
import './table-filters.css';

const prefix = <SearchIcon className='search-icon' />;

const CampaignTableFilter: FC<CampaignTableFilterProps> = (
  props: CampaignTableFilterProps
) => {
  const { isDedicatedPage, setGetCampaignsApiParams } = props;
  const [dropdownValues, setDropdownValues] = useState<DropdownValuesType>({
    ...INIT_DROPDOWN_VALUES,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [businessUnits, setBusinessUnits] = useState<OptionType[]>([]);
  const [campaignTypes, setCampaignTypes] = useState<OptionType[]>([]);

  useEffect(() => {
    if (businessUnits.length === 0) getBusinessUnits();
    if (campaignTypes.length === 0) getCampaignTypes();

    setIsLoading(false);
  }, []);

  const updateApiParams = (key: keyof GetCampaignsParams, value: any[] | string) => {
    setGetCampaignsApiParams((prevState) => ({
      ...prevState,
      [key]: value,
      page: 1,
    }));
  };

  const updateDropdownValues = (key: keyof DropdownValuesType, value: any[]) => {
    setDropdownValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handlePlatformChange = (value: IntegrationPlatformEnum[]) => {
    updateApiParams('integrationPlatform', [...value]);
    updateDropdownValues('platformValues', [...value]);
  };

  const getBusinessUnits = async () => {
    const options = await getBusinessUnitsFromStorage();

    if (businessUnits.length === 0 && options.length) {
      setBusinessUnits(options);
    }
  };

  const handleBusinessUnitChange = (value: number[]) => {
    updateApiParams('businessUnitId', [...value]);
    updateDropdownValues('businessUnitValues', [...value]);
  };

  const handleStatusChange = (value: CampaignStatusEnum[]) => {
    updateApiParams('entityStatus', [...value]);
    updateDropdownValues('statusValue', [...value]);
  };

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateApiParams('search', event.target.value);
  };

  const getCampaignTypes = () => {
    const _campaignTypes = enumToOptions(CampaignTypeEnum);

    if (_campaignTypes.length) {
      setCampaignTypes(_campaignTypes);
    }
  };

  const handleCampaignTypeChange = (value: CampaignTypeEnum[]) => {
    updateApiParams('campaignType', [...value]);
    updateDropdownValues('campaignTypeValues', [...value]);
  };

  const handleClear = () => {
    setDropdownValues({ ...INIT_DROPDOWN_VALUES });
    setGetCampaignsApiParams({ ...INIT_CAMPAIGNS_PARAMS });
  };

  return (
    <div>
      <div className='search-section'>
        <Row gutter={10} className='search-container'>
          <Col span={16}>
            <Input
              placeholder='Search'
              prefix={prefix}
              className='search-field'
              onChange={handleSearchOnChange}
            />
          </Col>
        </Row>
      </div>

      <Row gutter={16} className='filter-section'>
        <Col span={5}>
          <Select
            className='filter-dropdown'
            showArrow
            allowClear
            mode='multiple'
            maxTagCount='responsive'
            style={{ width: '100%' }}
            placeholder='Select Status'
            defaultValue={[CampaignStatusEnum.ACTIVE]}
            value={dropdownValues.statusValue}
            options={STATUS_OPTIONS}
            onChange={handleStatusChange}
          />
        </Col>

        <Col span={5}>
          <Select
            className='filter-dropdown'
            showArrow
            allowClear
            mode='multiple'
            maxTagCount='responsive'
            style={{ width: '100%' }}
            placeholder='Select Platform'
            value={dropdownValues.platformValues}
            options={isDedicatedPage ? PLATFORM_OPTIONS_WITHOUT_MARKETPLACE : PLATFORM_OPTIONS}
            onChange={handlePlatformChange}
          />
        </Col>

        <Col span={6}>
          <Select
            className='filter-dropdown'
            showArrow
            allowClear
            mode='multiple'
            maxTagCount='responsive'
            style={{ width: '100%' }}
            placeholder='Select Business Unit'
            loading={isLoading}
            onChange={handleBusinessUnitChange}
            optionFilterProp='children'
            filterOption={(input, businessUnit) =>
              (businessUnit?.label?.toString().toLowerCase() ?? '').includes(
                input.toLowerCase()
              )
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toString()
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toString().toLowerCase())
            }
            options={businessUnits}
            value={dropdownValues.businessUnitValues}
          />
        </Col>

        {!isDedicatedPage && (
          <Col span={4}>
            <Select
              className='filter-dropdown'
              showArrow
              allowClear
              mode='multiple'
              style={{ width: '100%' }}
              placeholder='Select Campaign Types'
              options={campaignTypes}
              onChange={handleCampaignTypeChange}
            />
          </Col>
        )}

        <Col span={4}>
          <Button ghost type='primary' onClick={handleClear}>
            Clear All
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default CampaignTableFilter;
