import { StarFilled } from '@ant-design/icons';

import {
  CampaignLoadings,
  CampaignStatusEnum,
  DropdownValuesType,
  GetCampaignsParams,
  CampaignDayViewEnum,
  MetaCampaignStatusEnum,
} from '@models/campaign-models';
import { GenericOptionType, OptionType } from '@models/global-models';

import xandrLogo from '@images/xandr.png';
import dv360Logo from '@images/dv360.png';
import marketplaceLogo from '@images/marketplace.png';
import { INIT_PAGE_ITEMS_LIMIT } from './global-constants';
import { THEME_COLORS } from '@constants/global-constants';
import {
  DropdownMetaEditValuesType,
  DropdownMetaValuesType,
  GetMetaCampaignParams,
  MetaCampaignLoadingTypes,
} from '@models/meta-campaign-models';
import { CheckCircleIcon, ExclamationCircleIcon } from '@icons';
import { generatePlatform, generateStatus } from '@helpers/global-helpers';

export enum INTEGRATION_PLATFORM_TYPES {
  MARKETPLACE = 'MARKETPLACE',
  DV360 = 'DV360',
  XANDR = 'XANDR',
  ISAP = 'ISAP',
  KEVEL = 'KEVEL',
  BEESWAX = 'BEESWAX',
  YAHOO = 'YAHOO',
  ZEMANTA = 'ZEMANTA',
}

export const PLATFORM_OPTIONS = [
  {
    value: INTEGRATION_PLATFORM_TYPES.MARKETPLACE,
    label: generatePlatform(marketplaceLogo, 'Marketplace'),
  },
  {
    value: INTEGRATION_PLATFORM_TYPES.XANDR,
    label: generatePlatform(xandrLogo, 'Xandr'),
  },
  {
    value: INTEGRATION_PLATFORM_TYPES.DV360,
    label: generatePlatform(dv360Logo, 'Dv360'),
  },
];

export const PLATFORM_OPTIONS_WITHOUT_MARKETPLACE = [
  {
    value: INTEGRATION_PLATFORM_TYPES.XANDR,
    label: generatePlatform(xandrLogo, 'Xandr'),
  },
  {
    value: INTEGRATION_PLATFORM_TYPES.DV360,
    label: generatePlatform(dv360Logo, 'Dv360'),
  },
];

export const STATUS_OPTIONS = [
  {
    value: CampaignStatusEnum.DRAFT,
    label: generateStatus('Draft', THEME_COLORS.DRAFT),
  },
  {
    value: CampaignStatusEnum.ACTIVE,
    label: generateStatus('Active', THEME_COLORS.ACTIVE),
  },
  {
    value: CampaignStatusEnum.PAUSE,
    label: generateStatus('Pause', THEME_COLORS.PAUSE_INACTIVE),
  },
  {
    value: CampaignStatusEnum.FINISHED,
    label: generateStatus('Finished', THEME_COLORS.FINISHED),
  },
];

export const META_STATUS_OPTIONS = [
  {
    value: MetaCampaignStatusEnum.COMPLETED,
    label: (
      <div className='v-center'>
        <CheckCircleIcon
          height={14}
          width={14}
          className='mr'
          color={THEME_COLORS.COMPLETED}
        />
        Completed
      </div>
    ),
  },
  {
    value: MetaCampaignStatusEnum.MISSING,
    label: (
      <div className='v-center'>
        <ExclamationCircleIcon
          height={14}
          width={14}
          className='mr'
          color={THEME_COLORS.MISSING}
        />
        Missing
      </div>
    ),
  },
  {
    value: MetaCampaignStatusEnum.ALL,
    label: <span style={{ marginLeft: '20px' }}>All</span>,
  },
];

export const DAY_OPTIONS: GenericOptionType<CampaignDayViewEnum>[] = [
  { value: CampaignDayViewEnum.D7, label: 'Last 7 Days' },
  { value: CampaignDayViewEnum.D15, label: 'Last 15 Days' },
  { value: CampaignDayViewEnum.M1, label: 'Last 1 month' },
  { value: CampaignDayViewEnum.M3, label: 'Last 3 months' },
  { value: CampaignDayViewEnum.M6, label: 'Last 6 months' },
  { value: CampaignDayViewEnum.Y1, label: 'Last 1 Year' },
];

export const FAVOURITE_OPTIONS: OptionType[] = [
  {
    value: 'favourites',
    label: (
      <span>
        {' '}
        <StarFilled /> Favourites{' '}
      </span>
    ),
  },
];

export const INIT_CAMPAIGN_LOADING_STATES: CampaignLoadings = {
  isCampaignsLoading: true,
};

export const INIT_CAMPAIGNS_PARAMS: GetCampaignsParams = {
  entityStatus: [CampaignStatusEnum.ACTIVE],
  integrationPlatform: [],
  businessUnitId: [],
  campaignType: [],
  page: 1,
  limit: INIT_PAGE_ITEMS_LIMIT,
};

export const INIT_META_CAMPAIGNS_PARAMS: GetMetaCampaignParams = {
  metaCampaignStatus: '',
  integrationPlatform: [],
  businessUnitId: [],
  page: 1,
  limit: INIT_PAGE_ITEMS_LIMIT,
};

export const INIT_META_CAMPAIGNS_LOADERS: MetaCampaignLoadingTypes = {
  //? Data fetching loaders
  isMetaDataLoading: true,
  isAgenciesLoading: true,
  isAdvertisersLoading: true,
  isSalesPersonsLoading: true,
  isAdOpsPersonsLoading: true,
  isBULoading: true,

  //? Data creation loaders
  isAdvertiserCreating: false,
  isSalesPersonCreating: false,
};

export const INIT_DROPDOWN_VALUES: DropdownValuesType = {
  platformValues: [],
  businessUnitValues: [],
  statusValue: [CampaignStatusEnum.ACTIVE],
  dayValue: CampaignDayViewEnum.D7,
  campaignTypeValues: [],
};

export const INIT_META_DROPDOWN_VALUES: DropdownMetaValuesType = {
  platformValues: [],
  businessUnitValues: [],
};

export const INIT_META_EDIT_DROPDOWN_VALUES: DropdownMetaEditValuesType = {
  businessUnitValues: '',
  salesPersonsValues: '',
  adOpsPersonsValues: '',
  advertisersValues: '',
  agenciesValues: '',
};

export const CREATIVE_TYPES = {
  DISPLAY: 'DISPLAY_IAB',
  VIDEO: 'VIDEO',
  ACP: 'ACP',
  RICH_MEDIA: 'RICH_MEDIA',
  NATIVE: 'NATIVE',
};

export const CREATIVE_MEDIA_TYPES = {
  THIRD_PARTY_TAG: 'THIRD_PARTY_TAG',
  MOBILE_IN_APP_THIRD_PARTY_TAG: 'MOBILE_IN_APP_THIRD_PARTY_TAG',
  VAST: 'VAST',
};

export enum DEVICE_TARGETING_TYPES {
  PC = 'PC',
  CONNECTED_TV = 'CONNECTED_TV',
  SMART_PHONE = 'SMART_PHONE',
  TABLET = 'TABLET',
}

export const DEVICE_TARGETING_OPTIONS = [
  { value: DEVICE_TARGETING_TYPES.PC, label: 'PC' },
  { value: DEVICE_TARGETING_TYPES.CONNECTED_TV, label: 'Connected TV' },
  { value: DEVICE_TARGETING_TYPES.SMART_PHONE, label: 'Smart Phone' },
  { value: DEVICE_TARGETING_TYPES.TABLET, label: 'Tablet' },
];

export const ENTITY_STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  PAUSE: 'pause/inactive',
  FINISHED: 'finished',
};
