import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input, Button, Typography, message } from 'antd';

import { Credentials } from '@models/auth-models';
import { AppErrorType } from '@models/global-models';
import { userLogin } from '@services/auth-services';
import { getUserDetails } from '@services/user-services';

import companyImage from '@images/azerion.png';
import { routes } from '@constants/route-constants';
import { LOCAL_STORAGE_KEYS } from '@constants/storage-constants';
import './login.css';

const { Text } = Typography;

const Login: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogin = (values: Credentials) => {
    setIsLoading(true);
    userLogin({ ...values })
      .then(() => {
        message.success('Successfully logged in');
        navigate(routes.myCampaigns.path);
        getUserDetails()
          .then((value) => {
            localStorage.setItem(
              LOCAL_STORAGE_KEYS.USER_INFO,
              JSON.stringify(value)
            );
          })
          .catch(() =>
            message.error('Error occured while fetching User Details')
          );
      })
      .catch((err: AppErrorType) => message.error(err?.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="main-container">
      <div className="login-container">
        <div className="company-image-container">
          <img className="company-image" src={companyImage} alt="Azerion" />
        </div>

        <div className="login-text-container">
          <Text className="login-title">Login</Text>
          <Text className="login-subtitle">Sign In to your account</Text>
        </div>

        <Form name="normal_login" className="login-form" onFinish={handleLogin}>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please enter your Email!',
                type: 'email',
              },
            ]}
            className="mb-4"
          >
            <Input
              prefix={
                <UserOutlined className="site-form-item-icon login-icon" />
              }
              placeholder="Email"
              className="login-input"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your Password!' }]}
          >
            <Input
              prefix={
                <LockOutlined className="site-form-item-icon login-icon" />
              }
              type="password"
              placeholder="Password"
              className="login-input"
            />
          </Form.Item>

          <Form.Item className="mb-0">
            <Button
              type="primary"
              htmlType="submit"
              className="login-button"
              loading={isLoading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
