import { Fragment } from 'react';
import { THEME_COLORS } from '@constants/global-constants';
import { ModalTitleProps } from '@models/meta-campaign-models';
import { RightLongArrow } from '@icons';
import dayjs from 'dayjs';

const MetaCampaignModalTitle = (props: ModalTitleProps) => {
  const { modalData } = props;
  return (
    <Fragment>
      <div className='modal-title-width'>
        <p>{modalData?.campaignName}</p>
        <div className='modal-title-style'>
          <span>{modalData?.businessUnitName}</span>
          <span>
            {modalData?.campaignStartDate ? dayjs(modalData?.campaignStartDate).format('DD/MM/YYYY') : ''}
            {modalData?.campaignStartDate ? <RightLongArrow style={{ margin: '0 10px', color: THEME_COLORS.DISABLED }} /> : ''}
            {modalData?.campaignEndDate ? dayjs(modalData?.campaignEndDate).format('DD/MM/YYYY') : ''}
          </span>
        </div>
      </div>
      <div className='line-0 modal-title-line' />
    </Fragment>
  );
};

export default MetaCampaignModalTitle;
