import dayjs from 'dayjs';
import { Fragment } from 'react';
import { Image, Tooltip } from 'antd';
import { EditOutlined, CopyOutlined, DeleteOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { RightLongArrow } from '@icons';
import { THEME_COLORS, THEME_FONT_SIZE } from '@constants/global-constants';
import { IntegrationPlatformEnum, LineItemMainBodyProps } from '@models/campaign-models';

import XandrLogo from '@images/xandr.png';
import DV360Logo from '@images/dv360.png';
import MarketplaceLogo from '@images/marketplace.png';

const LineItemMainBody = ({
  isCollapsed,
  isEditTitle,
  titleRef,
  data,
  draftCampaign,
  handleCopy,
  handleEdit,
  handleDelete,
  handleLineItemTitleEdit,
  handleEditCreative,
  isCreated
}: LineItemMainBodyProps) => {
  const { lineItemName, startDate, endDate, budget, margin, cpm, pacing, integrationPlatform } = data;
  const { geoTargeting, platformTypes } = data?.targeting ?? {};

  let creativeItem;
  if (data?.creatives?.length) {
    creativeItem = draftCampaign?.data?.creativesItems?.find(item => item.id === data.creatives[0]);
  }

  const getDSPLogo = (): string => {
    return integrationPlatform === IntegrationPlatformEnum.DV360 ? DV360Logo : (integrationPlatform === IntegrationPlatformEnum.MARKETPLACE ? MarketplaceLogo : XandrLogo);
  };

  const actionIconClassName = () => {
    return { fontSize: THEME_FONT_SIZE.DEFAULT, color: isCreated ? THEME_COLORS.DISABLED : '', cursor: isCreated ? 'no-drop' : 'pointer' };
  };

  return (
    <div className='preview-line-item-container'>
      <div className='preview-line-item-header'>
        <div>
          <div
            ref={titleRef}
            contentEditable={isEditTitle}
            className={`line-item-title ${isEditTitle ? 'edit' : ''}`}
          >
            {lineItemName}
          </div>
          {!isCreated && <div onClick={handleLineItemTitleEdit} className='line-item-rename'>
            {isEditTitle ? 'save' : 'rename'}
          </div>}
        </div>

        <div className='line-item-action-icon-container'>
          <Tooltip placement="bottom" title="Copy LineItem">
            <CopyOutlined onClick={handleCopy} style={actionIconClassName()} />
          </Tooltip>
          <Tooltip placement="bottom" title="Edit LineItem">
            <EditOutlined onClick={handleEdit} style={actionIconClassName()} />
          </Tooltip>
          <Tooltip placement="bottom" title="Delete LineItem">
            <DeleteOutlined onClick={handleDelete} style={actionIconClassName()} />
          </Tooltip>

        </div>
      </div>

      <div className='preview-line-item-body'>
        <LineItemProperty
          label='Dates'
          value={
            <span>
              {dayjs(startDate).format('DD/MM/YYYY')}
              <RightLongArrow style={{ margin: '0 10px' }} />
              {dayjs(endDate).format('DD/MM/YYYY')}
            </span>
          }
        />
        <LineItemProperty label='Impressions' value={'19.260'} />
        <LineItemProperty label='Budget' value={`${budget} ${draftCampaign?.data?.currencyCode ?? ''}`} />
        <LineItemProperty label='Margin' value={margin ? margin + '%' : '0%'} />
        <LineItemProperty label='CPM' value={cpm} />
        <LineItemProperty label='Pacing' value={pacing} />
        {!isCollapsed && (
          <Fragment>
            <LineItemProperty
              label='Country'
              value={geoTargeting ? `${geoTargeting?.region} / ${geoTargeting?.country}` : '__'}
            />
            <LineItemProperty label='Platform' value={!platformTypes?.length ? '__' : platformTypes.join(' & ')} />
            <LineItemProperty label='Creative' value={creativeItem?.creativeType ?? '__'} />
            <LineItemProperty
              label='Creative List'
              value={
                <p onClick={handleEditCreative}
                  className='line-item-rename'
                ><ArrowLeftOutlined /> Edit </p>
              }
            />
            <LineItemProperty
              label='Status'
              value={isCreated ? <span><span className='dot' style={{ backgroundColor: THEME_COLORS.ACTIVE }} /> Active </span> : 'N/A'}
            />
            <LineItemProperty
              label='DSP'
              value={isCreated ? <Image width={20} height={20} src={getDSPLogo()} /> : '__'}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default LineItemMainBody;

const LineItemProperty = ({ label, value }: { label: React.ReactNode, value: any }) => {
  return (
    <div>
      <p className='line-item-subtitle'> {label} </p>
      <p> {value} </p>
    </div>
  );
};