import { RefObject, SetStateAction } from 'react';
import { MessageType, PaginationResponseType } from './global-models';
import {
  CreateCampaignTargetingModel,
  TargetingDataModel,
} from './targeting-model';

export interface MyCampaignsProps {
  isDedicatedPage?: boolean;
}

export interface CampaignData {
  key?: string; // This property added to make custom data
  _id: string;
  advertiserId: string;
  advertiserName: string;
  businessUnitId: string;
  businessUnitName: string;
  campaignId: string | number;
  campaignName: string;
  entityStatus: string;
  currency: string;
  integrationPlatform: string;
  reference: string;
  integrationPlatformEntityUrl: string;
  startDate: string;
  endDate: string;
  impressions: number;
  clicks: number;
  ctr: number;
  bookedImpressions: number;
  budget: number;
  spend: number;
  cpm: number;
  daysLeft: number;
  osi: number;
  margin: number;
  dailyNeeded: number;
  deliveryRate: number;
  deliveredPct: number;
  campaignType: string;
  __v?: number;
  children?: CampaignData[];
}

export interface GetCampaignsResponse extends PaginationResponseType {
  campaigns: CampaignData[];
}

export interface GetCampaignsParams {
  search?: string;
  limit?: number;
  page?: number;
  entityStatus: CampaignStatusEnum[];
  integrationPlatform: IntegrationPlatformEnum[];
  businessUnitId: number[];
  sort?: string;
  campaignType?: CampaignTypeEnum[];
  isDedicatedPage?: boolean;
}

export interface LineItem {
  lineItemName: string;
  lineItemId: number;
  startDate: string;
  endDate: string;
  bookedImpressions: number;
  impressions: number;
  clicks: number;
  ctr: number;
  cpm: number;
  budget: number;
  spend: number;
  daysLeft: number;
  margin: number;
  osi: number;
  currency: string;
  integrationPlatform: string;
  integrationPlatformEntityUrl?: string;
  businessUnitId?: number;
  advertiserId?: number;
  entityStatus?: string;
  userId?: number;
  insertionOrderId?: number;
  creativeIds?: number[];
}

export interface GetCampaignWiseLineItemsParams {
  campaignId: string;
  integrationPlatform: string;
  advertiserId: string;
  campaignType: string;
}

interface BusinessUnit {
  id: number;
  name: string;
  external: boolean;
  owned_publishers: boolean;
}

export interface GetBusinessUnitsResponse {
  business_units: BusinessUnit[];
}

export interface CreateCampaignResponse {
  success: string;
  statusCode: number;
}

export interface UpdateDraftCampaignPayload {
  id: string;
  name: string;
  data: CampaignModel;
}

export interface CampaignLoadings {
  isCampaignsLoading: boolean;
}

export interface TableScrollType {
  x?: any,
  y?: any,
}

export interface CampaignTableProps {
  isCampaignsLoading: boolean;
  totalCampaignData: number;
  campaignData: CampaignData[];
  allWatchedCampaigns?: CampaignData[];
  scroll: TableScrollType;
  setCampaignData: (data: SetStateAction<CampaignData[]>) => void;
  setRefetchedAllWatchedCampaigns: (
    data: SetStateAction<CampaignData[]>
  ) => void;
  getCampaignsApiParams: GetCampaignsParams;
  setGetCampaignsApiParams: (data: SetStateAction<GetCampaignsParams>) => void;
}

export interface CampaignTableFilterProps {
  setGetCampaignsApiParams: (data: SetStateAction<GetCampaignsParams>) => void;
  isDedicatedPage?: boolean;
}

export interface DropdownValuesType {
  platformValues: IntegrationPlatformEnum[];
  businessUnitValues: number[];
  statusValue: CampaignStatusEnum[];
  dayValue: CampaignDayViewEnum;
  campaignTypeValues: CampaignTypeEnum[];
}

export interface CampaignModel {
  integrationPlatform: string;
  campaignName: string;
  currencyCode: string;
  campaignType: string;
  reference: string;
  buyingEntityId: number | null;
  buyingEntityOfficeId: number | null;
  lineItems: LineItemModel[];
  creativesItems: CreativeItem[];
  createdLineItems: LineItemModel[];
}

export interface CreateCampaignPayload
  extends Omit<
    CampaignModel,
    'lineItems' | 'creativesItems' | 'createdLineItems'
  > {
  lineItems: CreateCampaignLineItem[];
}

export interface LineItemModel {
  id?: number;
  integrationPlatform: string;
  lineItemName: string;
  startDate: string;
  endDate: string;
  pacing: string;
  budget: number;
  cpm: number;
  margin: number | null;
  creatives: number[];
  targeting: TargetingDataModel;
  createdByUserId: number | null;
}

export interface CreateCampaignLineItem
  extends Omit<
    LineItemModel,
    'id' | 'creatives' | 'createdByUserId' | 'targeting'
  > {
  targeting?: CreateCampaignTargetingModel;
  creatives: CreateCampaignCreativeItem[];
}

export interface IABCategory {
  description: string;
  name: string;
  parentCategory: boolean;
  parentIabCategory: number;
}

/**
 * TODO: May require to modify respect to CreateCampaignCreativeItem
 */
export interface CreativeItem {
  id: number;
  creativeName: string;
  creativeType: string;
  thirdPartyTag: string;
  iabCategoryDescription: string;
  advertiserDomain: string;
  displaySize: string;
}

/**
 * TODO: Will be update into later vertion
 */
export interface CreateCampaignCreativeItem
  extends Omit<CreativeItem, 'id' | 'displaySize'> {
  integrationPlatform?: string;
  entityStatus?: string; //TODO: for V4 or later
  creativeMediaType: string;
  iabCategory: string;
  dimension: {
    width: number;
    height: number;
  };
  tag?: string; //TODO: for V4 or later
  version?: string[]; //TODO: for V4 or later
  duration?: number; //TODO: for V4 or later
  vast?: any; //TODO: for V4 or later
  lineItemIds?: number[]; //TODO: for V4 or later
  platformType?: string; //TODO: for V4 or later
}

export interface TableCreativeItem extends CreativeItem {
  isAssigned: boolean;
}

export interface DraftCampaignPayload {
  name: string;
  data: CampaignModel;
  createdByUserId: number | null;
}

export interface DraftCampaignResponse {
  _id: string;
  name: string;
  data: CampaignModel;
  createdByUserId: number;
  createdAt: number;
  updatedAt: number;
  status: string;
  __v: number;
}

export enum CampaignStatusEnum {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  PAUSE = 'PAUSE_INACTIVE',
  FINISHED = 'FINISHED',
}

export enum MetaCampaignStatusEnum {
  MISSING = 'MISSING',
  COMPLETED = 'COMPLETED',
  ALL = 'ALL',
}

export enum IntegrationPlatformEnum {
  MARKETPLACE = 'MARKETPLACE',
  XANDR = 'XANDR',
  DV360 = 'DV360',
}

export enum CampaignTypeEnum {
  DEAL_ID = 'Deal ID',
  CAMPAIGN = 'Campaign',
}

export interface LineItemsPreviewProps {
  draftCampaign: DraftCampaignResponse;
  onAnotherLineItem: () => void;
  onNext: (updateDraftCampaignPayload: UpdateDraftCampaignPayload) => void;
  setIsRefetch: (data: SetStateAction<boolean>) => void;
  handleCancelOnConfirm: () => void;
  setCurrentStep: (data: SetStateAction<number>) => void;
}

export interface LineItemProps {
  data: LineItemModel;
  isCreated: boolean;
  selectedLineItemsId: (number | undefined)[];
  setSelectedLineItems: (data: SetStateAction<LineItemModel[]>) => void;
  draftCampaign: DraftCampaignResponse;
  setIsRefetch: (data: SetStateAction<boolean>) => void;
  showMessage: (type: MessageType, content: string) => void;
  setCurrentStep: (data: SetStateAction<number>) => void;
}

export interface LineItemMainBodyProps {
  isCollapsed: boolean;
  isCreated: boolean;
  isEditTitle: boolean;
  titleRef: RefObject<HTMLDivElement>;
  data: LineItemModel;
  draftCampaign: DraftCampaignResponse;
  handleLineItemTitleEdit: () => void;
  handleCopy: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
  handleEditCreative: () => void;
}

export enum CampaignDayViewEnum {
  D7 = '7d',
  D15 = '15d',
  M1 = '1m',
  M3 = '3m',
  M6 = '6m',
  Y1 = '1y',
}
