import { FrownOutlined } from '@ant-design/icons';
import './not-found.css';

const NotFound = () => {
  return (
    <div className="notfoundClass">
      <FrownOutlined className="notfound-icon" />
      <div className="notfound-message">
        <h5>Oops! The page you are looking for doesn't exist.</h5>
      </div>
    </div>
  );
};

export default NotFound;
