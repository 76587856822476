import { useEffect, useState } from 'react';
import { Row, Col, Form, Collapse } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import {
  PanelItemProps,
  PanelHeaderProps,
  TargetingDataModel,
  TargetingPanelsProps
} from '@models/targeting-model';

import { CheckCircleOutlineIcon } from '@icons';
import { TARGETING_TYPE } from '@constants/global-constants';
import { getTargetingOptions } from '@helpers/targeting-helpers';

const { Panel } = Collapse;

const TargetingPanels = ({ isClicked, targetingType, setTargetingType }: TargetingPanelsProps) => {
  const [targetingOptions, setTargetingOptions] = useState<TargetingDataModel | null>();

  useEffect(() => {
    setTimeout(() => {
      setTargetingOptions(getTargetingOptions());
    }, 200);
  }, [isClicked]);

  const handlePanelChange = (panelType: string | string[]) => {
    if (typeof panelType[0] === 'string') setTargetingType(panelType[0]);
    setTargetingOptions(getTargetingOptions());
  };

  const PanelHeader = ({ text, checked }: PanelHeaderProps) => {
    return (
      <div className='v-center'>
        <CheckCircleOutlineIcon color={checked ? '#03F0A9' : 'transparent'} className='mr-2' />
        {text}
      </div>
    );
  };

  const PanelItem = ({ title, type }: PanelItemProps) => {
    return (
      <div
        onClick={() => setTargetingType(type)}
        className={`sub-item ${targetingType === type && 'sub-item-active'}`}
      >
        {title}
      </div>
    );
  };

  return (
    <Col className='gutter-row targeting-panel' span={11} style={{ paddingRight: '5rem' }}>
      <Row gutter={16}>
        <Col span={24} className='gutter-row'>
          <Form.Item>
            <Collapse
              accordion={true}
              expandIconPosition='end'
              onChange={(v) => v ? handlePanelChange(v) : null}
              expandIcon={({ isActive }) => (
                <PlusCircleOutlined className={`collapse-icon ${isActive && 'rotate-135'}`} />
              )}
            >
              <Panel
                id='geo'
                key='geo'
                header={<PanelHeader text='Geo' checked={!!targetingOptions?.geoTargeting} />}
              />

              <Panel
                key='environment'
                header={(
                  <PanelHeader
                    text='Environment'
                    checked={!!targetingOptions?.platformTypes || !!targetingOptions?.deviceTypes}
                  />
                )}
              >
                <PanelItem title='Platform' type={TARGETING_TYPE.PLATFORM} />
                <PanelItem title='Device' type={TARGETING_TYPE.DEVICE} />
              </Panel>

              <Panel
                key='context'
                header={(
                  <PanelHeader
                    text='Context'
                    checked={!!targetingOptions?.domains || !!targetingOptions?.bundleIds}
                  />
                )}
              >
                <PanelItem title='Domain' type={TARGETING_TYPE.DOMAIN} />
                <PanelItem title='Bundle' type={TARGETING_TYPE.BUNDLE} />
              </Panel>

              <Panel
                id='brandsafety'
                key='brandsafety'
                header={<PanelHeader text='Brandsafety' checked={!!targetingOptions?.brandSafety} />}
              />
            </Collapse>
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
};

export default TargetingPanels;