import { SetStateAction, ReactNode } from 'react';

import {
  IABCategory,
  CreativeItem,
  DraftCampaignResponse,
  CreateCampaignCreativeItem,
  TableScrollType,
} from '@models/campaign-models';
import { CampaignData, LineItem } from '@models/campaign-models';
import { CreateCampaignTargetingModel, TargetingDataModel } from '@models/targeting-model';
import { getCampaignWiseLineItems, getDraftCampaignById } from '@services/campaign-services';

import {
  DEALS_BREADCRUMB_ITEMS,
  CAMPAIGN_BREADCRUMB_ITEMS,
} from '@constants/breadcrumb-items-data';
import { SESSION_STORAGE_KEYS } from '@constants/storage-constants';
import { CAMPAIGN_MODES, CAMPAIGN_TYPE } from '@constants/global-constants';
import { CREATIVE_MEDIA_TYPES, CREATIVE_TYPES, INTEGRATION_PLATFORM_TYPES } from '@constants/campaign-data';
import { LinkIcon } from '@icons';

export const processCampaignsResponse = (
  data: CampaignData[]
): CampaignData[] => {
  return data?.map((eachItem: CampaignData) => {
    return {
      ...eachItem,
      key: eachItem._id,
      children: [],
    };
  });
};

const processLineItemsResponse = (data: LineItem[]): CampaignData[] => {
  return data.map((eachItem: LineItem) => {
    return {
      key: eachItem.lineItemId.toString(),
      _id: eachItem.lineItemId.toString(),
      advertiserId: eachItem.advertiserId?.toString() ?? '',
      advertiserName: '',
      businessUnitId: '',
      businessUnitName: '',
      campaignId: eachItem.lineItemId,
      campaignName: eachItem.lineItemName,
      entityStatus: eachItem.entityStatus ?? '',
      currency: eachItem.currency,
      integrationPlatform: eachItem.integrationPlatform,
      reference: '',
      integrationPlatformEntityUrl: eachItem.integrationPlatformEntityUrl ?? '',
      startDate: eachItem.startDate,
      endDate: eachItem.endDate,
      impressions: eachItem.impressions,
      clicks: eachItem.clicks,
      ctr: eachItem.ctr,
      bookedImpressions: eachItem.bookedImpressions,
      budget: eachItem.budget,
      spend: eachItem.spend,
      cpm: eachItem.cpm,
      daysLeft: eachItem.daysLeft,
      osi: eachItem.osi,
      margin: eachItem.margin,
      dailyNeeded: 0,
      deliveryRate: 0,
      deliveredPct: 0,
      campaignType: '',
      __v: 0,
    };
  });
};

export const addLineItemsWithCampaign = (
  status: boolean,
  record: CampaignData,
  setViewLoaderOf: (key: string) => void,
  setCampaignData: (campaignData: SetStateAction<CampaignData[]>) => void
): void => {
  if (status) {
    setViewLoaderOf(record.key ?? '');

    getCampaignWiseLineItems({
      campaignId: record.campaignId.toString(),
      integrationPlatform: record.integrationPlatform,
      advertiserId: record.advertiserId,
      campaignType: record.campaignType,
    })
      .then((resp) => {
        setCampaignData((prev) => {
          return prev.map((item) => {
            if (item.campaignId !== record.campaignId) return item;
            return {
              ...item,
              children: [...processLineItemsResponse(resp)],
            };
          });
        });
      })
      .finally(() => setViewLoaderOf(''));
  } else {
    record.children = [];
  }
};

export const getDraftId = (): string | null => {
  return JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.DRAFT_ID) ?? 'null'
  );
};

export const getCampaignMode = () => {
  return JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.CREATE_CAMPAIGN_MODE) ?? 'null'
  );
};

export const setCampaignMode = (id: number = 0, mode: string) => {
  sessionStorage.setItem(
    SESSION_STORAGE_KEYS.CREATE_CAMPAIGN_MODE,
    JSON.stringify({ id, mode })
  );
};

export const hasLineItems = (
  draftCampaign: DraftCampaignResponse,
  id?: number
) => {
  if (id !== undefined) return !!draftCampaign?.data?.lineItems[id];
  return !!draftCampaign?.data?.lineItems;
};

export const getSessionCurrentStep = (): number => {
  return JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.CURRENT_STEP) ?? '0'
  );
};

export const setSessionCurrentStep = (step: number) => {
  sessionStorage.setItem(
    SESSION_STORAGE_KEYS.CURRENT_STEP,
    JSON.stringify(step)
  );
};

export const getCampaignType = (): string => {
  return JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.CURRENT_CAMPAIGN_TYPE) ?? 'null'
  );
};

export const setCampaignType = (type: string | null) => {
  sessionStorage.setItem(
    SESSION_STORAGE_KEYS.CURRENT_CAMPAIGN_TYPE,
    JSON.stringify(type)
  );
};

export const processTargetings = (
  targeting: TargetingDataModel,
  integrationPlatform: INTEGRATION_PLATFORM_TYPES,
): CreateCampaignTargetingModel => {
  const { domains, deviceTypes, ...rest } = targeting;
  let finalTargeting: any = { ...rest, integrationPlatform };

  if (domains?.length) {
    finalTargeting.domains = domains.map(domain => ({
      name: domain,
      includeSubdomains: true,
      excludeDomains: false
    }));
  }

  if (deviceTypes?.length) {
    finalTargeting.deviceTypes = deviceTypes.map(device => ({
      name: device,
      excluded: false,
    }));
  }

  return finalTargeting;
};

/**
 * ?INFO
 * @param creatives CreativeItem[]
 * @param ids number[]
 * @param integrationPlatform string
 * @returns CreateCampaignCreativeItem[]
 */
export const processCreatives = (
  creatives: CreativeItem[],
  ids: number[],
  integrationPlatform: string,
) => {
  let filteredCreatives: CreativeItem[] | CreateCampaignCreativeItem[] = creatives.filter(item => ids.includes(item.id));

  //TODO: dummy thirdPartyTag added. It may change in later version.
  // let thirdPartyTag = '<script></script>';

  /**
   *? Following commented fields are not required for creative item in V3.
   *? Those fields may added into V4 or later or if require.  
   */
  filteredCreatives = filteredCreatives.map(creative => {
    const { id = 0, displaySize = '', iabCategoryDescription, ...rest } = creative;
    let iabCategory: IABCategory = JSON.parse(iabCategoryDescription);

    const modifiedCreative: CreateCampaignCreativeItem = {
      ...rest,
      integrationPlatform,
      // entityStatus: ENTITY_STATUS.DRAFT,
      creativeMediaType: CREATIVE_MEDIA_TYPES.THIRD_PARTY_TAG,
      iabCategory: iabCategory.name,
      iabCategoryDescription: iabCategory.description,
      dimension: {
        width: displaySize ? +displaySize.split('x')[0] : 0,
        height: displaySize ? +displaySize.split('x')[1] : 0,
      },
    };

    if (creative.creativeType === CREATIVE_TYPES.VIDEO) {
      //TODO: Vast model will be changed in later version
      modifiedCreative.vast = {
        source: rest.thirdPartyTag,
        version: ['1.0'],
        duration: 60,
      };
    }

    return modifiedCreative;
  });

  return filteredCreatives;
};

export const prefillBreadcrumbClickedPage = (value: number, currentStep: number, setCurrentStep: (step: SetStateAction<number>) => void) => {
  const draftId = getDraftId();
  if (draftId) {
    getDraftCampaignById(draftId)
      .then(draftCampaign => {
        if (value < currentStep) {
          setCurrentStep(value);
          const campaignModeData = getCampaignMode();
          if (!campaignModeData) {
            setCampaignMode(draftCampaign?.data?.lineItems?.length - 1, CAMPAIGN_MODES.EDIT);
          } else {
            setCampaignMode(campaignModeData?.id, CAMPAIGN_MODES.EDIT);
          }
        }
      });
  }
};

export const getCreateCampaignBreadCrumbItems = (
  type: string,
  currentStep: number,
  setCurrentStep: (step: SetStateAction<number>) => void
): ReactNode[] => {
  if (type === CAMPAIGN_TYPE.DEAL_ID) {
    return DEALS_BREADCRUMB_ITEMS[currentStep].map(item => (
      <span key={item.value} onClick={() => prefillBreadcrumbClickedPage(item.value, currentStep, setCurrentStep)}>{item.label}</span>
    ));
  } else {
    return CAMPAIGN_BREADCRUMB_ITEMS[currentStep].map(item => (
      <span key={item.value} onClick={() => prefillBreadcrumbClickedPage(item.value, currentStep, setCurrentStep)}>{item.label}</span>
    ));
  }
};

export const isManagedCampaign = (draftCampaign: DraftCampaignResponse) => {
  return draftCampaign?.data?.campaignType === CAMPAIGN_TYPE.MANAGED_CAMPAIGN;
};

export const getCampaignActionItems = (url: string) => {
  return ([
    {
      key: '1',
      label: <a href={url} target='_blank' className='v-center gap'> <LinkIcon /> view campaign </a>
    },
  ]);
};

export const getTableScrollProperties = (
  limit: number,
  totalData: number,
  forMarketplace: boolean = false,
): TableScrollType => {
  const numOfPages = Math.floor(totalData / limit);   //? For checking pagination
  const deductHeightForMarketplace = 240;
  const deductHeightForButelka = 355;

  let finalDeductHeight = forMarketplace ? deductHeightForMarketplace : deductHeightForButelka;

  if (numOfPages > 1) finalDeductHeight += 64;    //? Showing pagination on UI

  return ({
    x: '100%',
    y: `calc( 100vh - ${finalDeductHeight}px )`,
  });
};
