import { OptionType } from '@models/global-models';
import { UserDetailsResponse } from '@models/user-models';
import { SorterResult } from 'antd/es/table/interface';
import { LOCAL_STORAGE_KEYS } from '@constants/storage-constants';
import { getBusinessUnits } from '@services/campaign-services';
import {
  metaDataAdOpsPersons,
  metaDataAdvertisers,
  metaDataAgencies,
  metaDataIntregationPlatforms,
  metaDataSalesPersons,
} from '@services/meta-campaign-services';
import { Fragment, ReactNode } from 'react';
import marketplaceLogo from '@images/marketplace.png';
import xandrLogo from '@images/xandr.png';
import dv360Logo from '@images/dv360.png';
import zemanta from '@images/zemanta.png';
import beeswax from '@images/beeswax.png';
import yahoo from '@images/yahoo.png';
import iasp from '@images/isap.png';
import kevel from '@images/kevel.png';
import { INTEGRATION_PLATFORM_TYPES } from '@constants/campaign-data';
import { Modal } from 'antd';

export const generateOptions = (
  values: any[],
  valueKey: string = 'id',
  labelKey: string = 'name',
): OptionType[] => {
  /**
   * ?INFO: Array map() method always returns
   * a new array, so no need to destructure it
   * while return.
   */
  return values.map((item) => ({
    value: item[valueKey],
    label: item[labelKey],
  }));
};

export const getUserDetails = (): UserDetailsResponse | null => {
  return JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO) ?? 'null'
  );
};

export const getSettings = () => {
  return JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS) ?? 'null'
  );
};

export const sortDirection = (sort: SorterResult<any>): string =>
  sort.order === 'descend' ? '-' : '';

export const convertToFixedPointNotation = (
  numberValue: number,
  fractionDigits = 2,
  postFix = ''
) => {
  const value = numberValue ?? null;

  if (value === -1 || value === null) {
    return 'N/A';
  }

  return typeof value.toFixed === 'function'
    ? `${value.toFixed(fractionDigits)}${postFix}`
    : 'N/A';
};

/**
 * To get business units from browser local storage that would be store on every login.
 *
 * @returns OptionType[]
 */
export const getBusinessUnitsFromStorage = async (): Promise<OptionType[]> => {
  const businessUnits = await getBusinessUnits();
  return generateOptions(businessUnits?.business_units ?? []);
};

export const getInternalBusinessUnits = async (): Promise<OptionType[]> => {
  const businessUnits = await getBusinessUnits();
  const internalBusinessUnits = businessUnits?.business_units?.filter(({ external }) => !external);
  return generateOptions(internalBusinessUnits ?? []);
};

export const getMetaDataSalesPersons = async (): Promise<OptionType[] | []> => {
  const salesPersons = await metaDataSalesPersons();
  return generateOptions(salesPersons, 'id', 'fullName');
};

export const getMetaDataAdOpsPersons = async (): Promise<OptionType[] | []> => {
  const adOopsPersons = await metaDataAdOpsPersons();
  return (
    adOopsPersons.map((options) => {
      return {
        key: options.id,
        value: options.id,
        label: `${options.firstName} ${options.lastName}`,
      };
    }) ?? []
  );
};

export const getMetaDataAdvertisers = async (): Promise<OptionType[] | []> => {
  const advertisers = await metaDataAdvertisers();
  return (
    advertisers.map((option) => {
      return {
        key: option.id,
        value: option.id,
        label: option.name,
      };
    }) ?? []
  );
};

export const getMetaDataAgencies = async (): Promise<OptionType[] | []> => {
  const agencies = await metaDataAgencies();
  return (
    agencies.map((options) => {
      return {
        value: options.id,
        label: options.name,
      };
    }) ?? []
  );
};

export const getMetaDataIntregrationPlatforms = async (): Promise<OptionType[]> => {
  const platforms = await metaDataIntregationPlatforms();

  return (
    platforms.map((options) => {
      return {
        value: options.id,
        label: getPlatformIcons(options?.alias),
      };
    }) ?? []
  );
};

export const getPlatformIcons = (platformAlias: string): ReactNode => {
  platformAlias = platformAlias.toUpperCase();
  let platformName: string = '';
  if (platformAlias === INTEGRATION_PLATFORM_TYPES.MARKETPLACE) {
    platformName = marketplaceLogo;
  } else if (platformAlias === INTEGRATION_PLATFORM_TYPES.DV360) {
    platformName = dv360Logo;
  } else if (platformAlias === INTEGRATION_PLATFORM_TYPES.XANDR) {
    platformName = xandrLogo;
  } else if (platformAlias === INTEGRATION_PLATFORM_TYPES.ISAP) {
    platformName = iasp;
  } else if (platformAlias === INTEGRATION_PLATFORM_TYPES.KEVEL) {
    platformName = kevel;
  } else if (platformAlias === INTEGRATION_PLATFORM_TYPES.BEESWAX) {
    platformName = beeswax;
  } else if (platformAlias === INTEGRATION_PLATFORM_TYPES.YAHOO) {
    platformName = yahoo;
  } else if (platformAlias === INTEGRATION_PLATFORM_TYPES.ZEMANTA) {
    platformName = zemanta;
  }

  return (
    <span className='v-center'>
      <img src={platformName} className="platform-icon-dropdown" />
      {platformAlias}
    </span>
  );
};

// Convert enum values into dropdown options.
export const enumToOptions = (options: any): OptionType[] => {
  const _campaignTypes: OptionType[] = [];

  Object.entries(options).map((option) => {
    _campaignTypes.push({
      value: option[0],
      label: `${option[1]}`,
    });
  });

  return _campaignTypes;
};

export const generatePlatform = (imgSrc: string, text: string): ReactNode => {
  return (
    <div className='v-center'>
      <img src={imgSrc} className='platform-icon-dropdown' />
      {text}
    </div>
  );
};

export const generateStatus = (text: string, color: string): ReactNode => {
  return (
    <div className='v-center'>
      <span className='dot' style={{ backgroundColor: color }} />
      {text}
    </div>
  );
};

export const showErrorModal = (title: string, content: string | string[]) => {
  Modal.error({
    title,
    content: (
      <Fragment>
        {Array.isArray(content) ? (
          <ul>
            {content.map((text, id) => (
              <li key={id}>{text}</li>
            ))}
          </ul>
        ) : (
          <p>{content}</p>
        )}
      </Fragment>
    ),
  });
};
