import { Checkbox } from 'antd';
import { useRef, useState } from 'react';
import { ArrowDownOutlined } from '@ant-design/icons';

import {
  LineItemModel,
  LineItemProps,
  UpdateDraftCampaignPayload,
} from '@models/campaign-models';
import { updateDraftCampaign } from '@services/campaign-services';

import { setCampaignMode } from '@helpers/campaign-helpers';
import { CAMPAIGN_MODES, CURRENT_STEP_NAME } from '@constants/global-constants';
import LineItemMainBody from './line-item-main-body';

const LineItem = ({
  data,
  draftCampaign,
  showMessage,
  setIsRefetch,
  selectedLineItemsId,
  setSelectedLineItems,
  setCurrentStep,
  isCreated
}: LineItemProps) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [isEditTitle, setIsEditTitle] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  /**
    *? Redirect user to the creative page
    */
  const handleEditCreative = () => {
    if (isCreated) return;
    setCurrentStep(CURRENT_STEP_NAME.CREATIVES);
    setCampaignMode(data?.id, CAMPAIGN_MODES.EDIT);
  };

  /**
   *? Line item selection handler
   */
  const handleLineItemSelect = (e: any) => {
    if (e.target.checked) {
      setSelectedLineItems(prev => ([
        ...prev,
        { ...data }
      ]));
    } else {
      setSelectedLineItems(prev => ([
        ...prev.filter(item => item.id !== data.id)
      ]));
    }
  };

  /**
   *? Line item title changing handler
   */
  const handleLineItemTitleEdit = () => {
    if (isEditTitle && (titleRef.current?.innerText !== data.lineItemName)) {
      const draftCampaignPayload: UpdateDraftCampaignPayload = {
        id: draftCampaign._id,
        name: draftCampaign.name,
        data: {
          ...draftCampaign?.data,
          lineItems: [
            ...draftCampaign?.data?.lineItems.map(({ id, ...actualData }) => {
              if (id === data.id) {
                return ({
                  ...actualData,
                  lineItemName: titleRef.current?.innerText ?? ''
                });
              } else {
                return actualData;
              }
            })],
        },
      };

      updateDraftCampaign(draftCampaignPayload)
        .then(() => {
          setIsRefetch(prev => !prev);
          showMessage('success', 'Line item title updated!');
        })
        .catch(() => showMessage('error', 'Something wrong while updating line item title!'));
    }

    setIsEditTitle(prev => !prev);
  };

  /**
   *? Line item duplacation handler
   */
  const handleCopy = () => {
    if (isCreated) return;
    showMessage('loading', 'Operation processing...');

    const { id, ...duplicateData } = data;
    const draftCampaignPayload: UpdateDraftCampaignPayload = {
      id: draftCampaign._id,
      name: draftCampaign.name,
      data: {
        ...draftCampaign?.data,
        lineItems: [
          ...draftCampaign?.data?.lineItems.map(({ id, ...actualData }) => actualData),
          {
            ...duplicateData,
            lineItemName: duplicateData.lineItemName + '_copy',
          },
        ],
      },
    };

    updateDraftCampaign(draftCampaignPayload)
      .then(() => {
        setIsRefetch(prev => !prev);
        showMessage('success', 'Line item copied!');
      })
      .catch(() => showMessage('error', 'Something wrong while copying line item!'));
  };

  /**
   *? Line item edit handler
   */
  const handleEdit = () => {
    if (isCreated) return;
    setCurrentStep(CURRENT_STEP_NAME.DATE_AND_BUDGET);
    setCampaignMode(data?.id, CAMPAIGN_MODES.EDIT);
  };

  /**
   *? Line item delete handler
   */
  const handleDelete = () => {
    if (isCreated) return;
    showMessage('loading', 'Operation processing...');

    const filteredLineItems: LineItemModel[] = [
      ...draftCampaign?.data?.lineItems?.filter(li => li.id !== data.id),
    ];

    const draftCampaignPayload: UpdateDraftCampaignPayload = {
      id: draftCampaign._id,
      name: draftCampaign.name,
      data: {
        ...draftCampaign?.data,
        lineItems: [
          ...filteredLineItems.map(({ id, ...actualData }) => actualData),
        ],
      },
    };

    updateDraftCampaign(draftCampaignPayload)
      .then(() => {
        setIsRefetch(prev => !prev);
        showMessage('success', 'Line item deleted!');
      })
      .catch(() => showMessage('error', 'Something wrong while deleting line item!'));
  };

  return (
    <div className='preview-line-item'>
      <div className='preview-line-item-main'>
        <div>
          {!isCreated &&
            <Checkbox
              onChange={handleLineItemSelect}
              checked={selectedLineItemsId.includes(data.id)}
            />}
        </div>

        <LineItemMainBody
          isCollapsed={isCollapsed}
          isEditTitle={isEditTitle}
          titleRef={titleRef}
          data={data}
          draftCampaign={draftCampaign}
          handleLineItemTitleEdit={handleLineItemTitleEdit}
          handleCopy={handleCopy}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleEditCreative={handleEditCreative}
          isCreated={isCreated}
        />
      </div>

      <div className='preview-line-item-collapse'>
        <ArrowDownOutlined
          style={{ color: '#FFFFFF' }}
          onClick={() => setIsCollapsed(!isCollapsed)}
          className={`${!isCollapsed ? 'rotate' : ''}`}
        />
      </div>
    </div >
  );
};

export default LineItem;