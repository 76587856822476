import { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface MetaProps {
  title: string,
  children: React.ReactNode,
}

const Meta: FC<MetaProps> = (props: MetaProps) => {
  const { children, title } = props;

  return (
    <HelmetProvider>
      <Helmet>
        <title> {title} | Butelka </title>
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default Meta;