import { Row, Col, message } from 'antd';
import { useSetRecoilState } from 'recoil';
import { Fragment, useEffect, useState } from 'react';

import {
  createDraftCampaign,
  getDraftCampaignById,
  updateDraftCampaign,
} from '@services/campaign-services';
import {
  DraftCampaignPayload,
  DraftCampaignResponse,
  UpdateDraftCampaignPayload,
} from '@models/campaign-models';
import { AppErrorType } from '@models/global-models';
import { breadcrumbState } from '@atoms/global-atoms';

import Creative from './creative';
import Targeting from './targeting';
import DspSelection from './dsp-selection';
import CampaignTypes from './campaign-types';
import DateAndBudget from './date-and-budget';
import LineItemsPreview from './line-items-preview';
import CreationTabs from '@components/common/creation-tabs';

import {
  CAMPAIGN_TYPE,
  CAMPAIGN_MODES,
  CURRENT_STEP_NAME,
} from '@constants/global-constants';
import {
  getDraftId,
  getCampaignMode,
  getCampaignType,
  setCampaignMode,
  setCampaignType,
  setSessionCurrentStep,
  getSessionCurrentStep,
  getCreateCampaignBreadCrumbItems,
} from '@helpers/campaign-helpers';

import { getUserDetails } from '@helpers/global-helpers';
import { INIT_DRAFT_CAMPAIGN_DATA } from '@constants/init-data';
import { SESSION_STORAGE_KEYS } from '@constants/storage-constants';
import './create-campaigns.css';

const CreateCampaigns = () => {
  const setBreadcrumbState = useSetRecoilState(breadcrumbState);

  const [isRefetch, setIsRefetch] = useState<boolean>(false);
  const [draftCampaign, setDraftCampaign] = useState<DraftCampaignResponse>(INIT_DRAFT_CAMPAIGN_DATA);
  const [currentStep, setCurrentStep] = useState<number>(getSessionCurrentStep() ?? 0);
  const [currentCampaignType, setCurrentCampaignType] = useState<string>(getCampaignType() ?? '');

  //? For triggering to get updated draft campaign data
  useEffect(() => {
    const draftId = getDraftId();

    if (draftId) {
      getDraftCampaignById(draftId).then(draftCampaign => {
        setDraftCampaign({
          ...draftCampaign,
          data: {
            ...draftCampaign.data,
            lineItems: [
              ...draftCampaign?.data?.lineItems?.map((lineItem, index) => ({
                ...lineItem,
                id: index,
              }))
            ]
          }
        });
      }).catch((err: AppErrorType) => {
        message.error(err.message);
      });
    }
  }, [isRefetch]);

  /**
   *? Update current step, campaign type and the breadcrumb on
   *? changing currentStep or currentCampaignType
   */
  useEffect(() => {
    setSessionCurrentStep(currentStep);
    setCampaignType(currentCampaignType);
    setBreadcrumbState([
      ...getCreateCampaignBreadCrumbItems(currentCampaignType, currentStep, setCurrentStep)
    ]);
  }, [currentStep, currentCampaignType]);

  //? Manage campaign handler
  const handleManageCampaign = (values: any) => {
    const userDetails = getUserDetails();

    let draftCampaignPayload: DraftCampaignPayload = {
      name: values?.campaign_name,
      data: {
        integrationPlatform: '',
        campaignName: values?.campaign_name,
        currencyCode: values?.currency,
        campaignType: values?.campaign_type,
        reference: values?.reference_number ? values?.reference_number : '',
        buyingEntityId: null,
        buyingEntityOfficeId: null,
        lineItems: [],
        creativesItems: [],
        createdLineItems: []
      },
      createdByUserId: userDetails ? userDetails.user_id : null,
    };

    const draftId = getDraftId();
    if (draftId) {
      const updateDraftCampaignPayload: UpdateDraftCampaignPayload = {
        id: draftId,
        name: draftCampaignPayload.name,
        data: {
          ...draftCampaignPayload.data,
          buyingEntityId: draftCampaign?.data?.buyingEntityId ?? null,
          buyingEntityOfficeId: draftCampaign?.data?.buyingEntityOfficeId ?? null,
        },
      };

      updateDraftCampaign(updateDraftCampaignPayload)
        .then(() => {
          if (values?.campaign_type === CAMPAIGN_TYPE.DEAL_ID) {
            setCurrentStep(CURRENT_STEP_NAME.DSP_SELECTION);
            setCurrentCampaignType(CAMPAIGN_TYPE.DEAL_ID);
          } else {
            setCurrentStep(CURRENT_STEP_NAME.DATE_AND_BUDGET);
            setCurrentCampaignType(CAMPAIGN_TYPE.MANAGED_CAMPAIGN);
          }

          setIsRefetch(!isRefetch);
        }).catch(() => message.error('Error occured while updating data!'));
    } else {
      createDraftCampaign(draftCampaignPayload)
        .then((resp) => {
          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.DRAFT_ID,
            JSON.stringify(resp?._id)
          );

          if (values?.campaign_type === CAMPAIGN_TYPE.DEAL_ID) {
            setCurrentStep(CURRENT_STEP_NAME.DSP_SELECTION);
            setCurrentCampaignType(CAMPAIGN_TYPE.DEAL_ID);
          } else {
            setCurrentStep(CURRENT_STEP_NAME.DATE_AND_BUDGET);
            setCurrentCampaignType(CAMPAIGN_TYPE.MANAGED_CAMPAIGN);
          }

          setIsRefetch(!isRefetch);
        })
        .catch(() => {
          message.error('Error occoured while creating draft campaign!');
        });
    }
  };

  //? Add another line item handler
  const handleAnotherLineItem = () => {
    if (currentCampaignType === CAMPAIGN_TYPE.DEAL_ID) setCurrentStep(CURRENT_STEP_NAME.DSP_SELECTION);
    if (currentCampaignType === CAMPAIGN_TYPE.MANAGED_CAMPAIGN) setCurrentStep(CURRENT_STEP_NAME.DATE_AND_BUDGET);

    setCurrentStep(CURRENT_STEP_NAME.DATE_AND_BUDGET);
  };

  //? Next button click handler
  const handleNext = (updateDraftCampaignPayload: UpdateDraftCampaignPayload) => {
    updateDraftCampaignPayload.data.lineItems = [
      ...updateDraftCampaignPayload.data.lineItems.map(({ id, ...actualData }) => actualData)
    ];

    updateDraftCampaign(updateDraftCampaignPayload)
      .then(() => {
        let forwardStep: number = 0;

        if (currentCampaignType === CAMPAIGN_TYPE.DEAL_ID) {
          forwardStep = currentStep + 2;
          if (forwardStep > 6) forwardStep = 6;
          setCurrentStep(forwardStep);
        } else if (currentCampaignType === CAMPAIGN_TYPE.MANAGED_CAMPAIGN) {
          forwardStep = currentStep + 1;
          if (forwardStep > 6) forwardStep = 6;
          setCurrentStep(forwardStep);
        }

        setIsRefetch(!isRefetch);
      })
      .catch(() => message.error('Error occured while updating data!'));
  };

  //? Back button click handler
  const handleBack = () => {
    let backStep: number = 0;
    if (currentCampaignType === CAMPAIGN_TYPE.DEAL_ID) {
      backStep = currentStep - 2;
      if (backStep < 0) backStep = 0;
      setCurrentStep(backStep);
    } else if (currentCampaignType === CAMPAIGN_TYPE.MANAGED_CAMPAIGN) {
      backStep = currentStep - 1;
      if (backStep === 1 || backStep === 2) backStep = 0;
      setCurrentStep(backStep);
    }

    if (backStep > 2 && backStep < 5 && !getCampaignMode()) {
      setCampaignMode(0, CAMPAIGN_MODES.EDIT);
    }
  };

  //? Skip button click handler
  const handleSkip = () => {
    let backStep = currentStep + 1;
    setCurrentStep(backStep);
  };

  //? Cancel on confirmation handler
  const handleCancelOnConfirm = () => {
    sessionStorage.clear();
    setCurrentStep(CURRENT_STEP_NAME.CREATE_CAMPAIGN);
  };

  return (
    <Fragment>
      <Row className='create-campaign-container'>
        <Col span={2}></Col>
        <Col span={18}>
          <CreationTabs
            currentCampaignType={currentCampaignType}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
          {(currentStep === CURRENT_STEP_NAME.CREATE_CAMPAIGN) && (
            <CampaignTypes
              onManageCampaign={handleManageCampaign}
              draftCampaign={draftCampaign}
            />
          )}
          {(currentStep === CURRENT_STEP_NAME.DSP_SELECTION) && (
            <DspSelection
              draftCampaign={draftCampaign}
              onNext={handleNext}
              onBack={handleBack}
              handleCancelOnConfirm={handleCancelOnConfirm}
            />
          )}
          {(currentStep === CURRENT_STEP_NAME.DATE_AND_BUDGET) && (
            <DateAndBudget
              draftCampaign={draftCampaign}
              onNext={handleNext}
              onBack={handleBack}
              handleCancelOnConfirm={handleCancelOnConfirm}
            />
          )}
          {(currentStep === CURRENT_STEP_NAME.CREATIVES) && (
            <Creative
              draftCampaign={draftCampaign}
              onBack={handleBack}
              onSkip={handleSkip}
              setIsRefetch={setIsRefetch}
              handleCancelOnConfirm={handleCancelOnConfirm}
            />
          )}
          {(currentStep === CURRENT_STEP_NAME.TARGETING) && (
            <Targeting
              draftCampaign={draftCampaign}
              onNext={handleNext}
              onBack={handleBack}
              onSkip={handleSkip}
              handleCancelOnConfirm={handleCancelOnConfirm}
            />
          )}
          {(currentStep === CURRENT_STEP_NAME.ADD_LINE_ITEM) && (
            <LineItemsPreview
              onAnotherLineItem={handleAnotherLineItem}
              draftCampaign={draftCampaign}
              onNext={handleNext}
              setIsRefetch={setIsRefetch}
              handleCancelOnConfirm={handleCancelOnConfirm}
              setCurrentStep={setCurrentStep}
            />
          )}
        </Col>
        <Col span={4}></Col>
      </Row>
    </Fragment >
  );
};

export default CreateCampaigns;