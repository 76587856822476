import { Fragment, useEffect, useState } from 'react';
import { Col, Radio, RadioChangeEvent, Row } from 'antd';

import { PlatformTargetingType, TargetingDataModel } from '@models/targeting-model';
import { getTargetingOptions, setTargetingOptions } from '@helpers/targeting-helpers';
import './platform-targeting.css';

const PlatformTargeting = () => {
  const [selectedPlatform, setSelectedPlatform] = useState<string>('');

  // Prefill platform 
  useEffect(() => {
    const existingTargets = getTargetingOptions();
    if (existingTargets?.platformTypes) {
      if (existingTargets.platformTypes.length > 1) {
        setSelectedPlatform('ALL');
      } else {
        setSelectedPlatform(existingTargets.platformTypes[0]);
      }
    }
  }, []);

  const handlePlatformChange = (e: RadioChangeEvent) => {
    let platformTypes: PlatformTargetingType[] = [];

    if (e.target.value === 'ALL') {
      platformTypes = [
        PlatformTargetingType.WEB,
        PlatformTargetingType.MOBILE,
      ];
    } else if (e.target.value === PlatformTargetingType.WEB) {
      platformTypes = [PlatformTargetingType.WEB];
    } else {
      platformTypes = [PlatformTargetingType.MOBILE];
    }

    const existingTargets: TargetingDataModel = getTargetingOptions();
    setTargetingOptions({ ...existingTargets, platformTypes });
    setSelectedPlatform(e.target.value);
  };

  return (
    <Fragment>
      <h4 style={{ paddingBottom: '26px' }}> Select Platform </h4>
      <div className='custom-radio-warapper'>
        <Radio.Group size='large' onChange={handlePlatformChange} value={selectedPlatform}>
          <Row gutter={14}>
            <Col span={9}>
              <Radio value={PlatformTargetingType.WEB}> Web </Radio>
            </Col>
            <Col span={9}>
              <Radio value={PlatformTargetingType.MOBILE}> In-App </Radio>
            </Col>
            <Col span={6}>
              <Radio value='ALL'> All </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </div>
    </Fragment>
  );
};

export default PlatformTargeting;
