import { useState, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import CampaignTable from '../../components/campaign-table';
import CampaignTableFilter from '../../components/campaign-filters';

import { allWatchedCampaignsState, breadcrumbState } from '@atoms/global-atoms';
import { getCampaigns } from '@services/campaign-services';
import { getAllWatchedCampaigns } from '@services/watched-campaign.service';

import { getTableScrollProperties, processCampaignsResponse } from '@helpers/campaign-helpers';
import {
  CampaignData,
  GetCampaignsParams,
  MyCampaignsProps,
} from '@models/campaign-models';
import { PAGE_TITLE } from '@constants/global-constants';
import { INIT_CAMPAIGNS_PARAMS } from '@constants/campaign-data';

const MyCampaigns = ({ isDedicatedPage }: MyCampaignsProps) => {
  const setBreadcrumbState = useSetRecoilState(breadcrumbState);

  const [isCampaignsLoading, setIsCampaignsLoading] = useState<boolean>(true);
  const [campaignData, setCampaignData] = useState<CampaignData[]>([]);
  const [totalCampaignData, setTotalCampaignData] = useState<number>(0);
  const [getCampaignsApiParams, setGetCampaignsApiParams] =
    useState<GetCampaignsParams>({ ...INIT_CAMPAIGNS_PARAMS, isDedicatedPage });

  const [allWatchedCampaigns, setAllWatchedCampaigns] = useState<CampaignData[]>([]);
  const [refetchedAllWatchedCampaigns, setRefetchedAllWatchedCampaigns] = useRecoilState(allWatchedCampaignsState);

  // Fetching Campaigns Data While First Load or Any Param Changed
  useEffect(() => {
    setIsCampaignsLoading(true);

    getCampaigns(getCampaignsApiParams)
      .then((resp) => {
        setTotalCampaignData(resp.total ?? 0);
        setCampaignData([...processCampaignsResponse(resp.campaigns)]);
      })
      .finally(() => setIsCampaignsLoading(false));
  }, [getCampaignsApiParams]);

  useEffect(() => {
    getAllWatchedCampaigns().then((allWatchedCampaigns) => {
      setAllWatchedCampaigns(allWatchedCampaigns.campaigns);
    });
  }, [refetchedAllWatchedCampaigns]);

  // Set breadcrumb state
  useEffect(() => {
    setBreadcrumbState([PAGE_TITLE.MY_CAMPAIGN]);
  });

  return (
    <div>
      <h1 className='page-heading'> {PAGE_TITLE.MY_CAMPAIGN} </h1>

      <CampaignTableFilter
        setGetCampaignsApiParams={setGetCampaignsApiParams}
        isDedicatedPage={isDedicatedPage}
      />

      <CampaignTable
        isCampaignsLoading={isCampaignsLoading}
        totalCampaignData={totalCampaignData}
        campaignData={campaignData}
        setCampaignData={setCampaignData}
        getCampaignsApiParams={getCampaignsApiParams}
        setGetCampaignsApiParams={setGetCampaignsApiParams}
        allWatchedCampaigns={allWatchedCampaigns}
        setRefetchedAllWatchedCampaigns={setRefetchedAllWatchedCampaigns}
        scroll={getTableScrollProperties(getCampaignsApiParams?.limit ?? 0, totalCampaignData, isDedicatedPage)}
      />
    </div>
  );
};

export default MyCampaigns;
