import { Select } from 'antd';
import { Fragment, useEffect, useState } from 'react';

import { GlobalSettings } from '@models/settings-models';
import { TargetingDataModel } from '@models/targeting-model';

import { getSettings } from '@helpers/global-helpers';
import { getTargetingOptions, setTargetingOptions } from '@helpers/targeting-helpers';
import './geo-targeting.css';

const GeoTargeting = () => {
  const { Option } = Select;
  const [selectedGeoTargeting, setSelectedGeoTargeting] = useState<string | null>(null);

  // Prefill GeoTargeting
  useEffect(() => {
    const existingTargets = getTargetingOptions();
    if (existingTargets?.geoTargeting) {
      setSelectedGeoTargeting(JSON.stringify(existingTargets.geoTargeting));
    } else {
      setSelectedGeoTargeting(null);
    }
  }, []);

  const global_settings: GlobalSettings = getSettings();

  const handleCountrySelect = (geoTargetingValue: string) => {
    let { geoTargeting, ...rest }: TargetingDataModel = getTargetingOptions();

    if (geoTargetingValue) {
      setSelectedGeoTargeting(geoTargetingValue);
      setTargetingOptions({
        ...rest,
        geoTargeting: JSON.parse(geoTargetingValue)
      });
    } else {
      setSelectedGeoTargeting(null);
      setTargetingOptions({ ...rest });
    }
  };

  return (
    <Fragment>
      <h4 style={{ paddingBottom: '40px' }}> Enter Geo Information </h4>
      <p style={{ marginBottom: '10px', color: '#374254' }}> Country </p>

      <Select
        showSearch
        allowClear
        filterOption={true}
        value={selectedGeoTargeting}
        onChange={handleCountrySelect}
        placeholder='Select Country'
      >
        {global_settings?.country?.map(value => (
          <Option key={value.country} value={JSON.stringify(value)}>
            {value.region + '/' + value.country}
          </Option>
        ))}
      </Select>
    </Fragment>
  );
};

export default GeoTargeting;
