import { Select, Switch } from 'antd';
import { Fragment, useState } from 'react';

import { GlobalSettings } from '@models/settings-models';
import { TargetingDataModel } from '@models/targeting-model';

import { getSettings } from '@helpers/global-helpers';
import { getTargetingOptions, setTargetingOptions } from '@helpers/targeting-helpers';
import './brand-safety.css';

const BrandSafety = () => {
  const { Option } = Select;
  const globalSettings: GlobalSettings = getSettings();
  const [isGvSafeChecked, setIsGvSafeChecked] = useState<boolean>(false);
  const [brandSafetyValues, setBrandSafetyValues] = useState<string[]>([]);

  const changeBrandSafety = (allSafe: boolean, segments: string[]) => {
    let { brandSafety, ...rest }: TargetingDataModel = getTargetingOptions();

    if (allSafe || segments.length) {
      setTargetingOptions({
        ...rest,
        brandSafety: { allSafe, segments }
      });
    } else {
      setTargetingOptions({ ...rest });
    }
  };

  const handleSwitch = (isChecked: boolean) => {
    setIsGvSafeChecked(isChecked);
    changeBrandSafety(isChecked, brandSafetyValues);
  };

  const handleBrandSafetySelect = (brandSafetyTargetingValues: string[]) => {
    setBrandSafetyValues(brandSafetyTargetingValues);
    changeBrandSafety(isGvSafeChecked, brandSafetyTargetingValues);
  };

  return (
    <Fragment>
      <h4> Brand Safety </h4>
      <div style={{ color: 'gray', margin: '10px 0 20px' }}>
        Add only inventory that is considered safe
      </div>

      <div>
        <Switch style={{ marginRight: '10px' }} onChange={handleSwitch} />
        Include all GV_Safe
      </div>

      <p style={{ margin: '40px 0 30px' }}> OR exclude standard segments </p>

      <Select
        showSearch
        allowClear
        mode='multiple'
        maxTagCount='responsive'
        placeholder='Select Segments'
        filterOption={true}
        disabled={isGvSafeChecked}
        onChange={handleBrandSafetySelect}
        dropdownStyle={{ maxHeight: '140px' }}
      >
        {globalSettings?.segments?.map(value => {
          return <Option key={value.id} value={value.display_name}>{value.display_name}</Option>;
        })}
      </Select>
    </Fragment>
  );
};

export default BrandSafety;