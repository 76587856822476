import { Switch, Table, Checkbox } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EditOutlined } from '@ant-design/icons';

import { TableCreativeItem } from '@models/campaign-models';
import { CreativeMainTableProps } from '@models/create-campaign-models';

const CreativeMainTable = ({
  creativesData,
  handleToggleAssign,
  selectedCreativeIds,
  setSelectedCreativeIds,
  setSelectedCreative,
}: CreativeMainTableProps) => {
  const handleSelection = (e: any, record: TableCreativeItem) => {
    if (e.target.checked) {
      setSelectedCreativeIds(prev => [...prev, record.id]);
    } else {
      setSelectedCreativeIds(prev => prev.filter(v => v !== record.id));
    }
  };

  const CreativeColumnsType: ColumnsType<TableCreativeItem> = [
    {
      width: 400,
      key: 'creativeName',
      title: 'Name',
      dataIndex: 'creativeName',
      fixed: 'left',
    },
    {
      width: 70,
      key: 'displaySize',
      title: 'Size',
      dataIndex: 'displaySize',
      render: (value: string) => value ? value : 'N/A',
    },
    {
      width: 110,
      key: 'creativeType',
      title: 'Type',
      dataIndex: 'creativeType',
    },
    {
      width: 60,
      align: 'center',
      key: 'isAssigned',
      title: 'Assign',
      dataIndex: 'isAssigned',
      render: (value: boolean, record) => (
        <Switch
          checked={value}
          size='small'
          onChange={() => handleToggleAssign(record.id, record.isAssigned)}
        />
      )
    },
    {
      width: 60,
      align: 'center',
      key: 'select',
      title: 'Select',
      render: (_, record) => (
        <Checkbox
          checked={selectedCreativeIds.includes(record.id)}
          onChange={(e) => handleSelection(e, record)}
        />
      )
    },
    {
      width: 50,
      align: 'center',
      key: 'action',
      fixed: 'right',
      title: ' ',
      render: (_, record) => (
        <EditOutlined
          onClick={() => setSelectedCreative(record)}
          className='creative-item-edit-icon'
        />
      )
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      size='middle'
      columns={CreativeColumnsType}
      pagination={false}
      scroll={{ x: '100%' }}
      className='creative-table-main'
      dataSource={creativesData}
    />
  );
};

export default CreativeMainTable;