import type { UploadProps } from 'antd';
import { Input, Upload, Tooltip } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { PaperClipOutlined, CloudUploadOutlined } from '@ant-design/icons';

interface BundleTargetingUploadProps {
  processBundleIds: (givenString: string) => void,
}

const BundleTargetingUpload = ({ processBundleIds }: BundleTargetingUploadProps) => {
  const [fileText, setFileText] = useState<string>('');
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);
  const [selectedFileName, setSelectedFileName] = useState<string>('');

  useEffect(() => {
    if (selectedFileName) setIsShowTooltip(true);
    else setIsShowTooltip(false);
  }, [selectedFileName]);

  const props: UploadProps = {
    name: 'file',
    accept: '.txt, .xls, .csv',
    className: 'bundle-targeting-upload',
    beforeUpload: async (file) => {
      setSelectedFileName(file.name);
      const text = await file.text();
      setFileText(text);
      return false;
    },
  };

  const handleUpload = (e: any) => {
    e.stopPropagation();
    processBundleIds(fileText);
    setFileText('');
    setSelectedFileName('');
  };

  return (
    <Fragment>
      <Upload {...props}>
        <Input
          readOnly
          className='bundle-targeting-input'
          placeholder='Choose a file to upload'
          value={selectedFileName}
          prefix={!!selectedFileName && <PaperClipOutlined style={{ color: '#248ef1' }} />}
          addonAfter={(
            <div className='bundle-targeting-common-btn'>
              <Tooltip placement='bottom' title='Click here to extract' open={isShowTooltip}>
                <CloudUploadOutlined onClick={handleUpload} />
              </Tooltip>
            </div>
          )}
        />
      </Upload>

      <div className='bundle-targeting-drag-wrapper'>
        <Upload.Dragger {...props}>
          <p className='bundle-targeting-upload-drag-icon'>
            <CloudUploadOutlined style={{ color: '#898989' }} />
          </p>
          <p style={{ color: '#898989' }}>{selectedFileName || 'Drop files here to upload'}</p>
        </Upload.Dragger>
      </div>
    </Fragment>
  );
};

export default BundleTargetingUpload;