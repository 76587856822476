import { ReactNode, useContext, useEffect, useState } from 'react';
import { Col, Form, InputNumber, message, Modal, Row } from 'antd';

import {
  MetaCampaignData,
  MetaCampaignModalProps,
  DropdownMetaEditValuesType,
} from '@models/meta-campaign-models';
import {
  updateMetaData,
  createMetaDataAdvertiser,
  createMetaDataSalesPerson,
} from '@services/meta-campaign-services';
import { AppErrorType } from '@models/global-models';
import { ActionListContext } from '@contexts/action-list-context';

import { showErrorModal } from '@helpers/global-helpers';
import { INIT_META_EDIT_DROPDOWN_VALUES } from '@constants/campaign-data';
import { modifyAsUpdateMetaDataPayload } from '@helpers/meta-campaign-helpers';

import MetaCampaignSelect from './meta-campaign-select';
import MetaCampaignDropdown from './meta-campaign-dropdown';
import MetaCampaignModalTitle from './meta-campaign-modal-title';
import CurrencyOptions from '@components/common/currency-options';
import './meta-campaign-modal.css';


const MetaCampaignModal = (props: MetaCampaignModalProps) => {
  const [form] = Form.useForm();
  const { modalData, isOpen, onCancel } = props;
  const {
    loadingStates, businessUnits, agencies, salesPersons, adOpsPersons, advertisers,
    setRefetch, getAdvertisers, getSalesPersons, updateLoadingState,
  } = useContext(ActionListContext);

  const [formSubmitLoader, setFormSubmitLoader] = useState<boolean>(false);
  const [newAdvertiserName, setNewAdvertiserName] = useState<string>('');
  const [newSalesPersonName, setNewSalesPersonName] = useState<string>('');
  const [metaDropdownValues, setMetaDropdownValues] = useState<DropdownMetaEditValuesType>({
    ...INIT_META_EDIT_DROPDOWN_VALUES,
  });

  useEffect(() => {
    form.setFieldsValue(modifyAsUpdateMetaDataPayload(modalData));
  }, [modalData]);

  const handleChange = (value: string, key: string) => {
    setMetaDropdownValues((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const handleAddNewAdvertiser = () => {
    updateLoadingState('isAdvertiserCreating', true);

    createMetaDataAdvertiser({ name: newAdvertiserName.trim() })
      .then(() => {
        message.success('Advertiser added successfully');
        setNewAdvertiserName('');
        getAdvertisers();
      })
      .catch((err: AppErrorType) => showErrorModal(err?.error?.error, err?.error?.message))
      .finally(() => updateLoadingState('isAdvertiserCreating', false));
  };

  const handleAddNewSalesPerson = () => {
    updateLoadingState('isSalesPersonCreating', true);

    createMetaDataSalesPerson({ fullName: newSalesPersonName.trim() })
      .then(() => {
        message.success('Sales person added successfully');
        setNewSalesPersonName('');
        getSalesPersons();
      })
      .catch((err: AppErrorType) => showErrorModal(err?.error?.error, err?.error?.message))
      .finally(() => updateLoadingState('isSalesPersonCreating', false));
  };

  const onSubmitMetaDataEditForm = (
    formValues: any,
    editedMetaData: MetaCampaignData
  ) => {
    setFormSubmitLoader(true);
    updateMetaData(formValues, editedMetaData?.compositeId)
      .then(() => {
        setRefetch(pState => !pState);
        handleCancel();
      })
      .catch((err: AppErrorType) => showErrorModal(err?.message, err?.error?.message))
      .finally(() => setFormSubmitLoader(false));
  };

  const handleCancel = () => {
    onCancel();
    setNewAdvertiserName('');
    setNewSalesPersonName('');
  };

  return (
    <Modal
      title={modalData && <MetaCampaignModalTitle modalData={modalData} />}
      forceRender={true}
      keyboard={false}
      open={isOpen}
      width={600}
      maskClosable={false}
      onOk={form.submit}
      onCancel={handleCancel}
      okText='Submit'
      confirmLoading={formSubmitLoader}
    >
      <Form
        form={form}
        name="create_campaign"
        className="login-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={(formValue) =>
          modalData && onSubmitMetaDataEditForm(formValue, modalData)
        }
      >
        <MetaCampaignSelect
          label='Buyer'
          name='agencyId'
          placeholder='Select from the List...'
          value={metaDropdownValues.agenciesValues}
          loading={loadingStates.isAgenciesLoading}
          options={agencies}
          handleChange={handleChange}
          fieldName='agenciesValues'
        />
        <MetaCampaignSelect
          label='Advertiser'
          name='advertiserUuid'
          placeholder='Select from the List...'
          value={metaDropdownValues.advertisersValues}
          loading={loadingStates.isAdvertisersLoading}
          options={advertisers}
          handleChange={handleChange}
          fieldName='advertisersValues'
          dropdownRender={(menu: ReactNode) => (
            <MetaCampaignDropdown
              menu={menu}
              value={newAdvertiserName}
              onChange={setNewAdvertiserName}
              placeholder="Enter New Advertiser's name..."
              onClick={handleAddNewAdvertiser}
              isLoading={loadingStates.isAdvertiserCreating}
              btnName='Add Advertiser'
            />
          )}
        />

        {/* Net revenue and Currency code options omited as
        per requirement. These options may require later */}
        {/* <Row gutter={16}>
            <Col span={18} className="gutter-row">
              <Form.Item
                label='Net Revenue'
                name='netRevenue'
                rules={[{ required: false }]}
                className="mb-4"
              >
                <InputNumber placeholder='Enter Net Revenue' className='number-input-width' />
              </Form.Item>
            </Col>
            <Col span={6} className='gutter-row'>
              <CurrencyOptions name='currencyCode' className='currency-select-without-label' />
            </Col>
          </Row> */}

        <MetaCampaignSelect
          label='Sales Person'
          name='salesPersonId'
          placeholder="Start Typing Sales Person's name..."
          value={metaDropdownValues.salesPersonsValues}
          loading={loadingStates.isSalesPersonsLoading}
          options={salesPersons}
          handleChange={handleChange}
          fieldName='salesPersonsValues'
          dropdownRender={(menu: ReactNode) => (
            <MetaCampaignDropdown
              menu={menu}
              value={newSalesPersonName}
              onChange={setNewSalesPersonName}
              placeholder="Enter New Sales Person's name..."
              onClick={handleAddNewSalesPerson}
              isLoading={loadingStates.isSalesPersonCreating}
              btnName='Add Sales Person'
            />
          )}
        />
        <MetaCampaignSelect
          label='AdOps User'
          name='adOpsPersonId'
          placeholder="Start Typing AdOperation's name..."
          value={metaDropdownValues.adOpsPersonsValues}
          loading={loadingStates.isAdOpsPersonsLoading}
          options={adOpsPersons}
          handleChange={handleChange}
          fieldName='adOpsPersonsValues'
        />
        <MetaCampaignSelect
          label='Business Unit'
          name='businessUnitId'
          placeholder='Select Business Unit'
          value={metaDropdownValues.businessUnitValues}
          loading={loadingStates.isBULoading}
          options={businessUnits}
          handleChange={handleChange}
          fieldName='businessUnitValues'
        />
      </Form>
      <div className="line-0 modal-bottom-line" />
    </Modal>
  );
};

export default MetaCampaignModal;
