import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Button, Dropdown, message, Checkbox, Empty } from 'antd';

import {
  LineItemModel,
  LineItemsPreviewProps,
  CreateCampaignPayload,
  UpdateDraftCampaignPayload,
} from '@models/campaign-models';
import { MessageType } from '@models/global-models';
import { createCampaign } from '@services/campaign-services';

import { ConfirmationModal } from '@components/common/modals';
import { SESSION_STORAGE_KEYS } from '@constants/storage-constants';
import { processCreatives, processTargetings } from '@helpers/campaign-helpers';
import { INTEGRATION_PLATFORM_TYPES, PLATFORM_OPTIONS } from '@constants/campaign-data';
import LineItem from './line-item';
import './line-items-preview.css';

const LineItemsPreview = ({
  draftCampaign,
  onAnotherLineItem,
  onNext,
  setIsRefetch,
  handleCancelOnConfirm,
  setCurrentStep,
}: LineItemsPreviewProps) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedLineItems, setSelectedLineItems] = useState<LineItemModel[]>([]);

  //? Clearing targeting data and campaign mode on first load
  useEffect(() => {
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.TARGETING_DATA);
    sessionStorage.removeItem(SESSION_STORAGE_KEYS.CREATE_CAMPAIGN_MODE);
  }, []);

  //? For updating the selected line items while a line item removed
  useEffect(() => {
    const lineItemsId = draftCampaign?.data?.lineItems?.map(li => li.id);
    setSelectedLineItems(prev => ([
      ...prev.filter(li => lineItemsId.includes(li.id))
    ]));
  }, [draftCampaign]);

  //? DSP options
  const dropdownOptions = [
    ...PLATFORM_OPTIONS.map((item, index) => ({
      key: index,
      label: <div onClick={() => handlePushToDsp(item.value)}>{item.label}</div>
    }))
  ];

  const showMessage = (type: MessageType, content: string, duration: number = 2) => {
    messageApi.open({
      type,
      content,
      duration,
      key: 'updatable',
    });
  };

  //? Select all line item handler
  const handleSelectAllLineItem = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedLineItems([
        ...draftCampaign?.data?.lineItems?.map(li => li)
      ]);
    } else {
      setSelectedLineItems([]);
    }
  };

  //? Push to DSP handler
  const handlePushToDsp = (integrationPlatform: INTEGRATION_PLATFORM_TYPES) => {
    showMessage('loading', 'Sending data to the selected DSP...', 10);
    const { creativesItems, createdLineItems, ...restDraftCampaignData } = draftCampaign.data;

    const createCampaignPayload: CreateCampaignPayload = {
      ...restDraftCampaignData,
      integrationPlatform,
      lineItems: [
        ...selectedLineItems.map(({ id, ...rest }) => {
          let tempLineItem: any = {
            ...rest,
            integrationPlatform,
            creatives: processCreatives(creativesItems, rest.creatives, integrationPlatform),
          };

          if (rest.targeting) {
            tempLineItem.targeting = processTargetings(rest.targeting, integrationPlatform);
          }

          return tempLineItem;
        })
      ],
    };

    const selectedLineItemsId = selectedLineItems.map(item => item.id);

    const updateDraftCampaignPayload: UpdateDraftCampaignPayload = {
      id: draftCampaign._id,
      name: draftCampaign.name,
      data: {
        ...draftCampaign?.data,
        lineItems: [
          ...draftCampaign?.data?.lineItems.filter(item => !selectedLineItemsId.includes(item.id))
        ],
        createdLineItems: [
          ...draftCampaign.data.createdLineItems,
          ...selectedLineItems.map(({ id, ...rest }) => ({
            ...rest,
            integrationPlatform,
          }))
        ]
      }
    };

    createCampaign(createCampaignPayload)
      .then(() => {
        showMessage('success', 'Campaign successfully pushed');
        setSelectedLineItems([]);

        onNext(updateDraftCampaignPayload);
      })
      .catch(() => showMessage('error', 'Something wrong while pushing the campaign!'));
  };

  return (
    <div className='right-to-left-fade-in'>
      {contextHolder}

      <div className='line-items-preview-heading-section'>
        <div>
          <p style={{ fontSize: '34px' }}><strong>{draftCampaign?.data?.campaignName}</strong></p>
          <p>Reference Number : {draftCampaign?.data?.reference}</p>
        </div>

        <Button onClick={onAnotherLineItem}>
          <PlusOutlined /> Add Another Line Item
        </Button>
      </div>

      <p style={{ fontSize: '24px', paddingTop: '20px' }}><strong>Drafts</strong></p>
      <div style={{ marginTop: '10px' }}>
        {draftCampaign?.data?.lineItems?.map(lineItem => (
          <LineItem
            key={lineItem.id}
            data={lineItem}
            selectedLineItemsId={[...selectedLineItems.map(li => li.id)]}
            setSelectedLineItems={setSelectedLineItems}
            draftCampaign={draftCampaign}
            setIsRefetch={setIsRefetch}
            showMessage={showMessage}
            setCurrentStep={setCurrentStep}
            isCreated={false}
          />
        ))}

        {!draftCampaign?.data?.lineItems?.length && (
          <Empty
            style={{ margin: '70px 0' }}
            description={
              <div>
                <p>No more line items left.</p>
                <p>Please add another line item or cancel to create another campaign.</p>
              </div>
            }
          />
        )}
      </div>

      <div className='line-items-preview-footer-section'>
        <Checkbox
          onChange={handleSelectAllLineItem}
          checked={!!selectedLineItems.length && (selectedLineItems.length === draftCampaign?.data?.lineItems?.length)}
          disabled={!draftCampaign?.data?.lineItems?.length}
        >
          Select All
        </Checkbox>

        <ConfirmationModal handleOk={handleCancelOnConfirm} />

        <Dropdown
          menu={{ items: dropdownOptions }}
          trigger={['click']}
          placement='topRight'
          disabled={!draftCampaign?.data?.lineItems?.length || selectedLineItems.length < 1}
        >
          <Button type='primary'>
            Push To DSP
          </Button>
        </Dropdown>
      </div>

      {draftCampaign.data.createdLineItems.length > 0 &&
        <div>
          <p style={{ fontSize: '24px', paddingTop: '20px' }}><strong>Created</strong></p>
          {draftCampaign?.data?.createdLineItems?.map(lineItem => (
            <LineItem
              key={lineItem.id}
              data={lineItem}
              selectedLineItemsId={[...selectedLineItems.map(li => li.id)]}
              setSelectedLineItems={setSelectedLineItems}
              draftCampaign={draftCampaign}
              setIsRefetch={setIsRefetch}
              showMessage={showMessage}
              setCurrentStep={setCurrentStep}
              isCreated={true}
            />
          ))}
        </div>
      }
    </div>
  );
};

export default LineItemsPreview;
