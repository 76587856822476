import axios from 'axios';
import HttpClientInstance from './base-services';

import {
  Credentials,
  LoginResponse,
  LogoutResponse,
} from '@models/auth-models';
import { getGlobalSettings } from './global-services';

import { getAccessToken, ensureTrailingSlash } from '@helpers/auth-helpers';
import { API_END_POINTS, DOMAINS, POST_MESSAGE_IFRAME } from '@constants/global-constants';
import { LOCAL_STORAGE_KEYS } from '@constants/storage-constants';
import { AppErrorType } from '@models/global-models';
import { message } from 'antd';
import { actionListIframePostmessage } from '@helpers/marketplace-action-list-helper';
import { getAccessTokenFromMarketPlace } from './marketplace-action-list-services';
import { routes } from '@constants/route-constants';

const httpClient = new HttpClientInstance();

// Login Service
export async function userLogin(
  credentials: Credentials
): Promise<LoginResponse> {
  const url = API_END_POINTS.TOKEN;

  return await httpClient
    .post(url, { data: { ...credentials } })
    .then(async (resp) => {
      //? Storing token into Loacal Storage
      localStorage.clear();

      localStorage.setItem(
        LOCAL_STORAGE_KEYS.AUTH_TOKEN,
        JSON.stringify({ access_token: resp.token ?? '' })
      );

      //? Storing global settings into localStorage after successful login
      let settings = await getGlobalSettings().catch((err: AppErrorType) =>
        message.error(err.message)
      );

      return settings ?? resp;
    })
    .catch((err) => Promise.reject(err));
}

// Logout Service
export async function userLogout(): Promise<LogoutResponse> {
  const url = API_END_POINTS.LOGOUT;

  return await httpClient.delete(url);
}


/** 
  * This retoken function has two steps
  * STEP-1:
  * If the token is coming from marketplace
  * After that it expired for marketplace action-list iframe
  * then refresh it with auth/marketplace-access api
  * 
  * STEP-2
  * If the token is not coming from marketplace 
  * then refresh as usual with butelka's auth/retoken api
  * NOTE: We are sending and recieving tokens
  * By using PostMessageMechanism
*/
export function getRetoken(): Promise<LoginResponse> {
  return new Promise((resolve, reject) => {
    let url = (window.location !== window.parent.location)
      ? document.referrer
      : document.location.origin;

    // if this is a butelka domain then retoken as usual
    if (DOMAINS.BUTELKA_DOMAINS.includes(url)) {
      axios
        .create({
          withCredentials: true,
          baseURL: ensureTrailingSlash(process.env.REACT_APP_API_BASE_URL ?? ''),
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${getAccessToken()}`,
          },
        })
        .get(API_END_POINTS.RE_TOKEN)
        .then((resp) => {
          //? Replace auth_token with
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.AUTH_TOKEN,
            JSON.stringify({ access_token: resp.data?.token ?? '' })
          );

          resolve(resp.data);
        })
        .catch((err) => {
          localStorage.clear();
          sessionStorage.clear();
          location.href = routes.login.path;
          reject(err);
        });
    }

    /**
     * send a message to marketplace to refresh his token
     * Then send me back the new access and refresh token
     * After that butelka will refresh with the new access and refresh token
     */

    if (DOMAINS.MARKETPLACE_DOMAINS.includes(url)) {
      actionListIframePostmessage(POST_MESSAGE_IFRAME.RETOKEN);
      window.addEventListener('message', (event) => {
        if (event?.data?.message?.accessToken && event?.data?.message?.refreshToken) {
          getAccessTokenFromMarketPlace(event?.data?.message).then(response => {
            localStorage.setItem(
              LOCAL_STORAGE_KEYS.AUTH_TOKEN,
              JSON.stringify({ access_token: response.data.token })
            );
            resolve(response?.data);
          }).catch(err => {
            // send a message to marketplace to clear his session
            actionListIframePostmessage(POST_MESSAGE_IFRAME.CLEAR_SESSION);
            reject(err);
          });
        }
      });
    }

  });

}
