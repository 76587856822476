import { useEffect, useState } from 'react';
import { Row, Form, Select, Col, Spin, message } from 'antd';

import {
  getAllBuyingEntities,
  getBuyingEntityOffices
} from '@services/campaign-services';
import { OptionType, AppErrorType } from '@models/global-models';
import { DspSelectionProps } from '@models/create-campaign-models';
import { DraftCampaignResponse, UpdateDraftCampaignPayload } from '@models/campaign-models';

import { FORM_TYPE } from '@constants/global-constants';
import { generateOptions } from '@helpers/global-helpers';
import { FullPageBlurLoading } from '@components/full-page-loading';
import CreateCampaignsBottomNavigation from '../create-campaigns-bottom-navigation';
import './dsp-selection.css';

const DspSelection = (props: DspSelectionProps) => {
  const { draftCampaign, onNext, onBack, handleCancelOnConfirm } = props;
  const [form] = Form.useForm();

  const [isBuyingEntityLoading, setIsBuyingEntityLoading] = useState<boolean>(true);
  const [isBuyingEntityOfficeLoading, setIsBuyingEntityOfficeLoading] = useState<boolean>(false);
  const [buyingEntityOptions, setBuyingEntityOptions] = useState<OptionType[]>([]);
  const [selectedBuyingEntity, setSelectedBuyingEntity] = useState<OptionType>();
  const [buyingEntityOfficeOptions, setBuyingEntityOfficeOptions] = useState<OptionType[]>([]);
  const [isPrefilledBEO, setIsPrefilledBEO] = useState<boolean>(false);

  // Initially Colllect all the available Buying Entity
  useEffect(() => {
    setIsBuyingEntityLoading(true);
    getAllBuyingEntities().then(buyingEntities => setBuyingEntityOptions(generateOptions(buyingEntities, 'id', 'name')))
      .catch((err: AppErrorType) => message.error(err.message))
      .finally(() => setIsBuyingEntityLoading(false));
  }, []);

  useEffect(() => {
    form.setFieldValue('dsp', isBuyingEntityLoading ? null : draftCampaign.data.buyingEntityId);
    const temp_dsp = buyingEntityOptions.find(buyingEntityOption => draftCampaign.data.buyingEntityId === buyingEntityOption.value);
    setSelectedBuyingEntity(temp_dsp);
  }, [buyingEntityOptions, draftCampaign]);

  // While A Buying Entity is selected
  useEffect(() => {
    if (selectedBuyingEntity) {
      form.resetFields(['buyers_seat']);
      setBuyingEntityOfficeOptions(() => []);
      setIsBuyingEntityOfficeLoading(true);
      getBuyingEntityOffices(selectedBuyingEntity?.label?.toString())
        .then(buyingEntityOffices => setBuyingEntityOfficeOptions(generateOptions(buyingEntityOffices, 'id', 'office')))
        .catch((err: AppErrorType) => message.error(err.message))
        .finally(() => setIsBuyingEntityOfficeLoading(false));
    }
  }, [selectedBuyingEntity]);

  useEffect(() => {
    if (draftCampaign && !isBuyingEntityOfficeLoading && !isBuyingEntityLoading) {
      if (!isPrefilledBEO) {
        form.setFieldValue('buyers_seat', draftCampaign.data.buyingEntityOfficeId);
      }
    }
  }, [buyingEntityOfficeOptions, draftCampaign, isBuyingEntityOfficeLoading]);

  const handleOnChangeBE = (id: number) => {
    setIsPrefilledBEO(true);
    const value = buyingEntityOptions.filter(option => id === (+option.value));
    setSelectedBuyingEntity(value[0]);
  };

  const handleOnFinish = (formValues: any) => {
    const cloneDraftCampaign: DraftCampaignResponse = { ...draftCampaign };
    cloneDraftCampaign.data.buyingEntityId = formValues.dsp;
    cloneDraftCampaign.data.buyingEntityOfficeId = formValues.buyers_seat;

    const updateDraftCampaignPayload: UpdateDraftCampaignPayload = {
      id: cloneDraftCampaign._id,
      name: cloneDraftCampaign.name,
      data: cloneDraftCampaign.data,
    };

    onNext(updateDraftCampaignPayload);
  };

  const handleFilterOption = (input: string, option: any) => {
    if (typeof option?.label === 'string') {
      return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
    }
    return false;
  };

  return (
    <div className='container right-to-left-fade-in'>
      {(isBuyingEntityLoading || isBuyingEntityOfficeLoading) && <FullPageBlurLoading mode='container' />}

      <Form
        form={form}
        name={FORM_TYPE.DSP_SELECTION_FORM}
        className='campaign-form'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleOnFinish}
      >
        <Row>
          <Col className='gutter-row' span={11}>
            <Row gutter={16}>
              <Col span={24} className='gutter-row'>
                <Form.Item
                  label='DSP'
                  name='dsp'
                  rules={[
                    {
                      required: true,
                      message: 'Please Select DSP',
                    },
                  ]}
                  className='mb-4'>
                  <Select
                    showSearch
                    placeholder='Please select the buyers DSP'
                    loading={isBuyingEntityLoading}
                    notFoundContent={
                      <NotFoundContent
                        isLoading={isBuyingEntityLoading}
                        hasValue={!!buyingEntityOptions.length}
                      />
                    }
                    options={buyingEntityOptions}
                    disabled={isBuyingEntityLoading}
                    onChange={handleOnChangeBE}
                    filterOption={handleFilterOption}>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={2}></Col>

          <Col className='gutter-row' span={11}>
            <Row gutter={16}>
              <Col span={24} className='gutter-row'>
                <Form.Item
                  label='Buyers Seat'
                  name='buyers_seat'
                  rules={[
                    {
                      required: true,
                      message: 'Select Buyers seat name/Id',
                    },
                  ]}
                  className='mb-4'>
                  <Select
                    placeholder={!selectedBuyingEntity ? 'Select buyers DSP first' : 'Please select the buyers seat'}
                    allowClear
                    showSearch
                    loading={isBuyingEntityOfficeLoading}
                    notFoundContent={
                      <NotFoundContent
                        isLoading={isBuyingEntityOfficeLoading}
                        hasValue={!!buyingEntityOfficeOptions.length}
                      />
                    }
                    options={buyingEntityOfficeOptions}
                    disabled={isBuyingEntityLoading || isBuyingEntityOfficeLoading || !selectedBuyingEntity}
                    filterOption={handleFilterOption}
                  >
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <CreateCampaignsBottomNavigation onBack={onBack} onCancel={handleCancelOnConfirm} />
      </Form >
    </div >
  );
};

const NotFoundContent = (props: any) => {
  const { isLoading, hasValue } = props;
  return isLoading ? <Spin size='small' /> : (hasValue ? <div>No Data Found</div> : <div></div>);
};

export default DspSelection;