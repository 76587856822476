import axios, { AxiosInstance, ResponseType } from 'axios';
import { getRetoken } from './auth-services';

import { routes } from '@constants/route-constants';
import { STATUS_CODES } from '@constants/global-constants';
import { ensureTrailingSlash, getAccessToken } from '@helpers/auth-helpers';

interface RequestConfig {
  headers?: any;
  params?: any;
  data?: any;
  timeout?: number;
  responseType?: ResponseType;
  onUploadProgress?: (progressEvent: any) => void;
}

interface Response {
  status: number;
  data: any;
}

/**
 *? Axios Instance
 */
class AxiosConfigService {
  private axiosInstance: AxiosInstance;

  constructor(
    baseUrl = process.env.REACT_APP_API_BASE_URL ?? '',
    timeout = 120000    //? 2 min
  ) {
    this.axiosInstance = axios.create({
      withCredentials: true,
      baseURL: ensureTrailingSlash(baseUrl),
      timeout,
      headers: {
        Accept: 'application/json',
      },
    });
  }

  private request(config: any = {}): Promise<Response> {
    /**
     *? Request interceptor before sending an error
     * Here we handled unauthorized request
     */
    this.axiosInstance.interceptors.response.use(undefined, (err) => {
      if (err?.response?.data?.statusCode === STATUS_CODES.UNAUTHORIZED) {
        localStorage.clear();
        sessionStorage.clear();
        if (!location.pathname.includes('login')) {
          location.href = routes.login.path;
        }
      }

      return Promise.reject(err);
    });

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .request(config)
        .then(({ status, data }) => resolve({ status, data }))
        .catch((err) =>
          reject({
            status: err.response.status,
            data: err.response.data,
          })
        );
    });
  }

  private getAuthHeader(config: RequestConfig) {
    return {
      ...config.headers,
      Authorization: `Bearer ${getAccessToken()}`,
    };
  }

  get(url: string, config: RequestConfig = {}) {
    config.headers = this.getAuthHeader(config);
    return this.request({ method: 'get', url, ...config }).catch((err) => err);
  }

  post(url: string, config: RequestConfig = {}) {
    config.headers = this.getAuthHeader(config);
    return this.request({ method: 'post', url, ...config }).catch((err) => err);
  }

  put(url: string, config: RequestConfig = {}) {
    config.headers = this.getAuthHeader(config);
    return this.request({ method: 'put', url, ...config }).catch((err) => err);
  }

  patch(url: string, config: RequestConfig = {}) {
    config.headers = this.getAuthHeader(config);
    return this.request({ method: 'patch', url, ...config }).catch(
      (err) => err
    );
  }

  delete(url: string, config: RequestConfig = {}) {
    config.headers = this.getAuthHeader(config);
    return this.request({ method: 'delete', url, ...config }).catch(
      (err) => err
    );
  }
}

/**
 *? HttpClient Instance
 */

let refreshToken: any = null;
const blockRedundantRetokenCalls = async () => {
  // blocking redundant calls on when multiple api fails with 401 at a time
  refreshToken = refreshToken ? refreshToken : getRetoken();
  await refreshToken;
  refreshToken = null;
};

class HttpClientInstance {
  private httpClient: AxiosConfigService;


  constructor() {
    this.httpClient = new AxiosConfigService();
  }

  private returnPromiseData(response: Response) {
    if (response.status < 400) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(response.data);
  }

  async get(url: string, config: RequestConfig = {}) {
    let response: Response = await this.httpClient.get(url, config);

    if (response.status === STATUS_CODES.TOKEN_EXPIRED) {
      await blockRedundantRetokenCalls();
      response = await this.httpClient.get(url, config);
    }

    return this.returnPromiseData(response);
  }

  async post(url: string, config: RequestConfig = {}) {
    let response: Response = await this.httpClient.post(url, config);

    if (response.status === STATUS_CODES.TOKEN_EXPIRED) {
      await blockRedundantRetokenCalls();
      response = await this.httpClient.post(url, config);
    }

    return this.returnPromiseData(response);
  }

  async put(url: string, config: RequestConfig = {}) {
    let response: Response = await this.httpClient.put(url, config);

    if (response.status === STATUS_CODES.TOKEN_EXPIRED) {
      await blockRedundantRetokenCalls();
      response = await this.httpClient.put(url, config);
    }

    return this.returnPromiseData(response);
  }

  async patch(url: string, config: RequestConfig = {}) {
    let response: Response = await this.httpClient.patch(url, config);

    if (response.status === STATUS_CODES.TOKEN_EXPIRED) {
      await blockRedundantRetokenCalls();
      response = await this.httpClient.patch(url, config);
    }

    return this.returnPromiseData(response);
  }

  async delete(url: string, config: RequestConfig = {}) {
    let response: Response = await this.httpClient.delete(url, config);

    if (response.status === STATUS_CODES.TOKEN_EXPIRED) {
      await blockRedundantRetokenCalls();
      response = await this.httpClient.delete(url, config);
    }

    return this.returnPromiseData(response);
  }
}

export default HttpClientInstance;
