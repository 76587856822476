import { Button, Switch } from 'antd';
import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';

import { DeleteIcon } from '@icons';
import { CreativeTableMenuProps } from '@models/create-campaign-models';

const CreativeTableMenu = ({
  isExpandTable,
  isCreativeSelected,
  setIsExpandTable,
  setIsShowAssigned,
  handleAssignToLi,
  handleDelete,
}: CreativeTableMenuProps) => {

  return (
    <div className='creative-table-menu-container'>
      <div>
        {isExpandTable ? (
          <ShrinkOutlined onClick={() => setIsExpandTable(false)} />
        ) : (
          <ArrowsAltOutlined onClick={() => setIsExpandTable(true)} />
        )}
        <strong>Creatives</strong>
        <div>
          Show assigned
          <Switch
            defaultChecked
            size='small'
            onChange={() => setIsShowAssigned(prev => !prev)}
          />
        </div>
      </div>

      <div>
        <Button disabled>Import Creatives</Button>
        <Button
          type='primary'
          onClick={handleAssignToLi}
          disabled={!isCreativeSelected}
        >
          Assign creative(s) to LI
        </Button>
        <DeleteIcon
          onClick={() => isCreativeSelected && handleDelete()}
          className={!isCreativeSelected ? 'cursor-not-allowed text-inactive' : 'cursor-pointer'}
        />
      </div>
    </div>
  );
};

export default CreativeTableMenu;