import { UpdateMetaDataPayload } from '@models/meta-campaign-models';

export const modifyAsUpdateMetaDataPayload = (data: any): UpdateMetaDataPayload => {
  const {
    agencyId, advertiserUuid, netRevenue, currencyCode,
    salesPersonId, adOpsPersonId, businessUnitId,
  } = data;

  return ({
    agencyId, advertiserUuid,
    salesPersonId, adOpsPersonId, businessUnitId,
  });
};