import { Row, Col, Form } from 'antd';
import { useEffect, useState } from 'react';

import { TargetingDataModel } from '@models/targeting-model';
import { TargetingProps } from '@models/create-campaign-models';
import { DraftCampaignResponse, UpdateDraftCampaignPayload } from '@models/campaign-models';

import BrandSafety from './brand-safety';
import GeoTargeting from './geo-targeting';
import TargetingPanels from './targeting-panels';
import BundleTargeting from './bundle-targeting';
import DeviceTargeting from './device-targeting';
import DomainTargeting from './domain-targeting';
import PlatformTargeting from './platform-targeting';
import CreateCampaignsBottomNavigation from '../create-campaigns-bottom-navigation';

import { INIT_TARGETING_DATA } from '@constants/init-data';
import { getCampaignMode } from '@helpers/campaign-helpers';
import { getTargetingOptions, setTargetingOptions } from '@helpers/targeting-helpers';
import { CAMPAIGN_MODES, FORM_TYPE, TARGETING_TYPE } from '@constants/global-constants';
import './targeting.css';

const Targeting = (props: TargetingProps) => {
  const { draftCampaign, onBack, onSkip, onNext, handleCancelOnConfirm } = props;
  const [campaignModeData, setCampaignModeData] = useState<any>();
  const [targetingType, setTargetingType] = useState<string>('');
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [form] = Form.useForm();

  /**
   *? Setting initial or existing targeting options
   */
  useEffect(() => {
    const data = getCampaignMode();
    const targeting = draftCampaign?.data?.lineItems[data?.id]?.targeting;

    !!targeting ? setTargetingOptions(targeting) : setTargetingOptions(INIT_TARGETING_DATA);

    if (data?.mode === CAMPAIGN_MODES.EDIT) {
      setCampaignModeData(data);
    }
  }, [draftCampaign]);

  /**
   *? Saving all targeting options handler
   */
  const handleOnFinish = () => {
    const cloneDraftCampaign: DraftCampaignResponse = { ...draftCampaign };

    const updateDraftCampaignPayload: UpdateDraftCampaignPayload = {
      id: cloneDraftCampaign._id,
      name: cloneDraftCampaign.name,
      data: cloneDraftCampaign.data,
    };

    let targetingsFromSession: TargetingDataModel = getTargetingOptions();

    if (campaignModeData?.mode === CAMPAIGN_MODES.EDIT) {
      updateDraftCampaignPayload.data.lineItems[campaignModeData.id].targeting = targetingsFromSession;
    } else {
      const lastIndex = updateDraftCampaignPayload.data.lineItems.length - 1;
      updateDraftCampaignPayload.data.lineItems[lastIndex].targeting = targetingsFromSession;
    }

    onNext(updateDraftCampaignPayload);
  };

  return (
    <div className='container right-to-left-fade-in'>
      <Form
        form={form}
        name={FORM_TYPE.TARGETING_FORM}
        className='campaign-form'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleOnFinish}
        onClick={() => setIsClicked(prev => !prev)}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <TargetingPanels
            isClicked={isClicked}
            targetingType={targetingType}
            setTargetingType={setTargetingType}
          />

          <Col span={1} />

          <Col className='gutter-row' span={12}>
            {targetingType === TARGETING_TYPE.GEO && <GeoTargeting />}
            {targetingType === TARGETING_TYPE.PLATFORM && <PlatformTargeting />}
            {targetingType === TARGETING_TYPE.DEVICE && <DeviceTargeting />}
            {targetingType === TARGETING_TYPE.DOMAIN && <DomainTargeting />}
            {targetingType === TARGETING_TYPE.BUNDLE && <BundleTargeting />}
            {targetingType === TARGETING_TYPE.BRANDSAFETY && <BrandSafety />}
          </Col>
        </Row>

        <CreateCampaignsBottomNavigation
          onBack={onBack}
          onSkip={onSkip}
          onCancel={handleCancelOnConfirm}
          altNextText='Add Line Item'
        />
      </Form>
    </div>
  );
};

export default Targeting;