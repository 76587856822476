import { Select } from 'antd';
import { FC, useEffect, useState } from 'react';

import { getTargetingOptions, setTargetingOptions } from '@helpers/targeting-helpers';
import { DEVICE_TARGETING_TYPES, DEVICE_TARGETING_OPTIONS } from '@constants/campaign-data';
import SelectedList from '../selected-list';
import './device-targeting.css';

const DeviceTargeting: FC = () => {
  const [isPrefilled, setIsPrefilled] = useState<boolean>(false);
  const [selectedDevices, setSelectedDevices] = useState<any[]>([]);

  /**
   *? This effect will prefill the existing Devices
   */
  useEffect(() => {
    const existingDevices = getTargetingOptions()?.deviceTypes ?? [];
    setSelectedDevices(
      DEVICE_TARGETING_OPTIONS.filter(option => existingDevices.includes(option.value))
    );
    setIsPrefilled(true);
  }, []);

  /**
   *? This effect will save Devices into session storage on changing Devices
   */
  useEffect(() => {
    if (isPrefilled) {
      setTargetingOptions({
        ...getTargetingOptions(),
        deviceTypes: selectedDevices.map(option => option.value),
      });
    }
  }, [selectedDevices, isPrefilled]);

  const handleDeviceChange = (values: DEVICE_TARGETING_TYPES[]) => {
    setSelectedDevices(
      DEVICE_TARGETING_OPTIONS.filter(option => values.includes(option.value))
    );
  };

  return (
    <div>
      <h4> Device Type Targeting </h4>
      <p style={{ margin: '15px 0 10px 0' }}> Type to search for device types matches </p>

      <Select
        showArrow
        showSearch
        allowClear
        mode='multiple'
        maxTagCount='responsive'
        className='device-dropdown'
        placeholder='Select Device Types'
        style={{ width: '100%', marginBottom: '24px' }}
        onChange={handleDeviceChange}
        options={DEVICE_TARGETING_OPTIONS}
        value={selectedDevices.map(option => option.value)}
      />

      <SelectedList
        title='Device Type'
        list={selectedDevices}
        setList={setSelectedDevices}
        emptyListMsg='No Device Added'
      />
    </div>
  );
};

export default DeviceTargeting;