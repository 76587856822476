import { Fragment } from 'react';

const IframeBlockerLayout = ({ children }: any) => {
  if (window === window.top) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }

  return (
    <div className='center h-screen'>
      Can't accessible from another host
    </div>
  );
};

export default IframeBlockerLayout;
