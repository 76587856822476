import { atom } from 'recoil';
import { ReactNode } from 'react';
import { CampaignData } from '@models/campaign-models';

export const breadcrumbState = atom<ReactNode[]>({
  key: 'breadcrumbState',
  default: [],
});

export const allWatchedCampaignsState = atom<CampaignData[]>({
  key: 'allWatchedCampaignsState',
  default: [],
});
