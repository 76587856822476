import dayjs from 'dayjs';
import { toPlainObject } from 'lodash';
import { Table, Spin, Pagination, Dropdown } from 'antd';
import { FC, Fragment, useState, useEffect } from 'react';
import { DownCircleOutlined } from '@ant-design/icons';
import { StarOutlined, MoreOutlined } from '@ant-design/icons';
import type { ColumnsType, TableProps } from 'antd/es/table';

import {
  CampaignData,
  CampaignTypeEnum,
  CampaignTableProps,
} from '@models/campaign-models';
import {
  LOGOS,
  THEME_COLORS,
  INIT_PAGE_ITEMS_LIMIT,
} from '@constants/global-constants';

import {
  getCampaignActionItems,
  addLineItemsWithCampaign,
} from '@helpers/campaign-helpers';
import {
  sortDirection,
  convertToFixedPointNotation,
} from '@helpers/global-helpers';

import { CampaignWatched, numberWithCommas } from './columns';
import OsiBar from './osi-bar';
import './styles.css';

const CampaignTable: FC<CampaignTableProps> = ({
  isCampaignsLoading,
  totalCampaignData,
  campaignData,
  allWatchedCampaigns,
  scroll,
  setCampaignData,
  getCampaignsApiParams,
  setGetCampaignsApiParams,
  setRefetchedAllWatchedCampaigns,
}: CampaignTableProps) => {
  const [viewLoaderOf, setViewLoaderOf] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);

  //? For sync the pagination with params pagination
  useEffect(() => {
    setCurrentPage(getCampaignsApiParams.page ?? 1);
  }, [getCampaignsApiParams]);

  const pageChangeHandler = (page: number, limit: number) => {
    setGetCampaignsApiParams((prev) => ({ ...prev, page, limit }));
  };
  const onTableChange: TableProps<CampaignData>['onChange'] = (
    _,
    __,
    sort: any
  ) => {
    const column = toPlainObject(sort)?.column;
    const sortParam = column?.sorter
      ? `${sortDirection(sort)}${column?.dataIndex}`
      : '';

    setGetCampaignsApiParams((state) => ({ ...state, sort: sortParam }));
  };

  const getCampaignType = (campaignTypeKey: string): string => {
    let campaignType = 'N/A';

    if (Object.keys(CampaignTypeEnum).includes(campaignTypeKey)) {
      campaignType = Object.entries(CampaignTypeEnum).filter(
        (campaignType) => campaignType[0] === campaignTypeKey
      )[0][1];
    }

    return campaignType;
  };

  const CampaignColumns: ColumnsType<CampaignData> = [
    {
      width: 40,
      title: <StarOutlined />,
      fixed: 'left',
      render: (_, record) => {
        if (!record?.children) return <></>;
        return (
          <CampaignWatched
            campaignId={record.campaignId.toString()}
            integrationPlatform={record.integrationPlatform}
            allWatchedCampaigns={allWatchedCampaigns}
            setRefetchedAllWatchedCampaigns={setRefetchedAllWatchedCampaigns}
          />
        );
      },
    },
    {
      width: 400,
      key: 'campaignName',
      title: 'Name',
      dataIndex: 'campaignName',
      fixed: 'left',
      render: (value, record) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: 500 }}>{value}</span>
            <span style={{ color: THEME_COLORS.DISABLED }}>
              <span
                className='dot'
                style={{ backgroundColor: THEME_COLORS[record?.entityStatus] }}
              />
              {record.campaignId}
            </span>
          </div>
        );
      },
    },
    {
      width: 120,
      align: 'left',
      key: 'campaignType',
      title: 'Type',
      dataIndex: 'campaignType',
      showSorterTooltip: false,
      render: (value) => getCampaignType(value),
      sorter: true,
    },
    {
      width: 105,
      align: 'right',
      key: 'start',
      title: 'Start Date',
      dataIndex: 'startDate',
      showSorterTooltip: false,
      render: (value) => dayjs(value).format('DD/MM/YYYY'),
      sorter: true,
    },
    {
      width: 105,
      align: 'right',
      key: 'end',
      title: 'End Date',
      dataIndex: 'endDate',
      showSorterTooltip: false,
      render: (value) =>
        value ? dayjs(value).format('DD/MM/YYYY') : 'No End Date',
      sorter: true,
    },
    {
      width: 130,
      align: 'right',
      key: 'bookedImpressions',
      title: 'Booked Impr.',
      dataIndex: 'bookedImpressions',
      showSorterTooltip: false,
      sorter: true,
      render: (value: number) => (
        <span>{numberWithCommas(convertToFixedPointNotation(value, 0))}</span>
      ),
    },
    {
      width: 135,
      align: 'right',
      key: 'impressions',
      title: 'Delivered Impr.',
      dataIndex: 'impressions',
      showSorterTooltip: false,
      sorter: true,
      render: (value: number) => (
        <span>{numberWithCommas(convertToFixedPointNotation(value, 0))}</span>
      ),
    },
    {
      width: 85,
      align: 'right',
      key: 'clicks',
      title: 'Clicks',
      dataIndex: 'clicks',
      showSorterTooltip: false,
      sorter: true,
      render: (value: number) => (
        <span>{numberWithCommas(convertToFixedPointNotation(value, 0))}</span>
      ),
    },
    {
      width: 75,
      align: 'right',
      key: 'ctr',
      title: 'CTR',
      dataIndex: 'ctr',
      showSorterTooltip: false,
      sorter: true,
      render: (value: any) => (
        <span>{convertToFixedPointNotation(value, 2, '%')}</span>
      ),
    },
    {
      width: 90,
      align: 'right',
      key: 'cpm',
      title: 'CPM',
      dataIndex: 'cpm',
      showSorterTooltip: false,
      sorter: true,
      render: (value: number, record: CampaignData) => (
        <span>
          {numberWithCommas(
            convertToFixedPointNotation(value, 2, ` ${record.currency}`)
          )}
        </span>
      ),
    },
    {
      width: 120,
      align: 'right',
      key: 'budget',
      title: 'Budget',
      dataIndex: 'budget',
      showSorterTooltip: false,
      sorter: true,
      render: (value: number, record: CampaignData) => (
        <span>
          {numberWithCommas(
            convertToFixedPointNotation(value, 2, ` ${record.currency}`)
          )}
        </span>
      ),
    },
    {
      width: 120,
      align: 'right',
      key: 'spend',
      title: 'Spent',
      dataIndex: 'spend',
      showSorterTooltip: false,
      sorter: true,
      render: (value: number, record: CampaignData) => (
        <span>
          {numberWithCommas(
            convertToFixedPointNotation(value, 2, ` ${record.currency}`)
          )}
        </span>
      ),
    },
    {
      width: 80,
      align: 'right',
      key: 'daysLeft',
      title: 'Days Left',
      dataIndex: 'daysLeft',
    },
    {
      width: 70,
      align: 'right',
      key: 'margin',
      title: 'Margin',
      dataIndex: 'margin',
      render: (value: number) => (
        <span>{convertToFixedPointNotation(value, 2, '%')}</span>
      ),
    },
    {
      width: 77,
      align: 'center',
      key: 'osi',
      title: 'OSI',
      dataIndex: 'osi',
      render: (value: any) => value >= 0 ? <OsiBar value={value} /> : 'N/A',
    },
    {
      width: 80,
      align: 'center',
      key: 'integrationPlatform',
      title: 'Platform',
      dataIndex: 'integrationPlatform',
      render: (value: any) =>
        LOGOS[value] ? (
          <img src={LOGOS[value]} className='platform-icon' alt={value} />
        ) : (
          <span>--</span>
        ),
    },
    {
      width: 50,
      align: 'center',
      key: 'action',
      title: ' ',
      dataIndex: 'action',
      fixed: 'right',
      render: (_, record) => {
        if (record.integrationPlatformEntityUrl) {
          return (
            <Dropdown
              arrow
              placement='topRight'
              trigger={['click']}
              menu={{ items: getCampaignActionItems(record.integrationPlatformEntityUrl) }}
            >
              <MoreOutlined style={{ fontSize: '17px' }} />
            </Dropdown>
          );
        }

        return '';
      },
    },
  ];

  const handleExpandedRow = (e: any) => {
    let expandedRowNode = e.target.parentNode.parentNode.parentNode;
    const expandedRowClass = ' expanded-row ';

    while (!expandedRowNode.classList.value.includes('ant-table-row-level-0')) {
      expandedRowNode = expandedRowNode.parentNode;
    }

    if (expandedRowNode.classList.value.includes(expandedRowClass)) {
      expandedRowNode.classList.value = expandedRowNode.classList.value.replaceAll(expandedRowClass, '');
    } else {
      expandedRowNode.classList.value += expandedRowClass;
    }
  };

  return (
    <Fragment>
      <Table
        rowKey={(record) => record?._id}
        size='middle'
        columns={CampaignColumns}
        pagination={false}
        scroll={{ ...scroll }}
        dataSource={campaignData}
        loading={isCampaignsLoading}
        className='campaign-table'
        onChange={onTableChange}
        expandable={{
          expandIcon: ({ expanded, record, onExpand }) => {
            if (typeof record.children === 'undefined')
              return <span className='bar' />;
            return (
              <DownCircleOutlined
                className={`campaign-dropdown-icon ${expanded ? 'rotate-180' : ''}`}
                onClick={(e) => {
                  handleExpandedRow(e);
                  onExpand(record, e);
                }}
              />
            );
          },
          expandedRowRender: (record) => {
            if (!(viewLoaderOf === record?.key)) return <></>;
            return <Spin />;
          },
          indentSize: 0,
          rowExpandable: (record) => typeof record?.children !== 'undefined',
          onExpand: (status, record) => {
            addLineItemsWithCampaign(
              status,
              record,
              setViewLoaderOf,
              setCampaignData
            );
          },
        }}
      />

      <Pagination
        hideOnSinglePage
        showSizeChanger
        className='pagination'
        current={currentPage}
        total={totalCampaignData}
        defaultPageSize={INIT_PAGE_ITEMS_LIMIT}
        onChange={(page, limit) => pageChangeHandler(page, limit)}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
      />
    </Fragment>
  );
};

export default CampaignTable;
