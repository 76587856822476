import axios from 'axios';
import { ensureTrailingSlash } from '@helpers/auth-helpers';
import { LOCAL_STORAGE_KEYS } from '@constants/storage-constants';


/**
 * if the token is coming from marketplace
 * then take the access_token from the query params
 * else taking the access_token from local storage
 * @returns access_token
 */
const getAccessToken = (isMarketPlace: boolean) => {
  if (isMarketPlace) {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams: any, prop: any) => searchParams.get(prop),
    });
    let { at, rt } = params;

    if (at && rt) {
      return at;
    }
  }

  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
  const parsedToken = token ? JSON.parse(token) : { access_token: null };
  const { access_token } = parsedToken;

  return access_token;
};

export const axiosInstance = axios.create({
  baseURL: ensureTrailingSlash(process.env.REACT_APP_API_BASE_URL ?? ''),
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${getAccessToken(false)}`,
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.Authorization = `Bearer ${getAccessToken(true)}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    /**
    * Here we can handle all kind of errors
    * By using status codes
    * const originalRequest = error.config;
    */
    return Promise.reject(err);
  }

);
