import { PlatformTargetingType, TargetingDataModel } from '@models/targeting-model';
import { DraftCampaignResponse } from '@models/campaign-models';

export const INIT_DRAFT_CAMPAIGN_DATA: DraftCampaignResponse = {
  _id: '',
  name: '',
  data: {
    integrationPlatform: '',
    campaignName: '',
    currencyCode: '',
    campaignType: '',
    reference: '',
    buyingEntityId: 0,
    buyingEntityOfficeId: 0,
    lineItems: [],
    creativesItems: [],
    createdLineItems: [],
  },
  createdByUserId: 0,
  createdAt: 0,
  updatedAt: 0,
  status: '',
  __v: 0,
};

export const INIT_TARGETING_DATA: TargetingDataModel = {
  platformTypes: [
    PlatformTargetingType.WEB,
    PlatformTargetingType.MOBILE
  ],
};