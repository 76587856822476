import { Tooltip } from 'antd';
import { useState, ReactNode } from 'react';
import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
// import './global-info-tooltip.css';

interface GlobalInfoToolTipProps {
  defaultOpen?: boolean;
  placement?:
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';
  color?: string;
  textColor?: string;
  text?: ReactNode;
  toolTipIconColor?: string;
}

const GlobalInfoToolTip = (props: GlobalInfoToolTipProps) => {
  const {
    defaultOpen = false,
    placement = 'bottomLeft',
    color = 'white',
    textColor = '#A3A5AA',
    text = '',
    toolTipIconColor = '#A3A5AA'
  } = props;
  const [isShowTooltip, setIsShowTooltip] = useState(defaultOpen);

  return (
    <Tooltip
      open={isShowTooltip}
      placement={placement}
      color={color}
      title={
        <div className="tooltip-container">
          <div className="tooltip-head">
            <div className="tooltip-yellow-dash" />
            <CloseOutlined
              className="tooltip-close-btn"
              onClick={() => setIsShowTooltip((prev) => !prev)}
            />
          </div>
          <p style={{ color: textColor, textAlign: 'justify' }}>{text}</p>
        </div>
      }
    >
      <InfoCircleOutlined
        style={{ fontSize: '16px', color: toolTipIconColor, paddingLeft: '10px' }}
        onClick={() => setIsShowTooltip((prev) => !prev)}
      />
    </Tooltip>
  );
};

export default GlobalInfoToolTip;
