import { Breadcrumb } from 'antd';
import { CustomBreadCrumbProps } from '@models/global-models';
import './base-breadcrumb.css';

const BaseBreadcrumb = ({ breadcrumbItems }: CustomBreadCrumbProps) => {
  return (
    <Breadcrumb className='base-breadcrumb' separator='>'>
      {breadcrumbItems.map((item, index, { length }) => (
        <Breadcrumb.Item className={index + 1 === length ? 'breadcrumb-text-color' : 'breadcrumb-text-color breadcrumb-item'} key={index}>{item}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BaseBreadcrumb;
