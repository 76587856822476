import { Input, Row, Col, Select, Button } from 'antd';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';

import {
  DropdownMetaValuesType,
  GetMetaCampaignParams,
} from '@models/meta-campaign-models';
import { OptionType } from '@models/global-models';
import { IntegrationPlatformEnum } from '@models/campaign-models';
import { ActionListContext } from '@contexts/action-list-context';

import {
  META_STATUS_OPTIONS,
  INIT_META_DROPDOWN_VALUES,
  INIT_META_CAMPAIGNS_PARAMS,
} from '@constants/campaign-data';
import { SearchIcon } from '@icons';
import { getMetaDataIntregrationPlatforms } from '@helpers/global-helpers';

const prefix = <SearchIcon className='search-icon' />;

const MetaCampaignTableFilter: FC = () => {
  const { loadingStates, businessUnits, setApiParams } = useContext(ActionListContext);

  const [isPlatformLoading, setIsPlatformLoading] = useState<boolean>(true);
  const [metaDataPlatform, setMetaDataPlatform] = useState<OptionType[]>([]);
  const [metaDropdownValues, setMetaDropdownValues] = useState<DropdownMetaValuesType>({
    ...INIT_META_DROPDOWN_VALUES,
  });

  useEffect(() => {
    getPlatforms();
  }, []);

  const getPlatforms = async () => {
    const options = await getMetaDataIntregrationPlatforms();
    setMetaDataPlatform(options);
    setIsPlatformLoading(false);
  };

  const updateApiParams = (key: keyof GetMetaCampaignParams, value: any[] | string) => {
    setApiParams((prevState) => ({
      ...prevState,
      [key]: value,
      page: 1,
    }));
  };

  const updateDropdownValues = (key: keyof DropdownMetaValuesType, value: any[] | string) => {
    setMetaDropdownValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handlePlatformChange = (value: IntegrationPlatformEnum[]) => {
    updateApiParams('integrationPlatform', [...value]);
    updateDropdownValues('platformValues', [...value]);
  };

  const handleBusinessUnitChange = (value: number[]) => {
    updateApiParams('businessUnitId', [...value]);
    updateDropdownValues('businessUnitValues', [...value]);
  };

  const handleStatusChange = (value: string) => {
    updateApiParams('metaCampaignStatus', value);
    updateDropdownValues('statusValue', value);
  };

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateApiParams('search', event.target.value);
  };

  const handleClear = () => {
    setMetaDropdownValues({ ...INIT_META_DROPDOWN_VALUES });
    setApiParams({ ...INIT_META_CAMPAIGNS_PARAMS });
  };

  return (
    <div>
      <div className='search-section'>
        <Row gutter={10} className='search-container'>
          <Col span={16}>
            <Input
              placeholder='Search'
              prefix={prefix}
              className='search-field'
              onChange={handleSearchOnChange}
            />
          </Col>
        </Row>
      </div>

      <Row gutter={16} className='filter-section'>
        <Col span={5}>
          <CommonSelect
            placeholder='All Campaigns'
            value={metaDropdownValues.statusValue}
            options={META_STATUS_OPTIONS}
            onChange={handleStatusChange}
          />
        </Col>
        <Col span={6}>
          <CommonSelect
            mode='multiple'
            placeholder='Select Platform'
            value={metaDropdownValues.platformValues}
            loading={isPlatformLoading}
            options={metaDataPlatform}
            onChange={handlePlatformChange}
          />
        </Col>
        <Col span={6}>
          <CommonSelect
            mode='multiple'
            placeholder='Select Business Unit'
            value={metaDropdownValues.businessUnitValues}
            loading={loadingStates.isBULoading}
            options={businessUnits}
            onChange={handleBusinessUnitChange}
          />
        </Col>
        <Col span={4}>
          <Button ghost type='primary' onClick={handleClear}>
            Clear All
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MetaCampaignTableFilter;

const filterOption = (input: string, option?: OptionType) => {
  return (option?.label?.toString()?.toLowerCase() ?? '').includes(input.toLowerCase());
};

const filterSort = (optionA: OptionType, optionB: OptionType) => {
  return (optionA?.label ?? '')
    .toString().toLowerCase()
    .localeCompare((optionB?.label ?? '').toString().toLowerCase());
};

const CommonSelect = (props: any) => {
  return (
    <Select
      {...props}
      className='filter-dropdown'
      showArrow
      allowClear
      showSearch
      style={{ width: '100%' }}
      filterOption={filterOption}
      filterSort={filterSort}
      maxTagCount={1}
    />
  );
};
