import {
  GetCampaignsParams,
  GetCampaignsResponse,
} from '@models/campaign-models';
import HttpClientInstance from './base-services';
import { API_END_POINTS } from '@constants/global-constants';
import { getCampaignsRequestParams } from './campaign-services';
import {
  WatchedCampaignRequest,
  WatchedCampaignResponse,
} from '@models/watched-campaigns.model';

const httpClient = new HttpClientInstance();

/**
 * To get users watched campaigns and fetch campaigns for the data table.
 *
 * @param params
 * @returns
 */
export async function getWatchedCampaigns(
  params: GetCampaignsParams
): Promise<GetCampaignsResponse> {
  const url = `${API_END_POINTS.WATCHED_CAMPAIGNS}?${getCampaignsRequestParams(
    params
  )}`;

  return await httpClient.get(url);
}

export async function isCampaignWatched(
  params: WatchedCampaignRequest
): Promise<WatchedCampaignResponse> {
  const url = `${
    API_END_POINTS.WATCHED_CAMPAIGNS
  }/search${getWatchedCampaignsRequestParams(params)}`;

  return await httpClient.get(url);
}

export async function watchedCampaign(
  data: WatchedCampaignRequest,
  isAlreadyWatched: boolean
): Promise<boolean> {
  let response;
  if (isAlreadyWatched) {
    response = await httpClient.delete(API_END_POINTS.WATCHED_CAMPAIGNS, {
      data,
    });
  } else {
    response = await httpClient.post(API_END_POINTS.WATCHED_CAMPAIGNS, {
      data,
    });
  }

  return response[0]?.campaignId !== undefined ? true : false;
}

/**
 * To get campaign request querystring params.
 *
 * @param params
 * @returns
 */
function getWatchedCampaignsRequestParams(
  params: WatchedCampaignRequest
): string {
  const { campaignId, integrationPlatform } = params;
  let url = '';

  if (campaignId) {
    url = url.concat(`?campaignId=${campaignId}`);
  }

  if (integrationPlatform) {
    url = url.concat(`&integrationPlatform=${integrationPlatform}`);
  }

  return url;
}

export async function getAllWatchedCampaigns(): Promise<GetCampaignsResponse> {
  return await httpClient.get(API_END_POINTS.WATCHED_CAMPAIGNS);
}
