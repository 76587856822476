import {
  BuyingEntityResponse,
  BuyingEntityOfficeResponse,
} from '@models/user-models';

import {
  LineItem,
  GetCampaignsParams,
  CampaignStatusEnum,
  GetCampaignsResponse,
  DraftCampaignPayload,
  DraftCampaignResponse,
  CreateCampaignPayload,
  CreateCampaignResponse,
  IntegrationPlatformEnum,
  GetBusinessUnitsResponse,
  UpdateDraftCampaignPayload,
  GetCampaignWiseLineItemsParams,
  CampaignTypeEnum,
} from '@models/campaign-models';

import { API_END_POINTS } from '@constants/global-constants';
import HttpClientInstance from './base-services';

const httpClient = new HttpClientInstance();

/** API: getCampaigns() */
export async function getCampaigns(
  params: GetCampaignsParams
): Promise<GetCampaignsResponse> {
  const url = `${API_END_POINTS.CAMPAIGNS}?${getCampaignsRequestParams(
    params
  )}`;

  // TODO: Match Users watched campaign Ids and add isWatched property to the campaign data row.
  return await httpClient.get(url);
}

/**
 * To get campaign request querystring params.
 *
 * @param params
 * @returns
 */
export function getCampaignsRequestParams(params: GetCampaignsParams): string {
  const {
    page,
    limit,
    search,
    entityStatus,
    businessUnitId,
    integrationPlatform,
    sort,
    campaignType,
    isDedicatedPage,
  } = params;
  let url = '';

  if (isDedicatedPage) {
    url = url.concat('&isRequestFromMarketplaceUI=true');
  }

  if (entityStatus.length) {
    url = url.concat(`&entityStatus=${getEntityStatusParam(entityStatus)}`);
  }

  if (businessUnitId.length) {
    url = url.concat(`&businessUnitId=${businessUnitId.join(',')}`);
  }

  if (search && search.length > 2) {
    url = url.concat(`&search=${search}`);
  }

  if (limit) {
    url = url.concat(`&limit=${limit}`);
  }

  if (page) {
    url = url.concat(`&page=${page}`);
  }

  if (sort) {
    url = url.concat(`&sort=${sort}`);
  }

  if (integrationPlatform.length) {
    url = url.concat(
      `&integrationPlatform=${getIntegrationPlatformParam(integrationPlatform)}`
    );
  }

  if (campaignType?.length) {
    url = url.concat(`&campaignType=${getCampaignTypeParam(campaignType)}`);
  }

  return url.substring(1);
}

/**
 * Parse entity status filter
 *
 * @param entityStatus
 * @returns
 */
const getEntityStatusParam = (entityStatus: CampaignStatusEnum[]): string => {
  return entityStatus.join(',');
};

/**
 * Parse integration platform filter
 *
 * @param integrationPlatform
 * @returns
 */
const getIntegrationPlatformParam = (
  integrationPlatform: IntegrationPlatformEnum[]
): string => {
  return integrationPlatform.join(',');
};

/**
 * Parse campaign type filter
 *
 * @param campaignType
 * @returns
 */
const getCampaignTypeParam = (campaignType: CampaignTypeEnum[]): string => {
  return campaignType.join(',');
};

/** API: getCampaignWiseLineItems() */
export async function getCampaignWiseLineItems(
  params: GetCampaignWiseLineItemsParams
): Promise<LineItem[]> {
  const url =
    API_END_POINTS.LINE_ITEMS +
    `?campaignId=${params.campaignId}` +
    `&integrationPlatform=${params.integrationPlatform}` +
    `&advertiserId=${params.advertiserId}&campaignType=${params.campaignType}`;

  return await httpClient.get(url);
}

/** API: getBusinessUnits() */
export async function getBusinessUnits(): Promise<GetBusinessUnitsResponse> {
  const url = API_END_POINTS.BUSINESS_UNITS;

  return await httpClient.get(url);
}

/** API: createCampaign() */
export async function createCampaign(
  data: CreateCampaignPayload
): Promise<CreateCampaignResponse> {
  const url = API_END_POINTS.CAMPAIGNS;

  return await httpClient.post(url, { data });
}

/** API: createDraftCampaign() */
export async function createDraftCampaign(
  data: DraftCampaignPayload
): Promise<DraftCampaignResponse> {
  const url = API_END_POINTS.DRAFTS;

  return await httpClient.post(url, { data });
}

/** API: getDraftCampaignById() */
export async function getDraftCampaignById(
  id: string
): Promise<DraftCampaignResponse> {
  const url = `${API_END_POINTS.DRAFTS}/${id}`;

  return await httpClient.get(url);
}

/** API: updateDraftCampaign() */
export async function updateDraftCampaign(
  payload: UpdateDraftCampaignPayload
): Promise<CreateCampaignResponse> {
  const { id, name, data } = payload;
  const url = `${API_END_POINTS.DRAFTS}/${id}`;

  return await httpClient.patch(url, { data: { name, data } });
}

/** API: getAllBuyingEntities() */
export async function getAllBuyingEntities(
  query: string = ''
): Promise<BuyingEntityResponse[]> {
  const url = API_END_POINTS.BUYING_ENTITY;
  return await httpClient.get(url);
}

/** API: getBuyingEntities() */
export async function getBuyingEntities(
  query: string = ''
): Promise<BuyingEntityResponse[]> {
  const url = API_END_POINTS.BUYING_ENTITY + (query ? `?search=${query}` : '');
  return await httpClient.get(url);
}

/** API: getBuyingEntityById() */
export async function getBuyingEntityById(
  id: number
): Promise<BuyingEntityResponse> {
  // const url = API_END_POINTS.BUYING_ENTITY + (query ? `?search=${query}` : '');
  const url = API_END_POINTS.BUYING_ENTITY + `/${id}`;
  return await httpClient.get(url);
}

/** API: getBuyingEntityOffices() */
export async function getBuyingEntityOffices(
  query: string = ''
): Promise<any[]> {
  const url = API_END_POINTS.ENTITY_OFFICES + `?search=${query}`;

  return await httpClient.get(url);
}

/** API: getBuyingEntityOfficeById() */
export async function getBuyingEntityOfficeById(
  buyingEntityId: number,
  buyingEntityOffceId: number
): Promise<BuyingEntityOfficeResponse> {
  const url = `buying-entities/${buyingEntityId}/offices/${buyingEntityOffceId}`;

  return await httpClient.get(url);
}
