import { SESSION_STORAGE_KEYS } from './../constants/storage-constants';

/** 
 * ?Info: This function will check the given string is a valid URL or not
 */
export function validateUrl(value: string) {
  return value.includes('.') && (value.lastIndexOf('.') !== value.length - 1);

  //? Actual URL checking ommited, just checking .* domain only
  /* 
  if (
    !value.startsWith('http://') &&
    !value.startsWith('https://') &&
    !value.startsWith('www.')
  ) return false;
  return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(value); 
  */
};

export function eliminateUrlPrefix(url: string) {
  let mainUrl = '';

  if (url.includes('www.')) {
    mainUrl = url.substring(url.indexOf('www.') + 4);
  } else if (url.includes('://')) {
    mainUrl = url.substring(url.indexOf('://') + 3);
  } else {
    mainUrl = url;
  }

  if (mainUrl.endsWith('/')) {
    return mainUrl.substring(0, mainUrl.length - 1);
  }

  return mainUrl;
};

export function getTargetingOptions() {
  return JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEYS.TARGETING_DATA) ?? 'null'
  );
};

export function setTargetingOptions(data: any) {
  sessionStorage.setItem(
    SESSION_STORAGE_KEYS.TARGETING_DATA,
    JSON.stringify(data),
  );
};