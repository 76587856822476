import { createContext } from 'react';
import { INIT_META_CAMPAIGNS_LOADERS } from '@constants/campaign-data';
import { ActionListContextValues, ActionListProviderProps } from '@models/meta-campaign-models';

const defaultValues = {
  loadingStates: { ...INIT_META_CAMPAIGNS_LOADERS },
  businessUnits: [],
  agencies: [],
  salesPersons: [],
  adOpsPersons: [],
  advertisers: [],
  setRefetch: () => null,
  setApiParams: () => null,
  getAdvertisers: () => null,
  getSalesPersons: () => null,
  updateLoadingState: () => null,
};

export const ActionListContext = createContext<ActionListContextValues>(defaultValues);

export const ActionListProvider = ({ children, value }: ActionListProviderProps) => {
  return (
    <ActionListContext.Provider value={value}>
      {children}
    </ActionListContext.Provider>
  );
};