import HttpClientInstance from './base-services';
import { UserDetailsResponse } from '@models/user-models';
import { API_END_POINTS } from '@constants/global-constants';

const httpClient = new HttpClientInstance();

export async function getUserDetails(): Promise<UserDetailsResponse> {
  const url = API_END_POINTS.USER_DETAILS;

  return await httpClient.get(url);
}
