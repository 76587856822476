export const routes = {
  login: {
    title: 'Login',
    key: 'login',
    path: '/auth/login',
  },
  logout: {
    title: 'Logout',
    key: 'logout',
    path: '/auth/logout',
  },
  dashboard: {
    title: 'Dashboard',
    key: 'dashboard',
    path: '/dashboard',
  },
  userDetails: {
    title: 'User Details',
    key: 'user-details',
    path: '/user-details',
  },
  createCampaign: {
    title: 'Create Campaign',
    key: 'create-campaign',
    path: '/create-campaign',
  },
  myCampaigns: {
    title: 'My Campaigns',
    key: 'my-campaigns',
    path: '/my-campaigns',
  },
  notFound: {
    title: 'Page Not Found',
    key: 'page-not-found',
    path: '*',
  },
  home: {
    title: 'Home',
    key: 'home',
    path: '/',
  },
  favourites: {
    title: 'Favourites',
    key: 'favourites',
    path: '',
  },
  campaigns: {
    title: 'Campaigns',
    key: 'campaigns',
    path: '',
  },
  watchedCampaigns: {
    title: 'Watched Campaigns',
    key: 'watched-campaigns',
    path: '/watched-campaigns',
  },
  actionList: {
    title: 'Action List',
    key: 'action-list',
    path: '/action-list',
  },
  marketplaceActionList: {
    title: 'Marketplace Action List',
    key: 'marketplace-action-list',
    path: '/marketplace-action-list',
  },
};

export const marketplaceRoutes = {
  actionList: {
    title: 'Action List',
    key: 'dedicated-action-list',
    path: '/dedicated-action-list',
  },
  myCampaigns: {
    title: 'My Campaigns',
    key: 'dedicated-my-campaigns',
    path: '/dedicated-my-campaigns',
  },
};
