import { Button, Modal } from 'antd';
import { useState } from 'react';
import './global-error-handler.css';

const GlobalErrorHandler = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      centered
      footer={[
        <Button type='text' onClick={handleOk}>
          Close
        </Button>
      ]}
      closable={false}
    >
      <p><b>Oops! Something went wrong.</b></p>
    </Modal>
  );
};

export default GlobalErrorHandler;