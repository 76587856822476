import { message } from 'antd';
import { useEffect, useState } from 'react';

import { getCampaignMode } from '@helpers/campaign-helpers';
import { updateDraftCampaign } from '@services/campaign-services';
import { CreativeTableProps } from '@models/create-campaign-models';
import { TableCreativeItem, UpdateDraftCampaignPayload } from '@models/campaign-models';

import CreativeMainTable from './creative-main-table';
import CreativeTableMenu from './creative-table-menu';
import './creative-table.css';

const MyCampaignsTable = ({
  draftCampaign,
  radioValue,
  isDisableTypeSelection,
  setIsRefetch,
  isExpandTable,
  setIsExpandTable,
  setSelectedCreative,
}: CreativeTableProps) => {
  const [isShowAssigned, setIsShowAssigned] = useState<boolean>(true);
  const [creativesData, setCreativesData] = useState<TableCreativeItem[]>([]);
  const [selectedCreativeIds, setSelectedCreativeIds] = useState<number[]>([]);
  const indexOfLi = getCampaignMode()?.id ?? draftCampaign?.data?.lineItems?.length - 1;

  /**
   *? Triggering show assigned and showing assigned creative types
   */
  useEffect(() => {
    if (draftCampaign) {
      const { lineItems, creativesItems } = draftCampaign.data;

      let modifiedCreativesItems = creativesItems.map(item => ({
        ...item,
        isAssigned: lineItems[indexOfLi].creatives.includes(item.id),
      }));

      if (isDisableTypeSelection) {
        modifiedCreativesItems = modifiedCreativesItems.filter(item => item.creativeType === radioValue);
      }

      if (isShowAssigned) {
        setCreativesData(modifiedCreativesItems);
      } else {
        setCreativesData(modifiedCreativesItems.filter(item => !item.isAssigned));
      }
    }
  }, [draftCampaign, isShowAssigned, radioValue, isDisableTypeSelection]);

  /**
   *? Toggle assign radio button handler
   */
  const handleToggleAssign = (id: number, current: boolean) => {
    const { creatives } = draftCampaign.data.lineItems[indexOfLi];

    if (current) {
      updateChanges(creatives.filter(cId => cId !== id));
    } else {
      updateChanges([...creatives, id]);
    }
  };

  /**
   *? Multiple creative item assigning handler
   */
  const handleMultipleAssign = () => {
    const { creatives } = draftCampaign.data.lineItems[indexOfLi];

    selectedCreativeIds.forEach(id => {
      if (!creatives.includes(id)) {
        creatives.push(id);
      }
    });

    updateChanges(creatives);
    setSelectedCreativeIds([]);
  };

  /**
   *? Creative item delete handler
   */
  const handleDelete = () => {
    const { _id, name, data } = draftCampaign;
    let updateDraftCampaignPayload: UpdateDraftCampaignPayload = { name, data, id: _id };

    // Removing selected ids from all line item's creative ids
    updateDraftCampaignPayload.data.lineItems = [
      ...updateDraftCampaignPayload.data.lineItems.map(item => ({
        ...item,
        creatives: item.creatives.filter(id => !selectedCreativeIds.includes(id)),
      }))
    ];

    // Removing creative items from base creatives
    updateDraftCampaignPayload.data.creativesItems = [
      ...updateDraftCampaignPayload.data.creativesItems.filter(item => !selectedCreativeIds.includes(item.id)),
    ];

    setSelectedCreativeIds([]);
    updateDraftCampaign(updateDraftCampaignPayload)
      .then(() => setIsRefetch(prev => !prev))
      .catch(() => message.error('Someting went wrong'));
  };

  const updateChanges = (creativeIds: number[]) => {
    const { _id, name, data } = draftCampaign;
    const updateDraftCampaignPayload: UpdateDraftCampaignPayload = { name, data, id: _id };

    updateDraftCampaignPayload.data.lineItems[indexOfLi].creatives = [...creativeIds];

    updateDraftCampaign(updateDraftCampaignPayload)
      .then(() => setIsRefetch(prev => !prev))
      .catch(() => message.error('Someting went wrong'));
  };

  return (
    <div className='creative-table'>
      <CreativeTableMenu
        isExpandTable={isExpandTable}
        isCreativeSelected={!!selectedCreativeIds.length}
        setIsExpandTable={setIsExpandTable}
        setIsShowAssigned={setIsShowAssigned}
        handleAssignToLi={handleMultipleAssign}
        handleDelete={handleDelete}
      />

      <CreativeMainTable
        creativesData={creativesData}
        handleToggleAssign={handleToggleAssign}
        selectedCreativeIds={selectedCreativeIds}
        setSelectedCreativeIds={setSelectedCreativeIds}
        setSelectedCreative={setSelectedCreative}
      />
    </div>
  );
};

export default MyCampaignsTable;
