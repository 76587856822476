import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Row, Col, DatePicker, Form, Input, Select, InputNumber } from 'antd';

import {
  LineItemModel,
  DraftCampaignResponse,
  UpdateDraftCampaignPayload
} from '@models/campaign-models';
import { DateAndBudgetProps } from '@models/create-campaign-models';

import { getUserDetails } from '@helpers/global-helpers';
import { SESSION_STORAGE_KEYS } from '@constants/storage-constants';
import { hasLineItems, isManagedCampaign } from '@helpers/campaign-helpers';
import { CAMPAIGN_MODES, CAMPAIGN_TYPE, FORM_TYPE } from '@constants/global-constants';

import CurrencyOptions from '@components/common/currency-options';
import CreateCampaignsBottomNavigation from '../create-campaigns-bottom-navigation';
import './date-and-budget.css';

const DateAndBudget = (props: DateAndBudgetProps) => {
  const { draftCampaign, onNext, onBack, handleCancelOnConfirm } = props;
  const [campaignModeData, setCampaignModeData] = useState<any>();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue('line_item_currency', draftCampaign?.data?.currencyCode);
    const data = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEYS.CREATE_CAMPAIGN_MODE) ?? 'null'
    );

    if ((data?.id >= 0) && (data?.mode === CAMPAIGN_MODES.EDIT)) {
      setCampaignModeData(data);
      if (hasLineItems(draftCampaign, data?.id)) prefillData(data);
    }
  }, [draftCampaign]);

  const handleOnFinish = (formValues: any) => {
    const cloneDraftCampaign: DraftCampaignResponse = { ...draftCampaign };
    const userDetails = getUserDetails();

    let lineItemData: LineItemModel = {
      lineItemName: formValues?.line_item_name,
      startDate: new Date(formValues.start_date_time?.$d).toISOString(),
      endDate: formValues.end_date_time ? new Date(formValues.end_date_time?.$d).toISOString() : '',
      pacing: formValues?.pacing,
      cpm: formValues?.cpm,
      margin: formValues?.margin ? +formValues?.margin : null,
      budget: formValues?.line_item_budget,
      creatives: [],
      integrationPlatform: '',
      targeting: {},
      createdByUserId: userDetails ? userDetails.user_id : null,
    };

    const updateDraftCampaignPayload: UpdateDraftCampaignPayload = {
      id: cloneDraftCampaign._id,
      name: cloneDraftCampaign.name,
      data: { ...cloneDraftCampaign.data },
    };

    if (
      campaignModeData?.mode === CAMPAIGN_MODES.EDIT &&
      hasLineItems(draftCampaign, campaignModeData?.id)
    ) {
      const {
        creatives, integrationPlatform, targeting
      } = draftCampaign?.data?.lineItems[campaignModeData?.id];

      updateDraftCampaignPayload.data.lineItems[campaignModeData?.id] = {
        ...lineItemData,
        creatives,
        integrationPlatform,
        targeting,
      };
    } else {
      updateDraftCampaignPayload.data.lineItems = [
        ...cloneDraftCampaign.data.lineItems,
        { ...lineItemData },
      ];
    }

    onNext(updateDraftCampaignPayload);
  };

  const prefillData = (data: any) => {
    const {
      lineItemName, startDate, endDate, budget, cpm, margin,
    } = draftCampaign?.data?.lineItems[data?.id];
    form.setFieldValue('line_item_name', lineItemName);
    form.setFieldValue('start_date_time', dayjs(new Date(startDate)));
    form.setFieldValue('end_date_time', endDate ? dayjs(new Date(endDate)) : '');
    form.setFieldValue('line_item_budget', budget);
    form.setFieldValue('cpm', cpm);
    form.setFieldValue('margin', margin);
    form.setFieldValue('line_item_currency', draftCampaign?.data?.currencyCode);
  };

  return (
    <div className='container right-to-left-fade-in'>
      <Form
        form={form}
        name={FORM_TYPE.DATE_AND_BUDGET_FORM}
        className='campaign-form'
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleOnFinish}
      >
        <Row>
          <Col className='gutter-row' span={11}>
            <h1>Add Line Item Name And Dates</h1>
            <Row gutter={16}>
              <Col span={24} className='gutter-row'>
                <Form.Item
                  label='Line Item Name'
                  name='line_item_name'
                  rules={[
                    {
                      required: true,
                      message: 'Enter Line Item Name',
                      type: 'string',
                    },
                  ]}
                  className='mb-4'>
                  <Input placeholder='Enter Line Item Name' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24} className='gutter-row'>
                <Form.Item
                  label='Start Date and Time'
                  name='start_date_time'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Start Date and Time',
                      type: 'date',
                    },
                    {
                      validator: (_, start_date_time) => {
                        if (dayjs(start_date_time?.$d).isAfter(dayjs()))
                          return Promise.resolve();
                        return Promise.reject('Start Date/Time Should be greater than Today');

                      }
                    }
                  ]}
                  className='mb-4'>
                  <DatePicker showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }} format={'YYYY-MM-DD HH:mm'} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24} className='gutter-row'>
                <Form.Item
                  label='End Date and Time'
                  name='end_date_time'
                  rules={[
                    {
                      required: isManagedCampaign(draftCampaign),
                      type: 'date',
                    },
                    {
                      validator: (_, end_date_time) => {
                        let start_date_time = form.getFieldValue('start_date_time');
                        if (!form.getFieldValue('end_date_time') || (dayjs(end_date_time?.$d).isAfter(start_date_time?.$d)))
                          return Promise.resolve();
                        return Promise.reject('End Date Must be greater than Start Date');
                      }
                    }
                  ]}
                  className='mb-4'>
                  <DatePicker showTime={{ defaultValue: dayjs('23:59', 'HH:mm') }} format={'YYYY-MM-DD HH:mm'} />
                </Form.Item>
              </Col>

            </Row>
          </Col>
          <Col span={2}></Col>
          <Col className='gutter-row' span={11}>
            <h1>Add line item budget and choose costing model</h1>
            <Row gutter={16}>
              <Col span={18} className='gutter-row'>
                <Form.Item
                  label='Enter Line Item Budget'
                  name='line_item_budget'
                  rules={[
                    {
                      required: draftCampaign.data.campaignType === CAMPAIGN_TYPE.MANAGED_CAMPAIGN,
                      message: 'Please enter your Line Item budget',
                    },
                  ]}
                  className='mb-4'>
                  <InputNumber placeholder='Enter Budget' className='number-input-width' />
                </Form.Item>
              </Col>
              <Col span={6} className='gutter-row'>
                <CurrencyOptions
                  required
                  disabled
                  name='line_item_currency'
                  className='currency-select-without-label'
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24} className='gutter-row'>
                <Form.Item
                  name='pacing'
                  label='Select Pacing'
                  rules={[{ required: false }]}
                  initialValue='Evenly'>
                  <Select allowClear disabled>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12} className='gutter-row'>
                <Form.Item
                  label='CPM'
                  name='cpm'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter CPM',
                    },
                  ]}
                  className='mb-4'>
                  <InputNumber placeholder='Enter CPM' className='number-input-width' />
                </Form.Item>
              </Col>
              <Col span={12} className='gutter-row'>
                <Form.Item
                  name='margin'
                  label='Margin'
                  rules={[{ required: false }]}
                  className='mb-4'>
                  <Input placeholder='Enter Margin' />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <CreateCampaignsBottomNavigation onBack={onBack} onCancel={handleCancelOnConfirm} />
      </Form>
    </div>
  );
};

export default DateAndBudget;
