import { Fragment } from 'react';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Row, Col, Form, Select, Input, Button, Radio } from 'antd';

import { getSettings } from '@helpers/global-helpers';
import { GlobalSettings } from '@models/settings-models';
import { CREATIVE_TYPES } from '@constants/campaign-data';
import { CreativeFormFieldsProps } from '@models/create-campaign-models';

const { Option } = Select;

const CreativeFormFields = ({
  radioValue,
  setRadioValue,
  selectedCreative,
  setSelectedCreative,
  isDisableTypeSelection,
}: CreativeFormFieldsProps) => {
  const globalSettings: GlobalSettings = getSettings();

  return (
    <Fragment>
      <Col className='gutter-row' span={11}>
        <Row gutter={16}>
          <Col span={24} className='gutter-row'>
            <Form.Item
              label={<strong>Creative Name:</strong>}
              name='creativeName'
              rules={[
                {
                  required: true,
                  message: 'Please enter creative name',
                  type: 'string',
                },
              ]}
              className='mb-4'>
              <Input placeholder='Enter Creative Name' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} className='gutter-row'>
            <Form.Item
              label={<strong>Select creative type and add creative</strong>}
              name='creativeType'
              rules={[{ required: false }]}
              className='mb-4'
            >
              <div className='custom-radio-warapper'>
                <Radio.Group
                  value={radioValue ? radioValue : selectedCreative?.creativeType}
                  onChange={e => setRadioValue(e.target.value)}
                  disabled={isDisableTypeSelection}
                >
                  <Row gutter={16}>
                    <Col span={12}><Radio value={CREATIVE_TYPES.DISPLAY}> Display </Radio></Col>
                    <Col span={12}><Radio value={CREATIVE_TYPES.VIDEO}> Video </Radio></Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}><Radio disabled value={CREATIVE_TYPES.ACP}> ACP </Radio></Col>
                    <Col span={12}><Radio value={CREATIVE_TYPES.RICH_MEDIA}> Rich Media </Radio></Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}><Radio disabled value={CREATIVE_TYPES.NATIVE}> Native </Radio></Col>
                  </Row>
                </Radio.Group>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col span={2}></Col>

      <Col className='gutter-row' span={11}>
        <Row gutter={16}>
          <Col span={24} className='gutter-row'>
            <Form.Item
              label={`Creative ${radioValue === CREATIVE_TYPES.VIDEO ? 'Source' : 'Third Party Tag'}`}
              name='thirdPartyTag'
              rules={[
                {
                  required: true,
                  message: `Please enter creative ${radioValue === CREATIVE_TYPES.VIDEO ? 'source' : 'third party tag'}`,
                },
              ]}
              className='mb-4'>
              <Input.TextArea
                rows={4}
                placeholder={`Enter Creative ${radioValue === CREATIVE_TYPES.VIDEO ? 'Source' : 'Third Party Tag'}`}
              />
            </Form.Item>
          </Col>
        </Row>

        {(radioValue !== CREATIVE_TYPES.VIDEO) && (
          <Row gutter={16}>
            <Col span={24} className='gutter-row'>
              <Form.Item
                label='Size'
                name='displaySize'
                rules={[
                  {
                    required: true,
                    message: 'Please select size',
                  },
                ]}
                className='mb-4'>
                <Select placeholder='Select size' allowClear showSearch dropdownStyle={{ maxHeight: '140px' }}>
                  {globalSettings.size.map(value => {
                    return <Option key={value.id} value={value.display}>{value.display}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={16}>
          <Col span={24} className='gutter-row'>
            <Form.Item
              label='Advertise Domain'
              name='advertiserDomain'
              rules={[
                {
                  required: true,
                  message: 'Please enter advertise domain',
                },
              ]}
              className='mb-4'>
              <Input placeholder='Enter a domain' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24} className='gutter-row'>
            <Form.Item
              label='IAB Category'
              name='iabCategoryDescription'
              rules={[
                {
                  required: true,
                  message: 'Please select IAB',
                },
              ]}
              className='mb-4'>
              <Select placeholder='Select IAB'
                showSearch
                allowClear
              >
                {globalSettings.iabCategory.map((value, id) => {
                  return <Option key={id} value={JSON.stringify(value)}>{value.description}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col span={24} className='creative-add-btn-container'>
        {!!selectedCreative && (
          <Button className='mr-2' onClick={() => setSelectedCreative(null)}>
            Cancel
          </Button>
        )}
        <Button htmlType='submit'>
          {!!selectedCreative ? <SaveOutlined /> : <PlusOutlined />}
          {!!selectedCreative ? 'Save Changes' : 'Add'}
        </Button>
      </Col>
    </Fragment>
  );
};

export default CreativeFormFields;