import { createBrowserRouter } from 'react-router-dom';

import Meta from '@components/Meta';
import AuthLayout from '@components/layouts/auth-layout';
import BaseLayout from '@components/layouts/base-layout';
import RedirectPage from '@components/common/redirect-page';

import Login from '@features/auth/login';
import MyCampaigns from '@features/my-campaigns';
import UserDetails from '@features/user-details';
import CreateCampaigns from '@features/create-campaigns';
import { marketplaceRoutes, routes } from '@constants/route-constants';
import NotFound from '@features/not-found';
import WatchedCampaign from '@features/watched-campaigns';
import ActionList from '@features/action-list';
import MarketplaceValidationLayout from '@components/layouts/marketplace-validation-layout';

const MainRoutes = createBrowserRouter([
  {
    element: <MarketplaceValidationLayout />,
    children: [
      {
        path: routes.marketplaceActionList.path,
        element: (
          <Meta title={routes.marketplaceActionList.title}>
            <ActionList isDedicatedPage={true} />
          </Meta>
        )
      },
      {
        path: marketplaceRoutes.actionList.path,
        element: (
          <Meta title={marketplaceRoutes.actionList.title}>
            <ActionList isDedicatedPage={true} />
          </Meta>
        )
      },
      {
        path: marketplaceRoutes.myCampaigns.path,
        element: (
          <Meta title={marketplaceRoutes.myCampaigns.title}>
            <MyCampaigns isDedicatedPage={true} />
          </Meta>
        )
      },
    ]
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: routes.login.path,
        element: (
          <Meta title={routes.login.title}>
            <Login />
          </Meta>
        ),
      },
    ],
  },
  {
    element: <BaseLayout />,
    errorElement: <BaseLayout errorBoundary={true} />,
    children: [
      {
        path: routes.home.path,
        element: (
          <Meta title={routes.home.title}>
            <RedirectPage route={routes.myCampaigns.path} />
          </Meta>
        ),
      },
      {
        path: routes.userDetails.path,
        element: (
          <Meta title={routes.userDetails.title}>
            <UserDetails />
          </Meta>
        ),
      },
      {
        path: routes.myCampaigns.path,
        element: (
          <Meta title={routes.myCampaigns.title}>
            <MyCampaigns />
          </Meta>
        ),
      },
      {
        path: routes.watchedCampaigns.path,
        element: (
          <Meta title={routes.watchedCampaigns.title}>
            <WatchedCampaign />
          </Meta>
        ),
      },
      {
        path: routes.createCampaign.path,
        element: (
          <Meta title={routes.createCampaign.title}>
            <CreateCampaigns />
          </Meta>
        ),
      },
      {
        path: routes.actionList.path,
        element: (
          <Meta title={routes.actionList.title}>
            <ActionList />
          </Meta>
        ),
      },
      {
        path: routes.notFound.path,
        element: (
          <Meta title={routes.notFound.title}>
            <NotFound />
          </Meta>
        ),
      },
    ],
  },
]);

export default MainRoutes;
