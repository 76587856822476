import { useNavigate } from 'react-router-dom';
import { useSession } from '@hooks/session-hooks';

import { routes } from '@constants/route-constants';
import { FullPageLoading } from '@components/full-page-loading';
import GeneralLayout from '../general-layout';
import IframeBlockerLayout from '../iframe-blocker-layout';
import './auth-layout.css';

const AuthLayout = () => {
  const { session, status } = useSession();
  const navigate = useNavigate();

  if (session) {
    navigate(routes.myCampaigns.path);
  }

  if (session || status === 'loading') {
    return <FullPageLoading />;
  }

  return (
    <IframeBlockerLayout>
      <GeneralLayout />
    </IframeBlockerLayout>
  );
};

export default AuthLayout;
