import { SetStateAction } from 'react';
import { DeleteOutlined } from '@ant-design/icons';

interface SelectedListProps {
  title: string,
  emptyListMsg?: string,
  list: any[],
  setList: (values: SetStateAction<any[]>) => void,
}

const SelectedList = ({
  title,
  list,
  setList,
  emptyListMsg = 'No Records Found',
}: SelectedListProps) => {
  const handleSingleDelete = (deletedId: any) => {
    setList(prev => prev.filter(data => data.value !== deletedId));
  };

  const handleDeleteAll = () => {
    setList([]);
  };

  return (
    <div className='selected-list'>
      <div className='selected-list-head'>
        <span> {title} </span>
        <DeleteOutlined onClick={handleDeleteAll} />
      </div>

      <div className='selected-list-body'>
        {list.length === 0 ? (
          <div className='empty-field'>
            {emptyListMsg}
          </div>
        ) : (
          <>
            {list.map(item => (
              <div key={item.value} className='list-item'>
                <span>{item.label}</span>
                <DeleteOutlined onClick={() => handleSingleDelete(item.value)} />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default SelectedList;