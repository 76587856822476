import { Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FC, useState, useEffect, Fragment } from 'react';

import {
  validateUrl,
  eliminateUrlPrefix,
  getTargetingOptions,
  setTargetingOptions,
} from '@helpers/targeting-helpers';
import { OptionType } from '@models/global-models';
import { TargetingDataModel } from '@models/targeting-model';
import { DomainTargetingToolTipText } from '@constants/info-tooltip-texts';

import SelectedList from '../selected-list';
import DomainTargetingUpload from './domain-targeting-upload';
import GlobalInfoTooltip from '@components/common/global-info-tooltip';
import './domain-targeting.css';

const DomainTargeting: FC = () => {
  const [inputText, setInputText] = useState<string>('');
  const [domains, setDomains] = useState<OptionType[]>([]);
  const [isPrefilled, setIsPrefilled] = useState<boolean>(false);

  /**
   *? This effect will prefill the existing Domains
   */
  useEffect(() => {
    const existingDomains = getTargetingOptions()?.domains ?? [];
    setDomains(existingDomains.map((d: string, i: number) => ({ label: d, value: i })));
    setIsPrefilled(true);
  }, []);

  /**
   *? This effect will save Domains into session storage on changing Domains
   */
  useEffect(() => {
    if (isPrefilled) {
      const { domains: existingDomains, ...rest }: TargetingDataModel = getTargetingOptions();

      if (domains?.length) {
        setTargetingOptions({
          ...rest,
          domains: domains.map(domain => domain.label),
        });
      } else {
        setTargetingOptions({ ...rest });
      }
    }
  }, [domains, isPrefilled]);

  const processUrls = (givenString: string) => {
    const validUrls: string[] = givenString.replaceAll(',', ' ').replaceAll('\n', ' ').split(' ').filter(str => validateUrl(str));
    let uniqueUrlSet = new Set([...validUrls.map(url => eliminateUrlPrefix(url))]);
    let prefixEliminatedUrls: string[] = [];
    uniqueUrlSet.forEach(value => prefixEliminatedUrls.push(value));

    const existingIds = domains.map(domain => domain.value);
    const existingDomains = domains.map(domain => domain.label);
    const newDomains: OptionType[] = [];

    prefixEliminatedUrls.map(url => {
      if (!existingDomains.includes(url)) {
        let uniqueId = Math.ceil(Math.random() * 1000);
        while (existingIds.includes(uniqueId)) {
          uniqueId = Math.ceil(Math.random() * 1000);
        }
        newDomains.push({
          value: uniqueId,
          label: url,
        });
      }
    });

    setDomains(prev => [...prev, ...newDomains]);
  };

  const handleAddDomain = () => {
    processUrls(inputText);
    setInputText('');
  };

  return (
    <Fragment>
      <p style={{ marginBottom: '20px' }}>
        <strong> Domain Targeting </strong>
        <GlobalInfoTooltip defaultOpen={true} text={DomainTargetingToolTipText} />
      </p>

      <div style={{ display: 'flex', marginBottom: '14px' }}>
        <Input.TextArea
          placeholder='Enter domains'
          className='domain-targeting-input'
          value={inputText}
          onChange={e => setInputText(e.target.value)}
        />
        <div className='domain-targeting-plus-btn' onClick={handleAddDomain}>
          <PlusOutlined />
        </div>
      </div>

      <DomainTargetingUpload processUrls={processUrls} />

      <SelectedList title='Domain' list={domains} setList={setDomains} />
    </Fragment>
  );
};

export default DomainTargeting;