import { Fragment } from 'react';
import { Button, Divider, Input } from 'antd';

const MetaCampaignDropdown = (props: any) => {
  const { menu, value, onChange, placeholder, onClick, btnName, isLoading } = props;

  return (
    <Fragment>
      {menu}
      <Divider style={{ margin: '8px 0' }} />
      <div className='advertiser-intput center gap-2 p-2'>
        <Input
          bordered={false}
          value={value}
          onChange={(e) => onChange(e.target.value ?? '')}
          placeholder={placeholder}
        />
        <Button
          type='text'
          className='text-primary'
          onClick={onClick}
          loading={isLoading}
          disabled={value.length < 2 || value.length > 100}
        >
          + {btnName}
        </Button>
      </div>
    </Fragment>
  );
};

export default MetaCampaignDropdown;