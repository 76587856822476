import { SetStateAction } from 'react';
import { DEVICE_TARGETING_TYPES, INTEGRATION_PLATFORM_TYPES } from '@constants/campaign-data';

export enum PlatformTargetingType {
  WEB = 'WEB',
  MOBILE = 'MOBILE',
}

export interface TargetingPanelsProps {
  isClicked: boolean;
  targetingType: string;
  setTargetingType: (data: SetStateAction<string>) => void;
}

export interface PanelHeaderProps {
  text: string;
  checked: boolean;
}

export interface PanelItemProps {
  title: string;
  type: string;
}

export interface GeoTargetingDataModel {
  country: string;
  region: string;
}

export interface DomainTargetingDataModel {
  name: string;
  includeSubdomains: boolean;
  excludeDomains: boolean;
}

export interface DeviceTargetingDataModel {
  name: string;
  excluded: boolean;
}

export interface BrandSafetyTagetingDataModel {
  allSafe: boolean;
  segments: string[];
}

export interface TargetingDataModel {
  geoTargeting?: GeoTargetingDataModel;
  platformTypes?: PlatformTargetingType[];
  domains?: string[];
  deviceTypes?: DEVICE_TARGETING_TYPES[];
  bundleIds?: string[];
  brandSafety?: BrandSafetyTagetingDataModel;
}

export interface CreateCampaignTargetingModel extends Omit<
  TargetingDataModel,
  'domains' | 'deviceTypes'
> {
  integrationPlatform: INTEGRATION_PLATFORM_TYPES;
  domains?: DomainTargetingDataModel[];
  deviceTypes?: DeviceTargetingDataModel[];
}
