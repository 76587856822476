import { ReactNode } from 'react';

export const BundleTargetingToolTipText: ReactNode = (
  <p>
    Please insert Bundle ID (App ID) for targeting by entering values, or by
    uploading / dropping a file with a content a list of bundles separated by
    commas, new lines or spaces. <br />
    <strong>File format should be .txt, .xls, .csv.</strong> <br />
    <br />
    <strong>Example :</strong> 1551241244 or air.com.and.games505.gamesofwar
  </p>
);


export const DomainTargetingToolTipText: ReactNode = (
  <p>
    Please insert domains targeting by entering values, or by uploading /
    dropping a file with a content a list of domains separated by commas or
    spaces. <br />
    <strong>File format should be .txt, .xls, .csv.</strong> <br />
    Possible values are domlain names or URLs. Domain names are stripped out
    from URLs <br />
    <br />
    <strong>Example :</strong> https://www.improvedigital.com{' '}
    <strong>becomes</strong> improvedigital.com
  </p>
);

export const ActionListToolTipText: string = (
  `Welcome to the Action List grid. All AdOps will have to fill campaign metadata 
  to help us identify business insights regarding buy-side activity (what DSPs are 
  being used, what agency and advertisers we are advertising for, etc.). This continuous 
  process will allow us to improve commercials efforts in the future, so please fill in 
  this data regularly. If you have any questions, feel free to ping Arnaud Delvoye. Thank you.`
);