import { Input } from 'antd';
import { FC, useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import { OptionType } from '@models/global-models';
import { TargetingDataModel } from '@models/targeting-model';
import { BundleTargetingToolTipText } from '@constants/info-tooltip-texts';
import { getTargetingOptions, setTargetingOptions } from '@helpers/targeting-helpers';

import SelectedList from '../selected-list';
import BundleTargetingUpload from './bundle-targeting-upload';
import GlobalInfoToolTip from '@components/common/global-info-tooltip/global-info-tooltip';
import './bundle-targeting.css';

const BundleTargeting: FC = () => {
  const [inputText, setInputText] = useState<string>('');
  const [bundles, setBundles] = useState<OptionType[]>([]);
  const [isPrefilled, setIsPrefilled] = useState<boolean>(false);

  /**
   *? This effect will prefill the existing Bundle IDs
   */
  useEffect(() => {
    const existingBundleIds = getTargetingOptions()?.bundleIds ?? [];
    setBundles(existingBundleIds.map((d: string, i: number) => ({ label: d, value: i })));
    setIsPrefilled(true);
  }, []);

  /**
   *? This effect will save Bundle IDs into session storage on changing Bundle IDs
   */
  useEffect(() => {
    if (isPrefilled) {
      const { bundleIds, ...rest }: TargetingDataModel = getTargetingOptions();

      if (bundles.length) {
        setTargetingOptions({
          ...rest,
          bundleIds: bundles.map(bundle => bundle.label),
        });
      } else {
        setTargetingOptions({ ...rest });
      }
    }
  }, [bundles, isPrefilled]);

  const processBundleIds = (givenString: string) => {
    const validBundles: string[] = givenString.replaceAll(',', ' ').replaceAll('\n', ' ').split(' ').filter(str => str.trim());
    let uniqueBundlesSet = new Set([...validBundles]);
    let uniqueBundles: string[] = [];
    uniqueBundlesSet.forEach(value => uniqueBundles.push(value));

    const existingIds = bundles.map(bundle => bundle.value);
    const existingBundles = bundles.map(bundle => bundle.label);
    const newBundles: OptionType[] = [];

    uniqueBundles.map(bundle => {
      if (!existingBundles.includes(bundle)) {
        let uniqueId = Math.ceil(Math.random() * 1000);
        while (existingIds.includes(uniqueId)) {
          uniqueId = Math.ceil(Math.random() * 1000);
        }
        newBundles.push({
          value: uniqueId,
          label: bundle,
        });
      }
    });

    setBundles(prev => [...prev, ...newBundles]);
  };

  const handleAddBundleIds = () => {
    processBundleIds(inputText);
    setInputText('');
  };

  return (
    <div>
      <p style={{ marginBottom: '20px' }}>
        <strong> Bundle ID Targeting </strong>
        <GlobalInfoToolTip defaultOpen={true} text={BundleTargetingToolTipText} />
      </p>

      <div style={{ display: 'flex', marginBottom: '14px' }}>
        <Input.TextArea
          placeholder='Enter Bundle ID'
          className='bundle-targeting-input'
          value={inputText}
          onChange={e => setInputText(e.target.value)}
        />
        <div className='bundle-targeting-plus-btn' onClick={handleAddBundleIds}>
          <PlusOutlined />
        </div>
      </div>

      <BundleTargetingUpload processBundleIds={processBundleIds} />

      <SelectedList title='Bundle ID' list={bundles} setList={setBundles} />
    </div>
  );
};

export default BundleTargeting;