import { Col, Form, Row, Select } from 'antd';
import { OptionType } from '@models/global-models';

const MetaCampaignSelect = (props: any) => {
  const { label, name, handleChange, fieldName, ...rest } = props;

  const handleFilterOption = (input: string, option: any) => {
    if (typeof option?.label === 'string') {
      return (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase());
    }
    return false;
  };

  const handleFilterSort = (optionA: OptionType, optionB: OptionType) => {
    return (optionA?.label ?? '')
      .toString()
      .toLowerCase()
      .localeCompare((optionB?.label ?? '').toString().toLowerCase());
  };

  return (
    <Row gutter={16}>
      <Col span={24} className='gutter-row'>
        <Form.Item
          label={label}
          name={name}
          rules={[{ required: false }]}
          className='mb-4'
        >
          <Select
            {...rest}
            showArrow
            allowClear
            showSearch
            onChange={(value) => handleChange(value, fieldName)}
            filterOption={handleFilterOption}
            filterSort={handleFilterSort}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default MetaCampaignSelect;