import { StarOutlined, StarFilled } from '@ant-design/icons';
import { FC, useEffect, useState } from 'react';
import { WatchedCampaignRequest } from '@models/watched-campaigns.model';
import { CampaignData } from '@models/campaign-models';
import { getAllWatchedCampaigns, watchedCampaign } from '@services/watched-campaign.service';

export const CampaignWatched: FC<WatchedCampaignRequest> = ({
  campaignId,
  integrationPlatform,
  allWatchedCampaigns = [],
  setRefetchedAllWatchedCampaigns
}: WatchedCampaignRequest) => {
  const [watched, setWatched] = useState(false);

  const handleStarClicked = async (
    campaignId: string,
    integrationPlatform: string,
    isAlreadyWatched: boolean,
  ) => {
    if (campaignId && integrationPlatform) {
      await watchedCampaign(
        {
          campaignId,
          integrationPlatform,
          setRefetchedAllWatchedCampaigns
        },
        isAlreadyWatched
      ).then(hasWatched => {
        setWatched(hasWatched);
        getAllWatchedCampaigns().then(allWatchedCampaigns => {
          setRefetchedAllWatchedCampaigns(allWatchedCampaigns.campaigns);
        });
      });
    }

  };

  const getWatchedCampaignStatus = (
    campaignId: string,
    integrationPlatform: string,
    allWatchedCampaigns: CampaignData[]
  ) => {
    if (campaignId && integrationPlatform && allWatchedCampaigns) {
      const isFound = allWatchedCampaigns.find(element => element?.campaignId === campaignId);
      setWatched(isFound ? true : false);
    }
  };

  useEffect(() => {
    if (campaignId && integrationPlatform && allWatchedCampaigns) {
      getWatchedCampaignStatus(campaignId, integrationPlatform, allWatchedCampaigns);
    }
  }, [
    {
      campaignId,
      integrationPlatform,
      allWatchedCampaigns
    },
  ]);

  return watched ? (
    <StarFilled
      onClick={() =>
        handleStarClicked(campaignId, integrationPlatform, watched)
      }
      className="star"
    />
  ) : (
    <StarOutlined
      onClick={() =>
        handleStarClicked(campaignId, integrationPlatform, watched)
      }
      className="star"
    />
  );
};

export const numberWithCommas = (value: string): string => {
  const num = parseFloat(value);

  return Number.isNaN(num) ? 'N/A' : num.toLocaleString('en');
};

