import { RecoilRoot } from 'recoil';
import { FC, Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import MainRoutes from './routes/base-routes';
import { FullPageLoading } from './components/full-page-loading';

const App: FC = () => {
  return (
    <RecoilRoot>
      <Suspense fallback={<FullPageLoading />}>
        <RouterProvider router={MainRoutes} />
      </Suspense>
    </RecoilRoot>
  );
};

export default App;
