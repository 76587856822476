import { useRecoilState, useSetRecoilState } from 'recoil';
import { FC, useState, Fragment, useEffect } from 'react';
import { allWatchedCampaignsState, breadcrumbState } from '@atoms/global-atoms';
import { getAllWatchedCampaigns, getWatchedCampaigns } from '@services/watched-campaign.service';
import { getTableScrollProperties, processCampaignsResponse } from '@helpers/campaign-helpers';
import {
  CampaignData,
  CampaignLoadings,
  GetCampaignsParams,
} from '@models/campaign-models';
import {
  INIT_CAMPAIGNS_PARAMS,
  INIT_CAMPAIGN_LOADING_STATES,
} from '@constants/campaign-data';
import CampaignTable from '../../components/campaign-table';
import CampaignTableFilter from '../../components/campaign-filters';
import { PAGE_TITLE } from '@constants/global-constants';

const WatchedCampaign: FC = () => {
  // TODO: states need to migrate on RecoilState later.
  const setBreadcrumbState = useSetRecoilState(breadcrumbState);
  const [loadingStates, setLoadingStates] = useState<CampaignLoadings>({
    ...INIT_CAMPAIGN_LOADING_STATES,
  });
  const [allWatchedCampaigns, setAllWatchedCampaigns] = useState<CampaignData[]>([]);
  const [campaignData, setCampaignData] = useState<CampaignData[]>([]);
  const [totalCampaignData, setTotalCampaignData] = useState<number>(0);
  const [getCampaignsApiParams, setGetCampaignsApiParams] =
    useState<GetCampaignsParams>({ ...INIT_CAMPAIGNS_PARAMS });
  const [refetchedAllWatchedCampaigns, setRefetchedAllWatchedCampaigns] = useRecoilState(allWatchedCampaignsState);

  useEffect(() => {
    setBreadcrumbState([PAGE_TITLE.WATCHED_CAMPAIGN]);
  });

  useEffect(() => {
    setLoadingStates((prev) => ({
      ...prev,
      isCampaignsLoading: true,
    }));

    getWatchedCampaigns(getCampaignsApiParams)
      .then((resp) => {
        setTotalCampaignData(resp?.total ?? 0);
        setCampaignData(resp.campaigns?.length ? [...processCampaignsResponse(resp?.campaigns)] : []);
      })
      .finally(() => {
        setLoadingStates((prev) => ({
          ...prev,
          isCampaignsLoading: false,
        }));
      });
  }, [getCampaignsApiParams]);

  useEffect(() => {
    getAllWatchedCampaigns().then((allWatchedCampaigns) => {
      setAllWatchedCampaigns(allWatchedCampaigns.campaigns);
    });
  }, [refetchedAllWatchedCampaigns]);

  return (
    <Fragment>
      <div>
        <h1 className='page-heading'> {PAGE_TITLE.WATCHED_CAMPAIGN} </h1>

        <CampaignTableFilter
          setGetCampaignsApiParams={setGetCampaignsApiParams}
        />

        <CampaignTable
          isCampaignsLoading={loadingStates.isCampaignsLoading}
          totalCampaignData={totalCampaignData}
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          getCampaignsApiParams={getCampaignsApiParams}
          setGetCampaignsApiParams={setGetCampaignsApiParams}
          allWatchedCampaigns={allWatchedCampaigns}
          setRefetchedAllWatchedCampaigns={setRefetchedAllWatchedCampaigns}
          scroll={getTableScrollProperties(getCampaignsApiParams?.limit ?? 0, totalCampaignData)}
        />
      </div>
    </Fragment>
  );
};

export default WatchedCampaign;
