import { Button } from 'antd';
import { ConfirmationModal } from '@components/common/modals';
import { CreateCampaignsBottomNavigationProps } from '@models/create-campaign-models';

const CreateCampaignsBottomNavigation = ({
  onBack,
  onSkip,
  onNext,
  onCancel,
  altNextText = 'Next',
}: CreateCampaignsBottomNavigationProps) => {
  const validator = (handler: any) => {
    return !!handler && (typeof handler === 'function');
  };

  const handleBack = () => {
    if (validator(onBack)) {
      onBack();
    }
  };

  const handleSkip = () => {
    if (validator(onSkip)) {
      onSkip();
    }
  };

  const handleNext = () => {
    if (validator(onNext)) {
      onNext();
    }
  };

  const handleCancel = () => {
    if (validator(onCancel)) {
      onCancel();
    }
  };

  return (
    <div className='form-button-group-format'>
      <div>
        {!!onBack && (
          <Button className="ant-btn-secondary mr-2" onClick={handleBack}>
            Back
          </Button>
        )}
      </div>
      <div>
        {!!onCancel && (
          <ConfirmationModal className='mr-2' handleOk={handleCancel} />
        )}
        {!!onSkip && (
          <Button className="ant-btn-secondary mr-2" onClick={handleSkip}>
            Skip
          </Button>
        )}
        <Button type='primary' htmlType='submit' onClick={handleNext}>
          {altNextText}
        </Button>
      </div>
    </div>
  );
};

export default CreateCampaignsBottomNavigation;