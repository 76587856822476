import { useEffect } from 'react';
import { Col, Input, Row } from 'antd';
import { useSetRecoilState } from 'recoil';

import { breadcrumbState } from '@atoms/global-atoms';
import { UserDetailsResponse } from '@models/user-models';
import { LOCAL_STORAGE_KEYS } from '@constants/storage-constants';
import './user-details.css';

const UserDetails = () => {
  const setBreadcrumbState = useSetRecoilState(breadcrumbState);
  const userDetails: UserDetailsResponse = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO) || 'null');

  useEffect(() => {
    setBreadcrumbState(['User Details']);
  }, []);

  return (
    <div className='' style={{ padding: '90px' }}>
      <Row gutter={24} >
        <Col className='gutter-row' span={8} >
          <label>{<h4><b>Id</b></h4>}</label>
          <Input value={userDetails?.user_id} disabled />
        </Col>

        <Col className='gutter-row' span={8} >
          <label>{<h4><b>First name</b></h4>}</label>
          <Input value={userDetails?.first_name} disabled />
        </Col>

        <Col className='gutter-row' span={8}>
          <label>{<h4><b>Last name</b></h4>}</label>
          <Input value={userDetails?.last_name} disabled />
        </Col>

        <Col className='gutter-row' span={24} style={{ marginTop: '2rem' }}>
          <label>{<h4><b>Roles</b ></h4>}</label>
          <Input value={userDetails?.user_roles} disabled />
        </Col>
      </Row>
    </div >
  );
};

export default UserDetails;
