import { message } from 'antd';
import { useSetRecoilState } from 'recoil';
import { useState, useEffect } from 'react';

import { breadcrumbState } from '@atoms/global-atoms';
import { getMetaCampaigns } from '@services/meta-campaign-services';

import {
  MetaCampaignData,
  GetMetaCampaignParams,
  MetaCampaignLoadingTypes,
  ActionListProps,
} from '@models/meta-campaign-models';
import { OptionType, AppErrorType } from '@models/global-models';
import { ActionListProvider } from '@contexts/action-list-context';

import { PAGE_TITLE } from '@constants/global-constants';
import { ActionListToolTipText } from '@constants/info-tooltip-texts';
import { INIT_META_CAMPAIGNS_LOADERS, INIT_META_CAMPAIGNS_PARAMS } from '@constants/campaign-data';

import {
  getMetaDataAgencies,
  getMetaDataAdvertisers,
  getMetaDataAdOpsPersons,
  getMetaDataSalesPersons,
  getBusinessUnitsFromStorage
} from '@helpers/global-helpers';
import { getTableScrollProperties } from '@helpers/campaign-helpers';

import MetaCampaignTableFilter from '@components/meta-campaign-filters';
import MetaCampaignTable from '@components/meta-campaign-table/meta-campaign-table';
import GlobalInfoToolTip from '@components/common/global-info-tooltip/global-info-tooltip';

const ActionList = ({ isDedicatedPage }: ActionListProps) => {
  const setBreadcrumbState = useSetRecoilState(breadcrumbState);
  const [refetch, setRefetch] = useState<boolean>(false);

  const [agencies, setAgencies] = useState<OptionType[]>([]);
  const [advertisers, setAdvertisers] = useState<OptionType[]>([]);
  const [salesPersons, setSalesPersons] = useState<OptionType[]>([]);
  const [adOpsPersons, setAdOpsPersons] = useState<OptionType[]>([]);
  const [businessUnits, setBusinessUnits] = useState<OptionType[]>([]);

  const [totalMetaCampaignData, setTotalMetaCampaignData] = useState<number>(0);
  const [metaCampaignData, setMetaCampaignData] = useState<MetaCampaignData[]>([]);

  const [apiParams, setApiParams] = useState<GetMetaCampaignParams>({
    ...INIT_META_CAMPAIGNS_PARAMS
  });
  const [loadingStates, setLoadingStates] = useState<MetaCampaignLoadingTypes>({
    ...INIT_META_CAMPAIGNS_LOADERS
  });

  // Initially Loading Necessary Data
  useEffect(() => {
    setBreadcrumbState([PAGE_TITLE.ACTION_LIST]);
    getBusinessUnits();
    getAgencies();
    getAdvertisers();
    getSalesPersons();
    getAdOpsPersons();
  }, []);

  // Fetching Campaigns Data While First Load or Any Param Changed
  useEffect(() => {
    updateLoadingState('isMetaDataLoading', true);

    getMetaCampaigns(apiParams)
      .then((resp) => {
        setTotalMetaCampaignData(resp.total ?? 0);
        setMetaCampaignData(resp.campaigns);
      })
      .catch((err: AppErrorType) => {
        message.error(err?.message);
      })
      .finally(() => updateLoadingState('isMetaDataLoading', false));
  }, [apiParams, refetch]);

  const updateLoadingState = (key: keyof MetaCampaignLoadingTypes, value: boolean) => {
    setLoadingStates(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const getBusinessUnits = async () => {
    const options = await getBusinessUnitsFromStorage();
    setBusinessUnits(options);
    updateLoadingState('isBULoading', false);
  };

  const getAgencies = async () => {
    const options = await getMetaDataAgencies();
    setAgencies(options);
    updateLoadingState('isAgenciesLoading', false);
  };

  const getAdvertisers = async () => {
    const options = await getMetaDataAdvertisers();
    setAdvertisers(options);
    updateLoadingState('isAdvertisersLoading', false);
  };

  const getAdOpsPersons = async () => {
    const options = await getMetaDataAdOpsPersons();
    setAdOpsPersons(options);
    updateLoadingState('isAdOpsPersonsLoading', false);
  };

  const getSalesPersons = async () => {
    const options = await getMetaDataSalesPersons();
    setSalesPersons(options);
    updateLoadingState('isSalesPersonsLoading', false);
  };

  return (
    <ActionListProvider value={{
      loadingStates,
      businessUnits,
      agencies,
      salesPersons,
      adOpsPersons,
      advertisers,
      setRefetch,
      setApiParams,
      getAdvertisers,
      getSalesPersons,
      updateLoadingState,
    }}>
      <h1 className='page-heading'>
        {PAGE_TITLE.ACTION_LIST}
        <GlobalInfoToolTip defaultOpen={false} text={ActionListToolTipText} />
      </h1>

      <MetaCampaignTableFilter />

      <MetaCampaignTable
        totalMetaCampaignData={totalMetaCampaignData}
        metaCampaignData={metaCampaignData}
        setMetaCampaignData={setMetaCampaignData}
        getMetaCampaignsApiParams={apiParams}
        scroll={getTableScrollProperties(apiParams?.limit ?? 0, totalMetaCampaignData, isDedicatedPage)}
      />
    </ActionListProvider>
  );
};

export default ActionList;
