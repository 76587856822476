import { Row, Form, message } from 'antd';
import { useEffect, useState } from 'react';

import {
  CreativeItem,
  TableCreativeItem,
  UpdateDraftCampaignPayload,
} from '@models/campaign-models';
import { CreativeProps } from '@models/create-campaign-models';
import { updateDraftCampaign } from '@services/campaign-services';

import { FORM_TYPE } from '@constants/global-constants';
import { CREATIVE_TYPES } from '@constants/campaign-data';
import { getCampaignMode } from '@helpers/campaign-helpers';

import CreativeTable from './creative-table';
import CreativeFormFields from './creative-form-fields';
import CreateCampaignsBottomNavigation from '../create-campaigns-bottom-navigation';
import './creative.css';

const Creative = (props: CreativeProps) => {
  const { onBack, onSkip, setIsRefetch, draftCampaign, handleCancelOnConfirm } = props;
  const [form] = Form.useForm();

  const [isExpandTable, setIsExpandTable] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<string>(CREATIVE_TYPES.DISPLAY);
  const [isDisableTypeSelection, setIsDisableTypeSelection] = useState<boolean>(false);
  const [selectedCreative, setSelectedCreative] = useState<TableCreativeItem | null>(null);

  //? Prefill while edit a creative item 
  useEffect(() => {
    if (selectedCreative) {
      form.setFieldValue('creativeName', selectedCreative.creativeName);
      form.setFieldValue('creativeType', selectedCreative.creativeType);
      form.setFieldValue('thirdPartyTag', selectedCreative.thirdPartyTag);
      form.setFieldValue('displaySize', selectedCreative.displaySize);
      form.setFieldValue('advertiserDomain', selectedCreative.advertiserDomain);
      form.setFieldValue('iabCategoryDescription', selectedCreative.iabCategoryDescription);
      setRadioValue(selectedCreative.creativeType);
    } else {
      form.resetFields();
      setRadioValue(CREATIVE_TYPES.DISPLAY);
    }
  }, [selectedCreative]);

  //? Prevent user from selecting different type creative items 
  useEffect(() => {
    if (draftCampaign) {
      let liIndex = getCampaignMode()?.id ?? draftCampaign?.data?.lineItems?.length - 1;
      if (liIndex < 0) liIndex = 0;
      if (draftCampaign?.data?.lineItems[liIndex]?.creatives?.length) {
        const creative = draftCampaign?.data?.creativesItems.find(item => item.id === draftCampaign?.data?.lineItems[liIndex]?.creatives[0]);
        if (creative) {
          setIsDisableTypeSelection(true);
          setRadioValue(creative?.creativeType);
          form.setFieldValue('creativeType', creative?.creativeType);
        }
      } else {
        setIsDisableTypeSelection(false);
        setRadioValue(CREATIVE_TYPES.DISPLAY);
        form.setFieldValue('creativeType', CREATIVE_TYPES.DISPLAY);
      }
    }
  }, [draftCampaign]);

  //? Add a new creative or edit existing creative handler
  const addOrEditHandler = (values: CreativeItem) => {
    const { _id, name, data } = draftCampaign;
    const updateDraftCampaignPayload: UpdateDraftCampaignPayload = { name, data, id: _id };

    if (selectedCreative) {
      updateDraftCampaignPayload.data.creativesItems = data.creativesItems.map(item => {
        if (item.id === selectedCreative.id) {
          return ({
            ...values,
            id: item.id,
            creativeType: values.creativeType ?? CREATIVE_TYPES.DISPLAY,
            displaySize: values?.displaySize ?? '',
          });
        } else {
          return item;
        }
      });
    } else {
      const len = data?.creativesItems?.length;
      let id = len ? (data.creativesItems[len - 1]?.id ?? 0) + 1 : 1;

      const creativeItem: CreativeItem = {
        ...values,
        id,
        creativeType: values.creativeType ?? CREATIVE_TYPES.DISPLAY,
        displaySize: values?.displaySize ?? '',
      };

      updateDraftCampaignPayload.data.creativesItems.push(creativeItem);

      const liIndex = getCampaignMode()?.id ?? data?.lineItems?.length - 1;
      updateDraftCampaignPayload.data.lineItems[liIndex].creatives.push(id);
    }

    updateDraftCampaign(updateDraftCampaignPayload)
      .then(() => {
        form.resetFields();
        setRadioValue(CREATIVE_TYPES.DISPLAY);
        setIsRefetch(prev => !prev);
        message.success(selectedCreative ? 'Creative item updated' : 'Creative item added');
        setSelectedCreative(null);
      })
      .catch(() => message.error('Someting went wrong'));
  };

  return (
    <div className='container right-to-left-fade-in'>
      <Form
        form={form}
        name={FORM_TYPE.CREATIVES_FORM}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={addOrEditHandler}
      >
        <div className={`transition-max-h ${isExpandTable ? 'max-h-0' : ''}`}>
          <Row>
            <CreativeFormFields
              radioValue={radioValue}
              setRadioValue={setRadioValue}
              selectedCreative={selectedCreative}
              setSelectedCreative={setSelectedCreative}
              isDisableTypeSelection={isDisableTypeSelection}
            />
          </Row>
        </div>

        <CreativeTable
          draftCampaign={draftCampaign}
          radioValue={radioValue}
          isDisableTypeSelection={isDisableTypeSelection}
          setIsRefetch={setIsRefetch}
          isExpandTable={isExpandTable}
          setIsExpandTable={setIsExpandTable}
          setSelectedCreative={setSelectedCreative}
        />

        <CreateCampaignsBottomNavigation
          onBack={onBack}
          onSkip={onSkip}
          onNext={onSkip}
          onCancel={handleCancelOnConfirm}
        />
      </Form>
    </div>
  );
};

export default Creative;
